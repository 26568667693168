import React from 'react';
import Table from '../../component/table/table'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import { MDBContainer,  MDBRow, MDBCol} from "mdbreact";
import './cancelled orders.css'
import {message} from 'antd';
import allStrings from '../../assets/strings'
import { connect } from 'react-redux';
import {NavLink, withRouter,Redirect} from 'react-router-dom'
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import { CSVLink } from "react-csv";
import Input from '@material-ui/core/Input';
import {hasValue} from '../../validations/validations'
let headers = [
  { label: "#", key: "id" },
  { label: "Client", key: "client" },
  { label: "Total", key: "total" },
  { label: "deliveryCost", key: "deliveryCost" }, 
  { label: "FinalTotal", key: "finalTotal" },
  { label: "City", key: "city" },
  { label: "Area", key: "area" },
  { label: "PaymentSystem", key: "paymentSystem" },
  { label: "HasPromoCode", key: "hasPromoCode" },
];
 
let csvData = [
  
];
class CanceledOrders extends React.Component {
  
  state={
    orders:[],
    csvData:[],
    orders2:[],
    loading:true,
    refresh:false,
    page:1,
    pages:1,
    search:""
  }

  constructor(props){
    super(props)
    this.getOrdersWithoutPagenation()
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }

  componentDidMount(){
    this.getOrders(1)
    this.getOrdersWithoutPagenation()
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }


  getOrders = (page,search) =>{
    this.setState({loading:true})
      let url = `${BASE_END_POINT}orders?status=CANCELED&page=${page}`
      if(search){
        url = `${BASE_END_POINT}orders?status=CANCELED&page=${page}&search=${search}`
      }
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
       console.log("orders   ",response.data)
      var data = response.data.data.map(item=>[
        item.id,
        item.client?item.client.firstname + " " + item.client.lastname:""
,
        item.total,
        item.deliveryCost,
        item.finalTotal,
        item.city?item.city.name:"",
        item.area?item.area.name:"",
        item.paymentSystem ==="CASH"?allStrings.cash:allStrings.online,
        item.hasPromoCode?allStrings.yes:allStrings.no,
        "action"])
      this.setState({
        loading:false,
        refresh:false,
        orders:response.data.data,
        orders2:data,
        page:response.data.page,
        pages:response.data.pageCount,
        search:""
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })
  }
  getOrdersWithoutPagenation = (search) =>{
    let url = `${BASE_END_POINT}orders/withoutPagenation/get?status=CANCELED`;
    if(search){
      url = `${BASE_END_POINT}orders/withoutPagenation/get?status=CANCELED&search=${search}`
    }
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log("orders   ",response.data)
        csvData= response.data.data.map(item => {
          return {
            ...{id:item.id},
            ...{client:item.client?item.client.firstname + " " + item.client.lastname:""
},
            ...{total:item.total},
            ...{deliveryCost:item.deliveryCost},
            ...{finalTotal:item.finalTotal},
            ...{city:item.city?item.city.name:""},
            ...{area:item.area?item.area.name:""},
            ...{paymentSystem:item.paymentSystem === "CASH"?allStrings.cash:allStrings.online},
            ...{hasPromoCode:item.hasPromoCode},
            
          };
        });
        console.log(csvData)
        this.setState({csvData:csvData})
      
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
    })
  }

  delete = (id) => {
    let l = message.loading('Wait..', 2.5)
    axios.delete(`${BASE_END_POINT}orders/${id}`,{
     headers: {
       'Content-Type': 'application/json',
       'Authorization':`Bearer ${this.props.currentUser.token}`
     },
    })
    .then(response=>{
      console.log("product DELETED")
      l.then(() => message.success(allStrings.done, 2.5) )
      this.getOrders(this.state.page)
      this.getOrdersWithoutPagenation()
    })
    .catch(error=>{
        console.log(error.response)
        l.then(() => message.error(allStrings.error, 2.5))
    })
 }

  addNewRecordButton = () =>(
    <div style={{width:'100%'}}>
      <NavLink to="addOrder">
        <Fab
          style={{background:'#605363',float:'left',margin:'50px 30px'}}
          variant="extended"
          size="medium"
          color="primary"
          aria-label="add"
        >
          <AddIcon />{allStrings.addNewRecored}
        </Fab>
      </NavLink>
        
    </div>
  )

  paginationButtons = () =>(
    <CardActions style={{display: 'inline-flex'}}>
        <Button 
        onClick={()=>{
          if(this.state.page!==1){
            this.getOrders(1)
            this.setState({page:1})
          }
        }}
        className="pagenation" >{allStrings.first}</Button>

        <Button 
        onClick={()=>{
         if(this.state.page>1){
            this.getOrders(this.state.page-1)
            this.setState({page:this.state.page-1})
         }
        }}
        className="pagenation">{allStrings.prev}</Button>
        
        <Pagination
        onChange={(event,page)=>{
        console.log("page   ",page)
        if(page!==this.state.page){
          this.getOrders(page)
          this.setState({page:page})
        }
        
        }} 
        defaultPage={1} page={this.state.page} count={this.state.pages} style={{color:`${'#605363'} !important`}} />
        
        <Button 
        onClick={()=>{
          if(this.state.page<this.state.pages){
            this.getOrders(this.state.page+1)
            this.setState({page:this.state.page+1})
          }
        }}
        className="pagenation">{allStrings.next}</Button>

        <Button 
         onClick={()=>{
          if(this.state.page!==this.state.pages){
            this.getOrders(this.state.pages)
            this.setState({page:this.state.pages})
          }
        }}
        className="pagenation">{allStrings.last}</Button>

    </CardActions>
  )

  render(){
    if (this.props.currentUser.data.type === "SUB-ADMIN" && !this.props.currentUser.data.permission.pages.includes('ORDERS')) return <Redirect to='/Home' />

  return (
    <div className="App">
        
      <MDBContainer> 
      <MDBRow>
        <MDBCol md="12">
          <div className="screenTable">
          <Table
            enableEdit = {false}
            reload = {
              <span 
              style={{position: 'absolute',margin: '7px 0px',color: 'rgb(67, 66, 93)',fontWeight: '600',cursor: 'pointer'}}
              onClick={() => this.getOrders(1)}
              class="material-icons">
                replay
              </span>
            }
            csv = {
              <CSVLink 
                  data={this.state.csvData} 
                  headers={headers} 
                  filename={"CanceledOrders.csv"}
                >
                  <img alt="" src={require('../../assets/images/export-csv.png')} width="28"></img>
                </CSVLink>
            }
            searchInput = {
              <Input 
              onKeyPress={(e)=>{
                if (e.key === 'Enter') {
                  if(hasValue(""+e.target.value)){
                    console.log("ENTER   ",e.target.value)
                    this.setState({search:e.target.value})
                    this.getOrders(1,e.target.value)
                    this.getOrdersWithoutPagenation(e.target.value)
                  }else{
                    message.warning(allStrings.noValue);
                    console.log("ENTER NO VALUE   ",e.target.value)
                  }
                }
               }}  
              placeholder={allStrings.Search} style={{direction:this.props.isRTL?'rtl':'ltr'}}inputProps={{ 'aria-label': 'description' }} />
            }
            title={allStrings.canceledOrders}
            icon='remove_shopping_cart'
            data={this.state.orders}
            data2={this.state.orders2}
            tableColumns={["#",allStrings.client,allStrings.total,allStrings.deliveryCost,allStrings.finalTotal,allStrings.city,allStrings.area,allStrings.paymentSystem,allStrings.hasPromoCode,allStrings.action]}
            loading={this.state.loading}
            deleteUser={(id)=>{this.delete(id)}}
            path='/OrderInfo'
            actionIndex={6}
            />
            {this.paginationButtons()}
            {/*this.addNewRecordButton()*/}
            </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer >
    </div> 
  )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = {
  ChangeLanguage
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(CanceledOrders)
);

