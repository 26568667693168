/* eslint-disable eqeqeq */
import React from 'react';
import Table from '../../component/table/table'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import { MDBContainer,  MDBRow, MDBCol} from "mdbreact";
import './user.css'
import {message} from 'antd';
import allStrings from '../../assets/strings'
import { connect } from 'react-redux';
import {withRouter,Redirect} from 'react-router-dom'
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import { CSVLink } from "react-csv";
import 'moment/locale/ar';
import Input from '@material-ui/core/Input';
import {hasValue} from '../../validations/validations'
let headers = [
  { label: "#", key: "id" },
  { label: "firstname", key: "firstname" },
  { label: "lastname", key: "lastname" },
  { label: "Email", key: "email" },
  { label: "Phone", key: "phone" },
];
 
let csvData = [
  
];
class Users extends React.Component {
  
  state={
    users:[],
    users2:[],
    loading:true,
    refresh:false,
    showFilter:false,
    page:1,
    pages:1,
    collapsed: false,
    filterType:{},
    value:'',
    count:0,
    search:null,

  }

  constructor(props){
    super(props)
    window.scrollTo(0,0)
    this.getUsersWithoutPagenation(false)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }

  componentDidMount(){
    this.getUsers(1,false)
    window.scrollTo(0,0)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }
  getUsers = (page,refresh) =>{
    this.setState({loading:true})
    let url = `${BASE_END_POINT}getAll?type=USER&page=${page}`
    let {search} = this.state
    if(refresh == false){
      if(search !== null){
        let str = '&search='+ search
        url += str
      }
    }else{
      this.setState({search:null})
    }
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
      }
    })
    .then(response=>{
      console.log("USERS   ",response.data)
      var data = response.data.data.map(item=>[
        item.id,
        item.firstname?item.firstname:'',
        item.lastname?item.lastname :'',
        item.img?{type:"img",img:item.img}:{type:"img",img:""},
        item.phone?item.phone:"",
        "action"])
      console.log("DATA   ",data)
      this.setState({
        loading:false,
        refresh:false,
        users:response.data.data,
        users2:data,
        //page:response.data.page,
        pages:response.data.pageCount,
        count:response.data.totalCount
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })
  }
  getUsersWithoutPagenation = (refresh) =>{
    let url = `${BASE_END_POINT}withoutPagenation/get?type=USER`
    let {search} = this.state
    if(refresh == false){
      if(search !== null){
        let str = '&search='+ search
        url += str
      }
    }else{
      this.setState({search:null})
    }
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
      }
    })
    .then(response=>{
      console.log("USERS   ",response.data)
        csvData= response.data.data.map(item => {
          return {
            ...{id:item.id},
            ...{firstname:item.firstname?item.firstname:''},
            ...{lastname:item.lastname?item.lastname:''},
            ...{email:item.email?item.email:""},
            ...{phone:item.phone},
          };
        });
        console.log(csvData)
      
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
    })
  }

  deleteUser = (id) => {
    console.log("ID   ",id)
    let l = message.loading('Wait..', 2.5)
    axios.delete(`${BASE_END_POINT}${id}/delete`,{
     headers: {
       'Content-Type': 'application/json',
       'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
     },
    })
    .then(response=>{
      console.log("USER DELETED")
      l.then(() => message.success(allStrings.done, 2.5) )
      this.getUsers(this.state.page,false)
    })
    .catch(error=>{
        console.log(error.response)
        l.then(() => message.error(allStrings.error, 2.5))
    })
 }


  paginationButtons = () =>(
    <CardActions style={{display: 'inline-flex'}}>
        <Button 
        onClick={()=>{
          if(this.state.page!==1){
            this.getUsers(1,false)
            this.setState({page:1})
          }
        }}
        className="pagenation" >{allStrings.first}</Button>

        <Button 
        onClick={()=>{
         if(this.state.page>1){
            this.getUsers(this.state.page-1,false)
            this.setState({page:this.state.page-1})
         }
        }}
        className="pagenation">{allStrings.prev}</Button>
        
        <Pagination
        onChange={(event,page)=>{
        console.log("page   ",page)
        if(page!==this.state.page){
          this.getUsers(page,false)
          this.setState({page:page})
        }
        
        }} 
        defaultPage={1} page={this.state.page} count={this.state.pages} 
        style={{color:`#605363 !important`}} />
        
        <Button 
        onClick={()=>{
          if(this.state.page<this.state.pages){
            this.getUsers(this.state.page+1,false)
            this.setState({page:this.state.page+1})
          }
        }}
        className="pagenation">{allStrings.next}</Button>

        <Button 
         onClick={()=>{
          if(this.state.page!==this.state.pages){
            this.getUsers(this.state.pages,false)
            this.setState({page:this.state.pages})
          }
        }}
        className="pagenation">{allStrings.last}</Button>

    </CardActions>
  )

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    if (this.props.currentUser.data.type === "SUB-ADMIN" && !this.props.currentUser.data.permission.pages.includes('CLIENTS')) return <Redirect to='/Home' />
    return (
        
      <MDBContainer> 
      <MDBRow>
        <MDBCol md="12">
          <div className="screenTable">
          
          <Table
            settings = {
              <div>
                <i class="fab fa-searchengin searchIcon" onClick={()=>this.setState({showFilter:!this.state.showFilter})}></i>
                <span 
                style={{position: 'relative',padding: '4px',color: '#659FF1',fontSize: '25px',borderRadius:'50%',top:'4px',cursor: 'pointer'}}
                onClick={() => {this.getUsers(1,true);this.getUsersWithoutPagenation(true)}}
                class="material-icons">
                  replay
                </span>
                <CSVLink 
                  data={csvData} 
                  headers={headers} 
                  filename={"users.csv"}
                  style={{padding: '10px',position: 'relative',top: '-5px'}}
                  >
                  <img alt="" src={require('../../assets/images/export-csv.png')} width="28"></img>
                </CSVLink>
              </div>
              
            }
            searchInput = {
              <Input 
              onKeyPress={(e)=>{
                if (e.key === 'Enter') {
                  if(hasValue(""+e.target.value)){
                    console.log("ENTER   ",e.target.value)
                    this.setState({search:e.target.value})
                    this.getUsers(1)
                  }else{
                    message.warning(allStrings.noValue);
                    console.log("ENTER NO VALUE   ",e.target.value)
                  }
                }
               }}  
              placeholder={allStrings.Search} style={{direction:this.props.isRTL?'rtl':'ltr'}}inputProps={{ 'aria-label': 'description' }} />
            }
            title={allStrings.users}
            lang={this.props.isRTL}
            icon='group'
            data={this.state.users}
            data2={this.state.users2}
            tableColumns={["#",allStrings.firstName,allStrings.lastName,allStrings.img,allStrings.phone,allStrings.action]}
            loading={this.state.loading}
            deleteUser={(id)=>{this.deleteUser(id)}}
            path='/UserInfo'
            actionIndex={7}
            />
            {this.paginationButtons()}
              
            </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer >
    );
  }

}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = {
  ChangeLanguage
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(Users)
);

