import React from 'react';
import './add anoncement.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message,DatePicker,TreeSelect} from "antd";
import { UploadOutlined } from '@ant-design/icons';

const { TreeNode } = TreeSelect;

class AddAnoncement extends React.Component {
  state = {
    title_ar:'',
    title_en:'',
    startDate:null,
    endDate:null,
    link:'',
    openPeriod:false,
    selectedFile: null,
    img: null,
    imgName:'',
    imgsType:'data',
  };

  constructor(props){
    super(props)
    this.fileChangedHandler = this.fileChangedHandler.bind(this);
    window.scrollTo(0,0)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
  }
  componentDidMount(){
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }

  addanoncement = () => {
    const {img,startDate,endDate,link,openPeriod,title_ar,title_en} = this.state
    let l = message.loading('Wait..', 1.5)
    if(img == null)
      l.then(() => message.error(allStrings.completeData, 1.5) )
    if(hasValue(link)&&hasValue(title_ar)&&hasValue(title_ar)){
        
        var data = new FormData()
        data.append('title_ar',title_ar)
        data.append('title_en',title_en)
        data.append('link',link)
        if(img != null){
          data.append('img',img); 
        }
        if(openPeriod ===false){
          data.append('startDate',startDate)
          data.append('endDate',endDate)
        }
        axios.post(`${BASE_END_POINT}anoncements`,data,{
        headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
        },
        })
        .then(response=>{
        console.log("add news  ",response.data)
        l.then(() => message.success(allStrings.done, 2.5) )
        this.props.history.goBack() 
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
    }
  }
  
  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.addanoncement()
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  
  fileChangedHandler = event => {
    this.setState({
      img: event.target.files[0]
    })
  }
  removeFile = () => {
    this.setState({
        img:null
    });
  }
  
  onChangeStartDate = (date, dateString) => {
    console.log(date, dateString);
    this.setState({startDate:dateString})
  }
  onChangeEndDate = (date, dateString) => {
    console.log(date, dateString);
    this.setState({endDate:dateString})
  }
  render(){
    if (this.props.currentUser.data.type === "SUB-ADMIN" && !this.props.currentUser.data.permission.pages.includes('ANONCEMENTS')) return <Redirect to='/Home' />
    return (
      <div className="App">
          
          <MDBContainer>
              <MDBRow>
                  <MDBCol xl ="12" lg = "12" md="12" sm="12">
                      <div className="infoCard">
                        <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                          <CardMedia
                          style={{height:"0px"}}
                          />
                          <CardHeader></CardHeader>
                          <div  style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                              <div className="topIcon" style={{background:'#605363'}}>
                                  <span className="material-icons" style={{color:'white',fontSize:23}}>featured_video</span>
                              </div>
                              <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                              marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0'}}>
                              <p>{allStrings.addAnoncement}</p>
                              </div>
                          </div>
                          <CardContent style={{minHeight:"390px",outline:'none'}}>
                            <MDBContainer>
                                <MDBRow>
                                    <MDBCol md="12">
                                    <form
                                    className="needs-validation"
                                    onSubmit={this.submitHandler}
                                    noValidate
                                    >
                                        
                                        <div className="grey-text">
                                          <MDBRow>
                                            <MDBCol md="6">
                                              <div className="md-form">
                                                <label htmlFor="form38" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicTitle}</label>
                                                <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.title_ar} type="text" id="form38"  name="title_ar"  className="form-control" onChange={this.changeHandler} required validate/>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                            </MDBCol>
                                            <MDBCol md="6">
                                            <div className="md-form">
                                              <label htmlFor="form2" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishTitle}</label>
                                              <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.title_en} type="text" id="form2" name="title_en" className="form-control" onChange={this.changeHandler} required validate/>
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                            </MDBCol>
                                          </MDBRow>
                                          <MDBRow>
                                            <MDBCol md="12">
                                              <div className="md-form">
                                                <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.link}</label>
                                                <input style={{direction:this.props.isRTL?'rtl':'ltr'}} type="text" id="form65" name="link" className="form-control" onChange={this.changeHandler} validate required/>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                            </MDBCol>
                                          </MDBRow>

                                          {this.state.openPeriod ===false &&
                                            <MDBRow style={{flexFlow:this.props.isRTL?'row-reverse':'row'}}  >
                                                <MDBCol md="6">
                                                  <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.startDate}</label>
                                                  <DatePicker style={{width:'100%'}} placeholder={allStrings.startDate} onChange={this.onChangeStartDate}  />
                                                </MDBCol>
                                                <MDBCol md="6">
                                                  <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.endDate}</label>
                                                  <DatePicker style={{width:'100%'}} placeholder={allStrings.endDate} onChange={this.onChangeEndDate} />
                                                </MDBCol>
                                            </MDBRow>
                                          }{this.state.openPeriod ===false &&
                                            <MDBRow style={{flexFlow:this.props.isRTL?'row-reverse':'row'}}  >
                                                <MDBCol md="6">
                                                    <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.openPeriod}</label>
                                                    <TreeSelect virtual={false}
                                                        showSearch={false} 
                                                        style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                        treeCheckable = {false}         
                                                        treeNodeFilterProp="title"  
                                                        value={this.state.openPeriod}                    
                                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                        placeholder={allStrings.openPeriod}
                                                        onChange={(value) => {
                                                        console.log(value)
                                                        this.setState({openPeriod:value});
                                                        }} 
                                                    
                                                    >
                                                        <TreeNode value={true} title={allStrings.yes} key={true} />
                                                        <TreeNode value={false} title={allStrings.no} key={false} />
                                                        
                                                    </TreeSelect>
                                                </MDBCol>
                                            </MDBRow>
                                            }
                                            <MDBCol md="12">
                                                <div className="fileUpload2"> 
                                                    <input  key={this.state.img} type='file' className="upImgs2" onChange={this.fileChangedHandler} ></input>
                                                    <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                                                </div>
                                                <div className="preview">
                                                {this.state.img&&
                                                <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine'}}>
                                                    <img alt="" src={URL.createObjectURL(this.state.img)}  className="previewImg2"/>
                                                    <span className="imgName">{this.state.imgName}</span>
                                                    <MDBIcon icon="trash" className="mr-2 removeImg2"
                                                    onClick={() => this.removeFile()}  ></MDBIcon>
                                                </div>
                                                }
                                                </div>
                                                
                                            </MDBCol>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="text-center">
                                            <MDBBtn
                                              type="submit" style={{background:'#605363'}}
                                              rounded
                                              className="btn-block z-depth-1a"
                                            >
                                                <MDBIcon icon="plus" className="mr-2" />
                                                {allStrings.addAnoncement}        
                                            </MDBBtn>
                                        </div>
                                    </form>
                                    </MDBCol>
                                    
                                </MDBRow>
                            </MDBContainer>
                          </CardContent>
                        </Card>
                    </div>
                </MDBCol>
              </MDBRow>
          </MDBContainer>
        
      </div> 
    )
  }
}



const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(AddAnoncement)
  );