import React from 'react';
import Table from '../../component/table/table'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import { MDBContainer,  MDBRow, MDBCol} from "mdbreact";
import './products.css'
import {message} from 'antd';
import allStrings from '../../assets/strings'
import { connect } from 'react-redux';
import {NavLink, withRouter,Redirect} from 'react-router-dom'
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import { CSVLink } from "react-csv";
import Input from '@material-ui/core/Input';
import {hasValue} from '../../validations/validations'
let headers = [
  { label: "#", key: "id" },
  { label: "Name", key: "name" },
  { label: "Vendor", key: "vendor" },
  { label: "Category", key: "category" },
  { label: "subCategory", key: "subCategory" }, 
  { label: "Quantity", key: "quantity" },
  { label: "Brand", key: "brand" },
];
 
let csvData = [
  
];
class products extends React.Component {
  
  state={
    products:[],
    csvData:[],
    products2:[],
    loading:true,
    refresh:false,
    page:1,
    owner:this.props.location.state?this.props.location.state.owner:null,
    pages:1,
    search:null
  }

  constructor(props){
    super(props)
    this.getProductsWithoutPagenation()
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }

  componentDidMount(){
    this.getProducts(1,false)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }


  getProducts = (page,rest) =>{
    this.setState({loading:true})
    let url = `${BASE_END_POINT}products?page=${page}`
    let {search,owner} = this.state
    if(owner !== null){
      let str = '&owner='+ owner
      url += str
    }
    if(search !== null){
      let str = '&search='+ search
      url += str
    }
    console.log(url)
    if(rest) url = `${BASE_END_POINT}products?page=${page}`
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
       console.log("products   ",response.data)
      var data = response.data.data.map(item=>[
        item.id,
        item.name?item.name:"",
        item.owner?item.owner.firstname + " " + item.owner.lastname:"",
        item.quantity,
        item.category?item.category.name:"",
        item.subCategory?item.subCategory.name:"",
        item.available?allStrings.yes:allStrings.no,
        "action"])
      this.setState({
        loading:false,
        refresh:false,
        products:response.data.data,
        products2:data,
        //page:response.data.page,
        pages:response.data.pageCount,
        search:""
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })
  }
  getProductsWithoutPagenation = (page,rest) =>{
    let url = `${BASE_END_POINT}products/withoutPagenation/get?page=${page}`
    let {search,owner} = this.state
    if(owner !== null){
      let str = '&owner='+ owner
      url += str
    }
    if(search !== null){
      let str = '&search='+ search
      url += str
    }
    console.log(url)
    if(rest) url = `${BASE_END_POINT}products/withoutPagenation/get?page=${page}`
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log("products   ",response.data)
        csvData= response.data.data.map(item => {
          return {
            ...{id:item.id},
            ...{name:item.name?item.name:""},
            ...{vendor:item.owner?item.owner.firstname + " " + item.owner.lastname:""},
            ...{category:item.category?item.category.name:""},
            ...{subCategory:item.subCategory?item.subCategory.name:""},
            ...{quantity:item.quantity?item.quantity:""},
            ...{brand:item.brand?item.brand.name:""},
            
          };
        });
        console.log(csvData)
        this.setState({csvData:csvData})
      
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
    })
  }

  delete = (id) => {
    let l = message.loading('Wait..', 2.5)
    axios.delete(`${BASE_END_POINT}products/${id}`,{
     headers: {
       'Content-Type': 'application/json',
       'Authorization':`Bearer ${this.props.currentUser.token}`
     },
    })
    .then(response=>{
      console.log("product DELETED")
      l.then(() => message.success(allStrings.done, 2.5) )
      this.getProducts(this.state.page)
      this.getProductsWithoutPagenation()
    })
    .catch(error=>{
        console.log(error.response)
        l.then(() => message.error(allStrings.error, 2.5))
    })
 }

  addNewRecordButton = () =>(
    <div style={{width:'100%'}}>
      <NavLink to="addProduct">
        <Fab
          style={{background:'#605363',float:'left',margin:'50px 30px'}}
          variant="extended"
          size="medium"
          color="primary"
          aria-label="add"
        >
          <AddIcon />{allStrings.addNewRecored}
        </Fab>
      </NavLink>
        
    </div>
  )

  paginationButtons = () =>(
    <CardActions style={{display: 'inline-flex'}}>
        <Button 
        onClick={()=>{
          if(this.state.page!==1){
            this.getProducts(1)
            this.setState({page:1})
          }
        }}
        className="pagenation" >{allStrings.first}</Button>

        <Button 
        onClick={()=>{
         if(this.state.page>1){
            this.getProducts(this.state.page-1)
            this.setState({page:this.state.page-1})
         }
        }}
        className="pagenation">{allStrings.prev}</Button>
        
        <Pagination
        onChange={(event,page)=>{
        console.log("page   ",page)
        if(page!==this.state.page){
          this.getProducts(page)
          this.setState({page:page})
        }
        
        }} 
        defaultPage={1} page={this.state.page} count={this.state.pages} style={{color:`${'#605363'} !important`}} />
        
        <Button 
        onClick={()=>{
          if(this.state.page<this.state.pages){
            this.getProducts(this.state.page+1)
            this.setState({page:this.state.page+1})
          }
        }}
        className="pagenation">{allStrings.next}</Button>

        <Button 
         onClick={()=>{
          if(this.state.page!==this.state.pages){
            this.getProducts(this.state.pages)
            this.setState({page:this.state.pages})
          }
        }}
        className="pagenation">{allStrings.last}</Button>

    </CardActions>
  )

  render(){
    if (this.props.currentUser.data.type === "SUB-ADMIN" && !this.props.currentUser.data.permission.pages.includes('PRODUCTS')) return <Redirect to='/Home' />

  return (
    <div className="App">
        
      <MDBContainer> 
      <MDBRow>
        <MDBCol md="12">
          <div className="screenTable">
          <Table
            reload = {
              <span 
              style={{position: 'absolute',margin: '7px 0px',color: 'rgb(67, 66, 93)',fontWeight: '600',cursor: 'pointer'}}
              onClick={() => {this.setState({search:null});this.getProducts(1,true);this.getProductsWithoutPagenation(true)}}
              class="material-icons">
                replay
              </span>
            }
            csv = {
              <CSVLink 
                  data={this.state.csvData} 
                  headers={headers} 
                  filename={"products.csv"}
                >
                  <img alt="" src={require('../../assets/images/export-csv.png')} width="28"></img>
                </CSVLink>
            }
            searchInput = {
              <Input 
              onKeyPress={(e)=>{
                if (e.key === 'Enter') {
                  if(hasValue(""+e.target.value)){
                    console.log("ENTER   ",e.target.value)
                    this.setState({search:e.target.value})
                    this.getProducts(1,false)
                    this.getProductsWithoutPagenation(1,false)
                  }else{
                    message.warning(allStrings.noValue);
                    console.log("ENTER NO VALUE   ",e.target.value)
                  }
                }
               }}  
              placeholder={allStrings.Search} style={{direction:this.props.isRTL?'rtl':'ltr'}} inputProps={{ 'aria-label': 'description' }} defaultValue={this.state.search} />
            }
            title={allStrings.products}
            icon='local_mall'
            data={this.state.products}
            data2={this.state.products2}
            tableColumns={["#",allStrings.name,allStrings.vendor,allStrings.quantity,allStrings.category,allStrings.subCategory,allStrings.available,allStrings.action]}
            loading={this.state.loading}
            deleteUser={(id)=>{this.delete(id)}}
            path='/ProductInfo'
            actionIndex={6}
            enableEdit = {false}
            />
            {this.paginationButtons()}
            
            </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer >
    </div> 
  )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = {
  ChangeLanguage
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(products)
);

