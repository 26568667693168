import React from 'react';
import './add subCategory.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message ,TreeSelect} from "antd";
import { UploadOutlined } from '@ant-design/icons';

const { TreeNode } = TreeSelect;
//const { SHOW_PARENT } = TreeSelect;
class AddSubCategory extends React.Component {
  state = {
    name_en:'',
    name_ar:'',
    type:'PRODUCT',
    img:null,
    selectedFile: null,
    imagePreviewUrl:[],
    categories:[],
    parent:this.props.location.state?this.props.location.state.data:[]
  };

  constructor(props){
    super(props)
    console.log(this.props.location.state.data)
    this.getCategory()
    this.fileChangedHandler = this.fileChangedHandler.bind(this);
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
  }
  componentDidMount(){
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }

  getCategory = () => {
    axios.get(`${BASE_END_POINT}categories`)
    .then(response=>{
      console.log(response.data)
      this.setState({categories:response.data})
    })
    .catch(error=>{
      console.log("ALL categories ERROR")
      console.log(error.response)
    })
  }
  addSubCategory = () => {
    const {name_ar,name_en,img,parent,type} = this.state
    let l = message.loading('Wait..', 1.5)
    
    if(parent.length >0){
      l.then(() => message.success(allStrings.completeData, 0.5) )
    } 
    if(hasValue(name_ar)&&hasValue(name_en)){
        
        var data = new FormData()
        data.append('name_ar',name_ar)
        data.append('name_en',name_en)
        data.append('parent',parent)
        data.append('type',type)
        
        if(img != null){
          data.append('img',img); 
        }
        axios.post(`${BASE_END_POINT}categories`,data,{
        headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization':`Bearer ${this.props.currentUser.token}`
        },
        })
        .then(response=>{
        console.log("USER UPDATED  ",response.data)
        l.then(() => message.success(allStrings.done, 2.5) )
        this.props.history.goBack() 
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
    }
  }
  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.addSubCategory()
  };
  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  fileChangedHandler = event => {
    this.setState({
      img: event.target.files[0]
    })        
  }
  removeFile = () => {
    this.setState({
        img:null
    });
  }
  
     
  render(){
    if (this.props.currentUser.data.type === "SUB-ADMIN" && !this.props.currentUser.data.permission.pages.includes('CATEGORIES')) return <Redirect to='/Home' />

    return (
      <div className="App">
          <MDBContainer>
              <MDBRow>
                  <MDBCol xl ="12" lg = "12" md="12" sm="12">
                      <div className="infoCard">
                        <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                          <CardMedia
                          style={{height:"0px"}}
                          />
                          <CardHeader></CardHeader>
                          <div  style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                              <div className="topIcon" style={{background:'#605363'}}>
                                  <span className="material-icons" style={{color:'white',fontSize:23}}>category</span>
                              </div>
                              <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                              marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0'}}>
                              <p>{allStrings.AddCategory}</p>
                              </div>
                          </div>
                          <CardContent style={{minHeight:"390px",outline:'none'}}>
                            <MDBContainer>
                                <MDBRow>
                                    <MDBCol md="12">
                                    <form
                                    className="needs-validation"
                                    onSubmit={this.submitHandler}
                                    noValidate
                                    >
                                        {/*<p className="h5 text-center mb-4">Welcome</p>*/}
                                        <div className="grey-text">
                                            <MDBRow>
                                                <MDBCol md="6">
                                                    <div className="md-form">
                                                        <label htmlFor="form3" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishName}</label>
                                                        <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z0-9-,.@%$*&!_\s]/g,'') }  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.name_en} type="text" id="form3" name="name_en" className="form-control" onChange={this.changeHandler} required validate/>
                                                        <div class="valid-feedback">{allStrings.correct}</div>
                                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                    </div>
                                                </MDBCol>

                                                <MDBCol md="6">
                                                    <div className="md-form">
                                                        <label htmlFor="form3" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicName}</label>
                                                        <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') }
                                                        style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.name_ar} type="text" id="form2" name="name_ar" className="form-control" onChange={this.changeHandler} required validate/>
                                                        <div class="valid-feedback">{allStrings.correct}</div>
                                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                              <MDBCol md="6">
                                                <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.category}</label>
                                                <TreeSelect
                                                value= {this.state.parent}
                                                showSearch={true} 
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {false}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.chooseCategory}
                                                onChange={(value) => {
                                                  this.setState({parent:value});
                                                  console.log(value)
                                                  
                                                }} 
                                                >
                                                  {this.state.categories.map(val=>(
                                                      <TreeNode value={val.id} title={this.props.isRTL?val.name_ar:val.name_en} key={val.id} />
                                                  ))}
                                                </TreeSelect>
                                              </MDBCol>
                                              
                                              <MDBCol md="6">
                                              </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol md="12">
                                                    <div className="fileUpload2"> 
                                                        <input  key={this.state.img} type='file' className="upImgs2" onChange={this.fileChangedHandler} ></input>
                                                        <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                                                    </div>
                                                    <div className="preview">
                                                    {this.state.img&&
                                                    <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine'}}>
                                                        <img alt="" src={URL.createObjectURL(this.state.img)}  className="previewImg2"/>
                                                        <span className="imgName">{this.state.imgName}</span>
                                                        <MDBIcon icon="trash" className="mr-2 removeImg2"
                                                        onClick={() => this.removeFile()}  ></MDBIcon>
                                                    </div>
                                                    }
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="text-center">
                                            <MDBBtn
                                            style={{background:'#605363',color:'#fff'}}
                                            rounded
                                            type="submit"
                                            className="btn-block z-depth-1a"
                                            >
                                                <MDBIcon icon="plus" className="mr-2" />
                                                {allStrings.addSubCategory}        
                                            </MDBBtn>
                                          
                                            
                                        </div>
                                    </form>
                                    </MDBCol>
                                    
                                </MDBRow>
                            </MDBContainer>
                          </CardContent>
                        </Card>
                      </div>
                  </MDBCol>
              </MDBRow>
          </MDBContainer>
      </div> 
    )
  }
}



const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
});

const mapDispatchToProps = {
};

export default withRouter(
  connect(mapToStateProps, mapDispatchToProps)(AddSubCategory)
);