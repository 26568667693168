import React from 'react';
import './subCategory info.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { setUser } from "../../redux/actions/AuthActions";

class SubCategoryInfo extends React.Component {
  state = {
      id:this.props.location.state.data.id,
      category:this.props.location.state.data?this.props.location.state.data:[],
      name_en:this.props.location.state.data.name_en,
      name_ar:this.props.location.state.data.name_ar,
      priority:this.props.location.state.data.priority,
      img:null,
      selectedFile: null,
      imgName:'',
      dataType:'url',
      imagePreviewUrl:[],
      categories:[],
      loading:true,
      refresh:false,
      page:1,
      pages:1,
      modal: false,
  };

  constructor(props){
    super(props)
    this.fileChangedHandler = this.fileChangedHandler.bind(this);
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
  }
  componentDidMount(){
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }
  updateCategory = () => {
    const {name_ar,name_en,img,priority} = this.state

    if(hasValue(name_ar)&&hasValue(name_en)){
        let l = message.loading('Wait..', 2.5)
        var data = new FormData()
        data.append('name_ar',name_ar)
        data.append('name_en',name_en)
        data.append('priority',priority)
        if(img){
            data.append('img',img); 
        }
        axios.put(`${BASE_END_POINT}categories/${this.props.location.state.data.id}`,data,{
        headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization':`Bearer ${this.props.currentUser.token}`
        },
        })
        .then(response=>{
        console.log("USER UPDATED  ",response.data)
        l.then(() => message.success(allStrings.done, 2.5) )
        this.props.history.goBack() 
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
    }
  }

  deleteCategory = () => {
      let l = message.loading('Wait..', 2.5)
      axios.delete(`${BASE_END_POINT}categories/${this.props.location.state.data.id}`,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${this.props.currentUser.token}`
      },
      })
      .then(response=>{
        console.log("USER DELETED")
        l.then(() => message.success(allStrings.done, 2.5) )
        this.props.history.goBack() 
      })
      .catch(error=>{
          console.log(error.response)
          l.then(() => message.error(allStrings.error, 2.5))
      })
  }
  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.updateCategory()
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  fileChangedHandler = event => {
    this.setState({
      img: event.target.files[0],
      imgName: event.target.files[0].name,
      imgType:'data'
    })
  }
  removeFile = () => {
    this.setState({
        img:null,
        imgName:'',
        imgType:"url"
    });
  }
  
  imageSection = () =>{
      
      return(
        <MDBCol  xl ="4" lg = "5"  md="12" sm="12">
        <div className="infoCard">
            <div style={{width:"100%" ,padding:'10px'}}>
              <img alt="" style={{borderRadius:'6px',width:"100%",height:'280px'}} src={this.props.location.state.data.img}></img>
            </div>
            <MDBBtn onClick={()=>{this.deleteCategory()}} rounded color="danger" className="btn-block z-depth-1a">
                <MDBIcon icon="trash-alt" className="mr-2" />
                {allStrings.remove}
            </MDBBtn>
        </div>
        
    </MDBCol>
      )
  }
  render(){
    if (this.props.currentUser.data.type === "SUB-ADMIN" && !this.props.currentUser.data.permission.pages.includes('CATEGORIES')) return <Redirect to='/Home' />
    return (
      <div className="App">
          
          <MDBContainer>
            <MDBRow>
              <MDBCol xl={this.state.actionType==="VIEW"?"8":"12"} lg = {this.state.actionType==="VIEW"?"7":"12"} md="12" sm="12">
                <div className="infoCard">
                  <Card className="editCard" style={{ overflow: "visible", outline: "none",marginBottom: "0px"}} >
                    <CardMedia style={{ height: "0px" }} />
                    <CardHeader></CardHeader>
                    <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                        <div className="topIcon" style={{background:'#605363'}}>
                            <span className="material-icons" style={{color:'white',fontSize:23}}>category</span>
                        </div>
                        <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                        marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0'}}>
                        <p>{this.state.actionType==="VIEW"?allStrings.info:allStrings.edit}</p>
                        </div>
                    </div>
                    <CardContent style={{ minHeight: "390px", outline: "none" }}>
                      <MDBContainer>
                          <MDBRow>
                              <MDBCol md="12">
                              <form
                              className="needs-validation"
                              onSubmit={this.submitHandler}
                              noValidate
                              >
                                  <div className="grey-text">
                                      {this.state.actionType==="EDIT"&&
                                      <MDBRow>
                                        <MDBCol md="12">
                                            <div class="md-form">
                                                <label for="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                                <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.id} type="text" id="form8" class="form-control" onChange={this.changeHandler} required validate/>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                      </MDBRow>
                                      }
                                      <MDBRow>
                                        <MDBCol md="6">
                                          <div class="md-form">
                                            <label for="form38" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicName}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.name_ar} type="text" id="form38"  name="name_ar"  class="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                          </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                          <div class="md-form">
                                            <label for="form2" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishName}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z0-9\s]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.name_en} type="text" id="form2" name="name_en" class="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                          </div>
                                        </MDBCol>
                                      </MDBRow>
                                    
                                    
                                    {this.state.actionType==="EDIT"&&
                                      <MDBRow>
                                        <MDBCol md="12">
                                            <div className="fileUpload2"> 
                                                <input  key={this.state.img} type='file' className="upImgs2" onChange={this.fileChangedHandler} ></input>
                                                <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                                            </div>
                                            <div className="preview">
                                            {this.state.img&&
                                            <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine'}}>
                                                <img alt="" src={this.state.imgType==="data"?URL.createObjectURL(this.state.img):this.state.img}  className="previewImg2"/>
                                                <span className="imgName">{this.state.imgName}</span>
                                                <MDBIcon icon="trash" className="mr-2 removeImg2"
                                                onClick={() => this.removeFile()}  ></MDBIcon>
                                            </div>
                                            }
                                            </div>
                                            
                                        </MDBCol>
                                      </MDBRow>
                                    }
                                  </div>
                                  <br></br>
                                  <br></br>
                                  <div className="text-center">
                                    {this.state.actionType==="EDIT"&&
                                      <MDBBtn
                                      style={{background:'#605363',color:'#fff'}}
                                      rounded
                                      type="submit"
                                      className="btn-block z-depth-1a"
                                      >
                                          <MDBIcon icon="edit" className="mr-2" />
                                          {allStrings.updateCategory}        
                                      </MDBBtn>
                                    }
                                  </div>
                              </form>
                              </MDBCol>
                              
                          </MDBRow>
                      </MDBContainer>
                    </CardContent>
                  </Card>
                </div>
              </MDBCol>
              {this.state.actionType==="VIEW"&&this.imageSection()}    
            </MDBRow>
          </MDBContainer>
        
      </div> 
    )
  }
}



const mapToStateProps = (state) => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
});

const mapDispatchToProps = {
  setUser,

};

export default withRouter(
  connect(mapToStateProps, mapDispatchToProps)(SubCategoryInfo)
);