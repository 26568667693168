import React from 'react';
import './stock info.css'
import { MDBContainer,  MDBRow, MDBCol} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";

class stockInfo extends React.Component {
        state = {
            count:this.props.location.state.data?this.props.location.state.data.count:'',
            size:this.props.location.state.data?this.props.location.state.data.size:null,
            colors:this.props.location.state.data?this.props.location.state.data.colors:[],
            price:this.props.location.state.data.price?this.props.location.state.data.price:0,
            quantity:this.props.location.state.data.quantity?this.props.location.state.data.quantity:0,
        };

      constructor(props){
        super(props)
        if(this.props.isRTL){
          allStrings.setLanguage('ar')
        }else{
          allStrings.setLanguage('en')
        } 
        window.scrollTo(0,0)
      }

  render(){
    if (this.props.currentUser.data.type === "SUB-ADMIN" && !this.props.currentUser.data.permission.pages.includes('PRODUCTS')) return <Redirect to='/Home' />

  return (
    <div className="App">
        <MDBContainer> 
            <MDBRow>
             
                <MDBCol md="12">
                    <div className="infoCard">
                        <Card className="editCard" style={{ overflow: "visible", outline: "none",marginBottom: "0px"}} >
                        <CardMedia style={{ height: "0px" }} />
                        <CardHeader></CardHeader>
                        <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon" style={{background:'#605363'}}>
                                <span className="material-icons" style={{color:'white',fontSize:23}}>local_mall</span>
                            </div>
                            <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                            marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0'}}>
                            <p>{this.state.actionType==="VIEW"?allStrings.info:allStrings.info}</p>
                            </div>
                        </div>
                        <CardContent style={{ minHeight: "390px", outline: "none" }}>
                        <MDBContainer>
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                onSubmit={this.submitHandler}
                                noValidate
                                >
                                    <div className="grey-text">
                                    <MDBRow>
                                        <MDBCol md="12">
                                          <div class="md-form">
                                            <label for="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.price}</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.price} type="text" id="form80" class="form-control"  required validate/>
                                          </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="6">
                                          <div class="md-form">
                                            <label for="form81" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.size}</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} 
                                            value={this.state.size?this.state.size.name:""} type="text" id="form8" class="form-control"  required validate/>
                                          </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                          <div class="md-form">
                                            <label for="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.quantity}</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} 
                                            value={this.state.quantity} type="text" id="form8" class="form-control"  required validate/>
                                          </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.colors}</label>
                                    {
                                    this.state.colors.length > 0 &&
                                    this.state.colors.map(val =>(
                                      <MDBRow>
                                          <MDBCol md="4">
                                            <div class="md-form">
                                              <label for="form81" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.color}</label>
                                              <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} 
                                              value={val.color?val.color.name:""} type="text" id="form8" class="form-control"  required validate/>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="4">
                                            <div class="md-form">
                                              <label for="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.quantity}</label>
                                              <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} 
                                              value={val.quantity} type="text" id="form8" class="form-control"  required validate/>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="4">
                                            <div class="md-form">
                                              <label for="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.price}</label>
                                              <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} 
                                              value={val.price} type="text" id="form8" class="form-control"  required validate/>
                                            </div>
                                          </MDBCol>
                                      </MDBRow>
                                    ))
                                    }
                                    </div>
                                    <br></br>
                                    <br></br>
                                    
                                </form>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol>
            </MDBRow>
           
      </MDBContainer>
      
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(stockInfo)
  );