import React from 'react';
import Table from '../../component/table/table'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import { MDBContainer,  MDBRow, MDBCol} from "mdbreact";
import './orders.css'
import {message,TreeSelect} from 'antd';
import allStrings from '../../assets/strings'
import { connect } from 'react-redux';
import {NavLink, withRouter,Redirect} from 'react-router-dom'
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import { CSVLink } from "react-csv";
import { SearchOutlined  } from '@ant-design/icons';

const { TreeNode } = TreeSelect;
let headers = [
  { label: "#", key: "id" },
  { label: "Client", key: "client" },
  { label: "Total", key: "total" },
  { label: "deliveryCost", key: "deliveryCost" }, 
  { label: "FinalTotal", key: "finalTotal" },
  { label: "City", key: "city" },
  { label: "Area", key: "area" },
  { label: "PaymentSystem", key: "paymentSystem" },
  { label: "HasPromoCode", key: "hasPromoCode" },
];
 
let csvData = [
  
];
class orders extends React.Component {
  
  state={
    orders:[],
    orders2:[],
    csvData:[],
    loading:true,
    refresh:false,
    page:1,
    pages:1,
    search:null,
    vendor:this.props.location.state?this.props.location.state.vendor:null,
    driver:this.props.location.state?this.props.location.state.driver:null,
    client:this.props.location.state?this.props.location.state.client:null,
    status:null,
  }

  constructor(props){
    super(props)
    this.getOrdersWithoutPagenation()
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }

  componentDidMount(){
    this.getOrders(1,false)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }


  getOrders = (page,rest) =>{
    this.setState({loading:true})
    let url = `${BASE_END_POINT}orders?page=${page}`
    let {search,vendor,client,driver,status} = this.state
    if(vendor !== null){
      let str = '&vendor='+ vendor
      url += str
    }
    if(status !== null){
      let str = '&status='+ status
      url += str
    }
    if(client !== null){
      let str = '&client='+ client
      url += str
    }
    if(driver !== null){
      let str = '&driver='+ driver
      url += str
    }
    if(search !== null){
      let str = '&search='+ search
      url += str
    }
    console.log(url)
    if(rest) url = `${BASE_END_POINT}orders?page=${page}`
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
       console.log("orders   ",response.data)
      var data = response.data.data.map(item=>[
        item.id,
        item.client?item.client.firstname + " " + item.client.lastname:"",
        item.total,
        item.deliveryCost,
        item.finalTotal,
        item.city?item.city.name:"",
        item.area?item.area.name:"",
        item.paymentSystem ==="CASH"?allStrings.cash:allStrings.online,
        item.hasPromoCode?allStrings.yes:allStrings.no,
        "action"])
      this.setState({
        loading:false,
        refresh:false,
        orders:response.data.data,
        orders2:data,
        page:response.data.page,
        pages:response.data.pageCount,
        search:""
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })
  }
  getOrdersWithoutPagenation = (page,rest) =>{
    let url = `${BASE_END_POINT}orders/withoutPagenation/get?page=${page}`
    let {search,vendor,client,driver,status} = this.state
    if(vendor !== null){
      let str = '&vendor='+ vendor
      url += str
    }
    if(status !== null){
      let str = '&status='+ status
      url += str
    }
    if(client !== null){
      let str = '&client='+ client
      url += str
    }
    if(driver !== null){
      let str = '&driver='+ driver
      url += str
    }
    if(search !== null){
      let str = '&search='+ search
      url += str
    }
    console.log(url)
    if(rest) url = `${BASE_END_POINT}orders/withoutPagenation/get?page=${page}`
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log("orders   ",response.data)
        csvData= response.data.data.map(item => {
          return {
            ...{id:item.id},
            ...{client:item.client?item.client.firstname + " " + item.client.lastname:""},
            ...{total:item.total},
            ...{deliveryCost:item.deliveryCost},
            ...{finalTotal:item.finalTotal},
            ...{city:item.city?item.city.name:""},
            ...{area:item.area?item.area.name:""},
            ...{paymentSystem:item.paymentSystem === "CASH"?allStrings.cash:allStrings.online},
            ...{hasPromoCode:item.hasPromoCode},
            
          };
        });
        console.log(csvData)
        this.setState({csvData:csvData})
      
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
    })
  }

  delete = (id) => {
    let l = message.loading('Wait..', 2.5)
    axios.delete(`${BASE_END_POINT}orders/${id}`,{
     headers: {
       'Content-Type': 'application/json',
       'Authorization':`Bearer ${this.props.currentUser.token}`
     },
    })
    .then(response=>{
      console.log("product DELETED")
      l.then(() => message.success(allStrings.done, 2.5) )
      this.getOrders(this.state.page)
      this.getOrdersWithoutPagenation()
    })
    .catch(error=>{
        console.log(error.response)
        l.then(() => message.error(allStrings.error, 2.5))
    })
 }

  addNewRecordButton = () =>(
    <div style={{width:'100%'}}>
      <NavLink to="addOrder">
        <Fab
          style={{background:'#605363',float:'left',margin:'50px 30px'}}
          variant="extended"
          size="medium"
          color="primary"
          aria-label="add"
        >
          <AddIcon />{allStrings.addNewRecored}
        </Fab>
      </NavLink>
        
    </div>
  )

  paginationButtons = () =>(
    <CardActions style={{display: 'inline-flex'}}>
        <Button 
        onClick={()=>{
          if(this.state.page!==1){
            this.getOrders(1)
            this.setState({page:1})
          }
        }}
        className="pagenation" >{allStrings.first}</Button>

        <Button 
        onClick={()=>{
         if(this.state.page>1){
            this.getOrders(this.state.page-1)
            this.setState({page:this.state.page-1})
         }
        }}
        className="pagenation">{allStrings.prev}</Button>
        
        <Pagination
        onChange={(event,page)=>{
        console.log("page   ",page)
        if(page!==this.state.page){
          this.getOrders(page)
          this.setState({page:page})
        }
        
        }} 
        defaultPage={1} page={this.state.page} count={this.state.pages} style={{color:`${'#605363'} !important`}} />
        
        <Button 
        onClick={()=>{
          if(this.state.page<this.state.pages){
            this.getOrders(this.state.page+1)
            this.setState({page:this.state.page+1})
          }
        }}
        className="pagenation">{allStrings.next}</Button>

        <Button 
         onClick={()=>{
          if(this.state.page!==this.state.pages){
            this.getOrders(this.state.pages)
            this.setState({page:this.state.pages})
          }
        }}
        className="pagenation">{allStrings.last}</Button>

    </CardActions>
  )

  render(){
    if (this.props.currentUser.data.type === "SUB-ADMIN" && !this.props.currentUser.data.permission.pages.includes('ORDERS')) return <Redirect to='/Home' />

  return (
    <div className="App">
        
      <MDBContainer> 
      <MDBRow>
        <MDBCol md="12">
          <div className="screenTable">
          <Table
            enableEdit = {false}
            settings = {
              <div>
                <i class="fab fa-searchengin searchIcon" onClick={()=>this.setState({showFilter:!this.state.showFilter})}></i>
                <span 
                style={{position: 'relative',padding: '4px',color: '#659FF1',fontSize: '25px',borderRadius:'50%',top:'4px',cursor: 'pointer'}}
                onClick={() => {this.setState({search:null,status:null});this.getOrders(1,true);this.getOrdersWithoutPagenation(true)}}
                class="material-icons">
                  replay
                </span>
                <CSVLink 
                  data={this.state.csvData} 
                  headers={headers} 
                  filename={"deals.csv"}
                  style={{padding: '10px',position: 'relative',top: '-5px'}}
                  >
                  <img crossorigin="anonymous"alt="" src={require('../../assets/images/export-csv.png')} width="28"></img>
                </CSVLink>
              </div>
              
            }
            filter ={
              <div>
                <div className={this.state.showFilter?'showClass160':'hideClass'}>
                  <MDBRow>
                    <MDBCol xl="6" lg="6" md="6" sm="12">
                      <div class="md-form">
                      <input style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',fontWeight:'300'}} type="text" id="form605"  class="form-control"
                        placeholder={allStrings.Search}
                        onChange={(e)=>{
                          this.setState({search:e.target.value})
                        }}  
                      />
                      </div>
                    </MDBCol>
                    <MDBCol xl="6" lg="6" md="6" sm="12">
                      <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.status}</label>
                      
                      <TreeSelect virtual={false}
                          showSearch={false}
                          value={this.state.status}
                          style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                          treeCheckable = {false}         
                          treeNodeFilterProp="title"                      
                          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                          placeholder={allStrings.status}
                          onChange={(value) => {
                          console.log(value)
                          this.setState({status:value});
                          }} 
                      
                      >
                          <TreeNode value="ACCEPTED" title={allStrings.accept} key="ACCEPTED" />
                          <TreeNode value="CANCELED" title={allStrings.canceled} key="CANCELED" />
                          <TreeNode value="OUT-FOR-DELIVERY" title={allStrings.outForDelivery} key="OUT-FOR-DELIVERY" />
                          <TreeNode value="ASSIGNED" title={allStrings.assigned} key="ASSIGNED" />
                          <TreeNode value="OFFER-PHASE" title={allStrings.refused} key="REFUSED" />
                          <TreeNode value="DELIVERED" title={allStrings.deliverd} key="DELIVERED" />
                        
                      </TreeSelect>
                    </MDBCol>
                    
                  </MDBRow>
                  <MDBRow>
                  <Button style={{background: '#233b5b',color: '#fff',width: '100%',margin: '32px auto'}} 
                        onClick={()=>{this.getOrders(1);this.getOrdersWithoutPagenation()}}><SearchOutlined /> {allStrings.Search}</Button>
                    
                  </MDBRow>
                  
                </div>
              </div>
            }
            title={allStrings.orders}
            icon='shopping_cart'
            data={this.state.orders}
            data2={this.state.orders2}
            tableColumns={["#",allStrings.client,allStrings.total,allStrings.deliveryCost,allStrings.finalTotal,allStrings.city,allStrings.area,allStrings.paymentSystem,allStrings.hasPromoCode,allStrings.action]}
            loading={this.state.loading}
            deleteUser={(id)=>{this.delete(id)}}
            path='/OrderInfo'
            actionIndex={6}
            />
            {this.paginationButtons()}
            {/*this.addNewRecordButton()*/}
            </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer >
    </div> 
  )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = {
  ChangeLanguage
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(orders)
);

