import React from 'react';
import './order info.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import Skeleton from 'react-loading-skeleton';
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@material-ui/icons';
import { message } from "antd";
import moment from "moment";
import ReactToPrint from 'react-to-print';
 
class ComponentToPrint extends React.Component {
  
    render() {
        let data = this.props.data
        return (
            <div style={{padding:'50px'}}>
                <MDBContainer> 
                    <br></br>
                    <br></br>
                    <MDBRow style={{textAlign:'left'}}>
                    <MDBCol size="4">
                        <p style={{fontWeight:'500',fontSize:'60px'}}>ORDER</p> 
                    </MDBCol>
                    <MDBCol size="4" >
                       
                    </MDBCol>
                    <MDBCol size="4"></MDBCol>
                    </MDBRow>
                    <br></br>
                    <br></br>
                    <br></br>
                    <MDBRow style={{textAlign:'left'}}>
                    <MDBCol size="3">
                        <p style={{fontWeight:'500'}}>Order Number</p>  
                        <p style={{fontWeight:'400'}}># {this.props.data.id}</p>  
                    </MDBCol>
                    <MDBCol size="3">
                        <p style={{fontWeight:'500'}}>Order Placed</p>  
                        <p style={{fontWeight:'400'}}>{moment(data.createdAt).format('YYYY-MM-DD HH:mm a')}</p>  
                    </MDBCol>
                    <MDBCol size="3">
                        <p style={{fontWeight:'500'}}>Payment Method</p>  
                        <p style={{fontWeight:'400'}}>{this.props.data.paymentSystem==="CASH"?'Cash On Deliverd':'Online Payment'}</p>  
                    </MDBCol>
                    <MDBCol size="3"></MDBCol>
                    </MDBRow>
                    <MDBRow style={{textAlign:'left'}}>
                    <MDBCol size="6">
                        <p style={{fontWeight:'500'}}>Customer Address</p>  
                        <p style={{fontWeight:'400',marginBottom:'0px',fontSize:'15px'}}>
                            <span>Customer Name : </span>
                            <span>{this.props.data.client?this.props.data.client.firstname + " "+this.props.data.client.lastname :""}</span>
                        </p> 
                        <p style={{fontWeight:'400',marginBottom:'0px',fontSize:'15px'}}>
                            <span>City : </span>
                            <span>{this.props.data.city?this.props.data.city.name:""}</span>
                        </p> 
                        <p style={{fontWeight:'400',marginBottom:'0px',fontSize:'15px'}}>
                            <span>Area : </span>
                            <span>{this.props.data.area?this.props.data.area.name:""}</span>
                        </p> 
                        <p style={{fontWeight:'400',fontSize:'15px'}}>
                            <span>Phone : </span>
                            <span>{this.props.data.phone?this.props.data.phone:""}</span>
                        </p>  
                    </MDBCol>
                    <MDBCol size="6"></MDBCol>
                    </MDBRow>
                    <p style={{fontWeight:'500',fontSize:'16px'}}>Description</p>
                    
                    <MDBRow>
                    <MDBCol  md="12" sm="12" style={{borderBottom: '1px solid #DEE2E6',borderTop: '1px solid #DEE2E6'}}>
                    <div className='tabelDev'>     
                        <div class="row">
                        <table class="table table-sm">
                            <thead>
                                <tr class="thead-dark" >
                                    <th>Item Name</th>
                                    <th>Size</th>
                                    <th>Color</th>
                                    <th>Quantity</th>
                                    <th>Unit Cost</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                            {this.props.data.orderItems.map(val=>(
                                <tr>
                                <td style={{padding:'8px'}}>{val.product?val.product.name:""}</td>
                                <td style={{padding:'8px'}}>{val.size?val.size.name:""}</td>
                                <td style={{padding:'8px'}}>{val.color?val.color.name:""}</td>
                                <td style={{padding:'8px'}}>{val.count?val.count:""}</td>
                                <td style={{padding:'8px'}}>{
                                val.product?val.unitCost?val.unitCost:val.product.stock.find(o => o.size === val.size.id).price:""
                                }</td>
                                <td style={{padding:'8px'}}>{val.count * (val.unitCost?val.unitCost:val.product?val.product.stock.find(o => o.size === val.size.id).price:0)}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        </div>
                    </div> 
                    </MDBCol>
                    </MDBRow>
                    <br></br>
                    <MDBRow style={{textAlign:'right',marginRight: '18px',marginBottom:'400px'}}>
                    <MDBCol size="7"></MDBCol>
                    <MDBCol size="5">
                        <MDBRow>
                            <MDBCol size="8"  style={{textAlign:'center'}}>
                                <span style={{fontWeight:'600'}}>SUBTOTAL </span>
                            </MDBCol>
                            <MDBCol size="4"  style={{textAlign:'center'}}>
                                <span style={{fontWeight:'400',marginBottom:'0px',fontSize:'15px'}}>{this.props.data.total?this.props.data.total:""}</span>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="8"  style={{textAlign:'center'}}>
                                <span style={{fontWeight:'600'}}>DISCOUNT </span>
                            </MDBCol>
                            <MDBCol size="4" style={{textAlign:'center'}}>
                                <span style={{fontWeight:'400',marginBottom:'0px',fontSize:'15px'}}>{this.props.data.discount?this.props.data.discount:0}</span>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="8"  style={{textAlign:'center'}}>
                                <span style={{fontWeight:'600'}}>SHIPPING </span>
                            </MDBCol>
                            <MDBCol size="4"  style={{textAlign:'center'}}>
                                <span style={{fontWeight:'400',marginBottom:'0px',fontSize:'15px'}}> {this.props.data.deliveryCost?this.props.data.deliveryCost:0}</span>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="8"  style={{textAlign:'center'}}>
                                <span style={{fontWeight:'600'}}>TOTAL </span>
                            </MDBCol>
                            <MDBCol size="4"  style={{textAlign:'center'}}>
                                <span style={{fontWeight:'400',marginBottom:'0px',fontSize:'15px'}}>{this.props.data.finalTotal?this.props.data.finalTotal:""}</span>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol size="12">
                            <div style={{border:'2px solid',padding:'5px 10px',fontWeight: '500'}}>
                                <p style={{marginBottom:'5px'}}>-	Returns should be within 1 week of delivery date	</p>
                                <p>-	You can always reach us on: 010000000000 - 011111111111</p>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        )
    }
}
class orderInfo extends React.Component {
    state = {
        actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
        id:this.props.location.state.data.id,
        paymentSystem:this.props.location.state.data.paymentSystem,
        status:this.props.location.state.data.status,
        total:this.props.location.state.data.total,
        deliveryCost:this.props.location.state.data.deliveryCost,
        finalTotal:this.props.location.state.data.finalTotal,
        client:this.props.location.state.data.client?this.props.location.state.data.client:null,
        vendor:this.props.location.state.data.vendor?this.props.location.state.data.vendor:null,
        driver:this.props.location.state.data.driver?this.props.location.state.data.driver:null,
        city:this.props.location.state.data.city?this.props.location.state.data.city:null,
        area:this.props.location.state.data.area?this.props.location.state.data.area:null,
        address:this.props.location.state.data.address?this.props.location.state.data.address:"",
        discount:this.props.location.state.data.discount?this.props.location.state.data.discount:0,
        hasPromoCode:this.props.location.state.data.hasPromoCode?this.props.location.state.data.hasPromoCode:false,
        phone:this.props.location.state.data.phone?this.props.location.state.data.phone:"",
        promoCode:this.props.location.state.data.promoCode?this.props.location.state.data.promoCode:null,
        orderItems:this.props.location.state.data.orderItems?this.props.location.state.data.orderItems:[],
        driverCost:'',
        reason:'',
        deliveredDateMillSec:'',
        cancelDateMillSec:'',
        createdAt:this.props.location.state.data.createdAt?this.props.location.state.data.createdAt:'',
        order:null
    };

    constructor(props){
        super(props)
        this.getOrderData(this.props.location.state.data.id)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }
    getOrderData = (id) => {
        axios.get(`${BASE_END_POINT}orders/${this.props.location.state.data.id}/getMainOrder`,{
         headers: {
           'Content-Type': 'application/json',
           'Authorization':`Bearer ${this.props.currentUser.token}`
         },
        })
        .then(response=>{
            console.log(response.data)
            this.setState({
                loading: false,
                order:response.data.data,
                paymentSystem:response.data.data.paymentSystem,
                status:response.data.data.status,
                total:response.data.data.total,
                deliveryCost:response.data.data.deliveryCost,
                finalTotal:response.data.data.finalTotal,
                client:response.data.data.client?response.data.data.client:null,
                vendor:response.data.data.vendor?response.data.data.vendor:null,
                driver:response.data.data.driver?response.data.data.driver:null,
                driverCost:response.data.data.driverCost?response.data.data.driverCost:"",
                city:response.data.data.city?response.data.data.city:null,
                area:response.data.data.area?response.data.data.area:null,
                address:response.data.data.address?response.data.data.address:"",
                discount:response.data.data.discount?response.data.data.discount:0,
                hasPromoCode:response.data.data.hasPromoCode?response.data.data.hasPromoCode:false,
                phone:response.data.data.phone?response.data.data.phone:"",
                promoCode:response.data.data.promoCode?response.data.data.promoCode:null,
                deliveredDateMillSec:response.data.data.deliveredDateMillSec?response.data.data.deliveredDateMillSec:"",
                cancelDateMillSec:response.data.data.cancelDateMillSec?response.data.data.cancelDateMillSec:"",
                createdAt:response.data.data.createdAt?response.data.data.createdAt:"",
                orderItems:response.data.data.orderItems?response.data.data.orderItems:[],
            })
        })
        .catch(error=>{
            console.log(error.response)
        })
    }
    deleteOrder = () => {
        let l = message.loading('Wait..', 2.5)
        axios.delete(`${BASE_END_POINT}orders/${this.props.location.state.data.id}`,{
         headers: {
           'Content-Type': 'application/json',
           'Authorization':`Bearer ${this.props.currentUser.token}`
         },
        })
        .then(response=>{
          console.log("order DELETED")
          l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.goBack() 
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
    }
    productTable = () =>{
        return(
        <MDBRow>
        <MDBCol md="12">
            <div className="infoTable">
                <div className="topInfoTable" style={{ background: '#605363' }}>
                    <h4>
                    <MDBIcon icon="layer-group" /> {allStrings.products}
                    </h4>
                </div>
                <div className="tableDate">
                    <div class="table-responsive">
                        <table class="table table-striped">
                        <thead>
                            <tr style={{textAlign:'center'}}>
                                <th>{allStrings.product}</th>
                                <th>{allStrings.size}</th>
                                <th>{allStrings.color}</th>
                                <th>{allStrings.count}</th>
                                <th>{allStrings.vendor}</th>
                                <th>{allStrings.more}</th>
                            </tr>
                        </thead>
                        <tbody>
                        {this.state.loading?
                            <tr style={{height: '200px'}}>
                                <th colspan="6" className="noData">
                                <Skeleton height={400}/> 
                                </th>
                            </tr>
                            :
                            this.state.orderItems.length > 0 ?
                            this.state.orderItems.map(val =>(
                            <tr style={{textAlign:'center'}}>
                                <td>{val.product?val.product.name:""}</td>
                                <td>{val.size?val.size.name:""}</td>
                                <td>{val.color?val.color.name:""}</td>
                                <td>{val.count}</td>
                                <td>{val.vendor?val.vendor.firstname + " " + val.vendor.lastname:""}</td>
                                <td onClick={()=>{this.props.history.push("productOrderInfo",{data:val}) }} >
                                <span style={{margin: "4px",cursor:'pointer'}}>
                                    <Tooltip title="details" placement="top">
                                    <Info style={{color:'#33d633'}}/>
                                    </Tooltip>
                                </span>
                                </td>
                            </tr>
                                ))
            
                                :
                                <tr style={{height: '200px'}}>
                                    <th colspan="6" className="noData">
                                    <p style={{marginTop:'4rem',textAlign:'center'}}>{allStrings.noData}</p> 
                                    </th>
                                </tr>
                            }
                        </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </MDBCol>
    </MDBRow>
        )
    }
    
  render(){
    if (this.props.currentUser.data.type === "SUB-ADMIN" && !this.props.currentUser.data.permission.pages.includes('ORDERS')) return <Redirect to='/Home' />
    console.log("order",this.state.order)
  return (
    <div className="App">
        
        <MDBContainer> 
            <MDBRow>  
                <MDBCol  md="12">
                    <div className="infoCard">
                    <Card className="editCard" style={{ overflow: "visible", outline: "none",marginBottom: "0px"}} >
                        <CardMedia style={{ height: "0px" }} />
                        <CardHeader></CardHeader>
                        <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon" style={{background:'#605363'}}>
                                <span className="material-icons" style={{color:'white',fontSize:23}}>shopping_cart</span>
                            </div>
                            <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                            marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0'}}>
                            <p>{this.state.actionType==="VIEW"?allStrings.info:allStrings.edit}</p>
                            </div>
                        </div>
                        <CardContent style={{ minHeight: "390px", outline: "none" }}>
                        <MDBContainer>
                            
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                noValidate
                                >
                                    <div className="grey-text">
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div class="md-form">
                                            <label for="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                            <input  disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.id} type="text" id="form65" class="form-control"    />
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div class="md-form">
                                            <label for="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.status}</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} 
                                            value={this.state.status === "PENDING"?allStrings.pending:this.state.status==="ACCEPTED"?allStrings.accepted:this.state.status==="REFUSED"?allStrings.refused:this.state.status==="CANCELED"?allStrings.canceled:this.state.status==="OUT-FOR-DELIVERY"?allStrings.outForDelivery:allStrings.deliverd} type="text" id="form56" class="form-control"  />
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        
                                        <MDBCol md="6">
                                            <div class="md-form">
                                            <label for="form561" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.paymentSystem}</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} 
                                            value={this.state.paymentSystem === "CASH"?allStrings.cash:allStrings.online} type="text" id="form561" class="form-control"  />
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div class="md-form">
                                            <label for="form615" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.hasPromoCode}</label>
                                            <input  disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.hasPromoCode?allStrings.yes:allStrings.no} type="text" id="form615" class="form-control"    />
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        
                                        <MDBCol md="6">
                                            <div class="md-form">
                                            <label for="form61" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.discount}</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} 
                                            value={this.state.discount} type="text" id="form16" class="form-control"  />
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div class="md-form">
                                            <label for="form01" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.total}</label>
                                            <input  disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} 
                                            value={this.state.total} type="text" id="form01" class="form-control"    />
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div class="md-form">
                                            <label for="form01" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.driverCost}</label>
                                            <input  disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} 
                                            value={this.state.driverCost} type="text" id="form01" class="form-control"    />
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div class="md-form">
                                            <label for="form02" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.deliveryCost}</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} 
                                            value={this.state.deliveryCost} type="text" id="form02" class="form-control"  />
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow style={{flexFlow:this.props.isRTL?'row-reverse':'row' }}>
                                        <MDBCol md="6">
                                            <div class="md-form">
                                            <label for="form03" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.finalTotal}</label>
                                            <input  disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} 
                                            value={this.state.finalTotal} type="text" id="form03" class="form-control"    />
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div class="md-form">
                                            <label for="form08" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.phone}</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} 
                                            value={this.state.phone} type="text" id="form08" class="form-control"  />
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow style={{flexFlow:this.props.isRTL?'row-reverse':'row' }}> 
                                        <MDBCol md="6">
                                            <div class="md-form">
                                            <label for="form001" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.city}</label>
                                            <input  disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} 
                                            value={this.state.city?this.state.city.name:""} type="text" id="form001" class="form-control"    />
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div class="md-form">
                                            <label for="form018" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.area}</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} 
                                            value={this.state.area?this.state.area.name:""} type="text" id="form018" class="form-control"  />
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                      <MDBCol md="12">
                                        <div class="md-form">
                                          <label for="form920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.address}</label>
                                          <br></br>
                                          <textarea disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} type="text" id="form920" class="form-control" required validate  value={this.state.address} />
                                        </div>
                                      </MDBCol>
                                    </MDBRow>
                                    <MDBRow style={{flexFlow:this.props.isRTL?'row-reverse':'row' }}> 
                                        <MDBCol md="6">
                                            <div class="md-form">
                                            <label for="form021" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.date}</label>
                                            <input  disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} 
                                            value={moment(this.state.createdAt).format('lll')} type="text" id="form021" class="form-control"    />
                                            </div>
                                        </MDBCol>
                                        {this.state.status ==="DELIVERED"&&
                                        <MDBCol md="6">
                                            <div class="md-form">
                                            <label for="form028" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.delivaryDate}</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} 
                                            value={moment(this.state.deliveredDateMillSec).format('lll')} type="text" id="form028" class="form-control"  />
                                            </div>
                                        </MDBCol>
                                        }
                                        {this.state.status ==="CANCELED"&&
                                        <MDBCol md="6">
                                            <div class="md-form">
                                            <label for="form028" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.cancelDate}</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} 
                                            value={moment(this.state.cancelDateMillSec).format('lll')} type="text" id="form028" class="form-control"  />
                                            </div>
                                        </MDBCol>
                                        }
                                        
                                    </MDBRow>
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="10" xs="10">
                                            <div class="md-form">
                                            <label for="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.client}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.client?this.state.client.firstname + ' ' + this.state.client.lastname:''} type="text" id="form80" class="form-control"  />
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="2" xs="2">
                                       
                                        <p style={{textAlign: 'center',marginTop: '2rem',color: '#2BBBAD'}}>
                                            <span style={{cursor:'pointer',position: 'absolute',padding: '3px'}} class="material-icons"
                                            onClick={()=>{this.props.history.push('userInfo',{data:this.state.client}) }}>
                                              remove_red_eye
                                            </span>
                                            <span style={{cursor:'pointer',fontSize: '16px',fontWeight: '500',marginLeft: '2rem'}}
                                              onClick={()=>{this.props.history.push('userInfo',{data:this.state.client}) }}>
                                              {allStrings.show}
                                            </span> 
                                          </p>
                                        </MDBCol>
                                    </MDBRow>
                                    {this.state.vendor &&
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                    <MDBCol md="10" xs="10">
                                        <div class="md-form">
                                        <label for="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.vendor}</label>
                                        <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.vendor?this.state.vendor.firstname + ' ' + this.state.vendor.lastname:''} type="text" id="form80" class="form-control"  />
                                        </div>
                                    </MDBCol>
                                    <MDBCol md="2" xs="2">
                                   
                                    <p style={{textAlign: 'center',marginTop: '2rem',color: '#2BBBAD'}}>
                                        <span style={{cursor:'pointer',position: 'absolute',padding: '3px'}} class="material-icons"
                                        onClick={()=>{this.props.history.push('userInfo',{data:this.state.vendor}) }}>
                                          remove_red_eye
                                        </span>
                                        <span style={{cursor:'pointer',fontSize: '16px',fontWeight: '500',marginLeft: '2rem'}}
                                          onClick={()=>{this.props.history.push('userInfo',{data:this.state.vendor}) }}>
                                          {allStrings.show}
                                        </span> 
                                      </p>
                                    </MDBCol>
                                    </MDBRow>
                                    }
                                    {this.state.driver &&
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                    <MDBCol md="10" xs="10">
                                        <div class="md-form">
                                        <label for="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.driver}</label>
                                        <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.driver?this.state.driver.firstname + ' ' + this.state.driver.lastname:''} type="text" id="form80" class="form-control"  />
                                        </div>
                                    </MDBCol>
                                    <MDBCol md="2" xs="2">
                                   
                                    <p style={{textAlign: 'center',marginTop: '2rem',color: '#2BBBAD'}}>
                                        <span style={{cursor:'pointer',position: 'absolute',padding: '3px'}} class="material-icons"
                                        onClick={()=>{this.props.history.push('userInfo',{data:this.state.driver}) }}>
                                          remove_red_eye
                                        </span>
                                        <span style={{cursor:'pointer',fontSize: '16px',fontWeight: '500',marginLeft: '2rem'}}
                                          onClick={()=>{this.props.history.push('userInfo',{data:this.state.driver}) }}>
                                          {allStrings.show}
                                        </span> 
                                      </p>
                                    </MDBCol>
                                    </MDBRow>
                                    }
                                    {this.state.hasPromoCode &&
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="10" xs="10">
                                            <div class="md-form">
                                            <label for="form810" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.coupon}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.promoCode?this.state.promoCode.couponNumber:''} type="text" id="form810" class="form-control"  />
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="2" xs="2">
                                       
                                        <p style={{textAlign: 'center',marginTop: '2rem',color: '#2BBBAD'}}>
                                            <span style={{cursor:'pointer',position: 'absolute',padding: '3px'}} class="material-icons"
                                            onClick={()=>{this.props.history.push('couponInfo',{data:this.state.promoCode}) }}>
                                              remove_red_eye
                                            </span>
                                            <span style={{cursor:'pointer',fontSize: '16px',fontWeight: '500',marginLeft: '2rem'}}
                                              onClick={()=>{this.props.history.push('couponInfo',{data:this.state.promoCode}) }}>
                                              {allStrings.show}
                                            </span> 
                                          </p>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    {this.state.order&&this.state.actionType==="VIEW"&&
                                    <div style={{textAlign: "center"}}>
                                        <ReactToPrint
                                        documentTitle={"order reciept # "+ this.state.id}

                                        trigger={() => {
                                            return (
                                            <div >
                                                <img alt=""  style={{cursor:'pointer',width:'40px'}} src={require('../../assets/images/icons8_pdf.png')}></img>
                                                <span
                                                style={{
                                                color: '#FF3B40',
                                                fontWeight: '600',
                                                fontSize: '16px',
                                                padding: '10px 10px',
                                                cursor:'pointer'
                                                }}>{allStrings.print}</span>
                                            </div>
                                            ) ;
                                        }}
                                        content={() => this.componentRef}
                                        />
                                        <div style={{display:'none'}}>
                                        <ComponentToPrint  data={this.state.order}  ref={el => (this.componentRef = el)} />
                                        </div>
                                    </div>
                                    }
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="text-center">
                                        <MDBBtn onClick={()=>{this.deleteOrder()}} rounded color="danger" className="btn-block z-depth-1a">
                                            <MDBIcon icon="trash-alt" className="mr-2" />
                                            {allStrings.remove}
                                        </MDBBtn>
                                    </div>
                                </form>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol>
            </MDBRow>
           {this.productTable()}
           
        </MDBContainer>
      
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(orderInfo)
  );