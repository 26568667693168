import React from 'react';
import './city info.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon,MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Tooltip from '@material-ui/core/Tooltip';
import { Info,Edit } from '@material-ui/icons';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message,Spin,Empty } from "antd";
import Pagination from '@material-ui/lab/Pagination';
import Skeleton from 'react-loading-skeleton';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
class CityInfo extends React.Component {
  state = {
      actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
      id:this.props.location.state.data.id,
      country:this.props.location.state.country?this.props.location.state.country:1,
      name_en:this.props.location.state.data.name_en,
      name_ar:this.props.location.state.data.name_ar,
      areas:[],
      areaName_en:'',
      areaName_ar:'',
      deliveryCost:0,
      modal:false,
      loading:true,
      page:1,
      pages:1
  };

  constructor(props){
      super(props)
      window.scrollTo(0,0)
      if(this.props.isRTL){
          allStrings.setLanguage('ar')
      }else{
          allStrings.setLanguage('en')
      } 
  }

  componentDidMount(){
      this.getAreas(1)
      if(this.props.isRTL){
          allStrings.setLanguage('ar')
      }else{
          allStrings.setLanguage('en')
      } 
  }
  getAreas = (page) => {
      axios.get(`${BASE_END_POINT}cities/${this.props.location.state.data.id}/areas?page=${page}`)
      .then(response=>{
          console.log('AREAS  ',response.data)
          this.setState({
            areas:response.data.data,
            pages:response.data.pageCount,
            page:response.data.page,
            loading:false,
          })
      })
      .catch(error=>{
          console.log('AREAS ERROR  ',error)
          console.log('AREAS ERROR  ',error.respose)
      })
  }

  updateCity = () => {
      const {name_en,name_ar,country,deliveryCost} = this.state
      if(hasValue(name_en)&&hasValue(name_ar)){
          let l = message.loading('Wait..', 2.5)
          const data={
            'name_en':name_en,
            'name_ar':name_ar,
            'country':country
          }
          axios.put(`${BASE_END_POINT}/cities/${this.props.location.state.data.id}`,JSON.stringify(data),{
          headers: {
          'Content-Type': 'application/json',
          'Authorization':`Bearer ${this.props.currentUser.token}`,
          'Accept-Language':this.props.isRTL?'ar':'en'
          },
          })
          .then(response=>{
          console.log("CITY UPDATED  ",response.data)
          l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.goBack() 
          })
          .catch(error=>{
              console.log(error.response)
              l.then(() => message.error(allStrings.error, 2.5))
          })
      }
  }

  deleteCity = () => {
      let l = message.loading('Wait..', 2.5)
      axios.delete(`${BASE_END_POINT}cities/${this.props.location.state.data.id}`,{
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`Bearer ${this.props.currentUser.token}`,
          'Accept-Language':this.props.isRTL?'ar':'en'
        },
      })
      .then(response=>{
        console.log("city DELETED")
        l.then(() => message.success(allStrings.done, 2.5) )
        this.props.history.goBack() 
      })
      .catch(error=>{
          console.log(error.response)
          l.then(() => message.error(allStrings.error, 2.5))
      })
  }
  addArea = () => {
      const {areaName_en,areaName_ar} = this.state
      if(hasValue(areaName_en)&&hasValue(areaName_ar)){
          let l = message.loading('Wait..', 2.5)
          const data={
            'name_en':areaName_en,
            'name_ar':areaName_ar,
          }
          axios.post(`${BASE_END_POINT}cities/${this.props.location.state.data.id}/areas`,JSON.stringify(data),{
              headers: {
              'Content-Type': 'application/json',
              'Authorization':`Bearer ${this.props.currentUser.token}`,
              'Accept-Language':this.props.isRTL?'ar':'en'
              },
          })
          .then(response=>{
              console.log("CITY UPDATED  ",response.data)
              l.then(() => message.success(allStrings.done, 2.5) )
              this.getAreas()
              this.toggle()
          })
          .catch(error=>{
              console.log(error.response)
              l.then(() => message.error(allStrings.error, 2.5))
          })
      }
  }
  submitHandler = event => {
      event.preventDefault();
      console.log(event.target.className)
      event.target.className += " was-validated";
      this.updateCity()
  };

  changeHandler = event => {
      this.setState({ [event.target.name]: event.target.value });
  };
    
  submitHandlerAddArea = (event) => {
      event.preventDefault();
      event.target.className += " was-validated";
      this.addArea()
  };
  changeHandlerAddArea = (event) => {
      console.log(event.target.value);
      this.setState({ [event.target.name]: event.target.value });
  };

  areasTable = () =>{
      return(
      <MDBRow>
      <MDBCol md="12">
          <div className="infoTable">
              <div className="topInfoTable" style={{ background: '#605363' }}>
                  <h4>
                  <MDBIcon icon="map" /> {allStrings.areas}
                  </h4>
              </div>
              <div className="tableDate">
                  <div className="table-responsive">
                      <table className="table table-striped">
                      <thead>
                        {this.props.isRTL?
                          <tr style={{textAlign:'center'}}>
                              <th>{allStrings.more}</th>
                              <th>{allStrings.englishName}</th>
                              <th>{allStrings.arabicName}</th>
                              <th scope="col">#</th>
                          </tr>
                              :
                          <tr style={{textAlign:'center'}}>
                              <th scope="col">#</th>
                              <th>{allStrings.englishName}</th>
                              <th>{allStrings.arabicName}</th>
                              <th>{allStrings.more}</th>
                          </tr>
                        }
                            
                      </thead>
                      <tbody>
                      {this.state.loading?
                          <tr style={{height: '200px'}}>
                              <th colSpan="6" className="noData">
                              <Skeleton height={400}/> 
                              </th>
                          </tr>
                          :
                          this.state.areas.length > 0 ?
                          this.state.areas.map(area =>(
                            this.props.isRTL?
                            <tr style={{textAlign:'center'}}>
                                <td>
                                <span style={{margin: "4px",cursor:'pointer'}} onClick={()=>{this.props.history.push("areaInfo",{data:area}) }} >
                                    <Tooltip title={allStrings.info} placement="top">
                                    <Info style={{color:'#33d633'}}/>
                                    </Tooltip>
                                </span>
                                <span onClick={()=>{this.props.history.push('areaInfo',{data:area,actionType:'EDIT'}) }} style={{margin: "4px",cursor:'pointer'}}>
                                  <Tooltip title={allStrings.edit} placement="top" arrow>
                                      <Edit style={{color:'cornflowerblue',fontSize: '20px'}}/>
                                  </Tooltip>
                                </span> 
                                </td>
                                <td>{area.name_en}</td>
                                <td>{area.name_ar}</td>
                                <td>{area.id}</td>
                            </tr>
                            :
                            <tr style={{textAlign:'center'}}>
                                <td>{area.id}</td>
                                <td>{area.name_en}</td>
                                <td>{area.name_ar}</td>
                                <td>
                                <span style={{margin: "4px",cursor:'pointer'}} onClick={()=>{this.props.history.push("areaInfo",{data:area}) }} >
                                    <Tooltip title={allStrings.info} placement="top">
                                    <Info style={{color:'#33d633'}}/>
                                    </Tooltip>
                                </span>
                                <span onClick={()=>{this.props.history.push('areaInfo',{data:area,actionType:'EDIT'}) }} style={{margin: "4px",cursor:'pointer'}}>
                                  <Tooltip title={allStrings.edit} placement="top" arrow>
                                      <Edit style={{color:'cornflowerblue',fontSize: '20px'}}/>
                                  </Tooltip>
                                </span> 
                                </td>
                            </tr>
                              ))
          
                              :
                              <tr style={{height: '200px'}}>
                                  <th colSpan="6" className="noData">
                                  <Empty description={allStrings.noData} style={{margin:'0rem'}}/>
                                  </th>
                              </tr>
                          }
                      </tbody>
                      </table>
                      {this.state.areas.length > 0 &&
                          <div style={{textAlign:'center',margin:'auto',marginTop:'3rem'}}>
                              {this.paginationButtons()}
                          </div>
                      }
                  </div>
              </div>
          </div>
      </MDBCol>
  </MDBRow>
      )
  }
  paginationButtons = () =>(
    <CardActions style={{display: 'inline-flex'}}>
        <Button 
        onClick={()=>{
          if(this.state.page!==1){
            this.getAreas(1)
            this.setState({page:1})
          }
        }}
        className="pagenation" >{allStrings.first}</Button>

        <Button 
        onClick={()=>{
          if(this.state.page>1){
            this.getAreas(this.state.page-1)
            this.setState({page:this.state.page-1})
          }
        }}
        className="pagenation">{allStrings.prev}</Button>
        
        <Pagination
        onChange={(event,page)=>{
        console.log("page   ",page)
        if(page!==this.state.page){
          this.getAreas(page)
          this.setState({page:page})
        }
        
        }} 
        defaultPage={1} page={this.state.page} count={this.state.pages} style={{color:`${'#605363'} !important`}} />
        
        <Button 
        onClick={()=>{
          if(this.state.page<this.state.pages){
            this.getAreas(this.state.page+1)
            this.setState({page:this.state.page+1})
          }
        }}
        className="pagenation">{allStrings.next}</Button>

        <Button 
          onClick={()=>{
          if(this.state.page!==this.state.pages){
            this.getAreas(this.state.pages)
            this.setState({page:this.state.pages})
          }
        }}
        className="pagenation">{allStrings.last}</Button>

    </CardActions>
  )
  addNewRecordButton = () =>(
    <div style={{width:'100%'}}>
        <Fab
          onClick={this.toggle} 
          style={{background:'#605363',float:'left',margin:'50px 30px'}}
          variant="extended"
          size="medium"
          color="primary"
          aria-label="add"
        >
          <AddIcon />{allStrings.addArea}
        </Fab>
    </div>
  )
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  }
  modal = () =>(
    <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
      <MDBModalHeader toggle={this.toggle} >{allStrings.completeData}</MDBModalHeader>
        <MDBModalBody>
          <MDBContainer>
            <MDBRow>
              <MDBCol md="12">
                <form style={{paddingTop:'1rem'}}
                  className="needs-validation"
                  onSubmit={this.submitHandlerAddArea}
                  noValidate
                >
                  <div className="grey-text">
                    <MDBRow>
                      <MDBCol md="6">
                        <div className="md-form">
                          <label htmlFor="form92" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishName}</label>
                          <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text" id="form92" className="form-control" onChange={this.changeHandlerAddArea} required validate  name="areaName_en"/>
                        
                          <div class="valid-feedback">{allStrings.correct}</div>
                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                        </div>
                      </MDBCol>
                      <MDBCol md="6">
                        <div className="md-form">
                          <label htmlFor="form89" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicName}</label>
                          <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text" id="form89" className="form-control" onChange={this.changeHandlerAddArea} required validate name="areaName_ar"/>
                          <div class="valid-feedback">{allStrings.correct}</div>
                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                  
                  </div>
                  <br></br>
                  <br></br>
                  <div className="text-center">
                    <MDBBtn type="submit" style={{ background: '#605363' }} rounded className="btn-block z-depth-1a" >
                      <MDBIcon icon="plus" className="mr-2" />
                      {allStrings.add}
                    </MDBBtn>
                  </div>
                </form>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
      </MDBModalBody>
    </MDBModal>
  );

     
  render(){
    if (this.props.currentUser.data.type === "SUB-ADMIN" && !this.props.currentUser.data.permission.pages.includes('COUNTRIES')) return <Redirect to='/Home' />
    return (
      <div className="App">
        <Spin spinning={this.state.loading} tip={allStrings.loading} size="large" style={{color:'#605363'}}>
          {this.modal()}
          <MDBContainer> 
              <MDBRow>  
                  <MDBCol md="12" sm="12">
                    <div className="infoCard">
                        <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                          <CardMedia
                          style={{height:"0px"}}
                          />
                          <CardHeader></CardHeader>
                          <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}> 
                              <div className="topIcon" style={{background:'#605363'}}>
                                  <span className="material-icons" style={{color:'white',fontSize:23}}>'location_city'</span>
                              </div>
                              <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                              marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0'}}>
                              <p>{this.state.actionType==="VIEW"?allStrings.info:allStrings.edit}</p>
                              </div>
                          </div>
                          <CardContent style={{minHeight:"390px",outline:'none'}}>
                          <MDBContainer>
                              
                              <MDBRow>
                                  <MDBCol md="12">
                                  <form
                                  className="needs-validation"
                                  onSubmit={this.submitHandler}
                                  noValidate
                                  >
                                      <div className="grey-text">
                                      {this.state.actionType==="VIEW"&&
                                      <MDBRow>
                                          <MDBCol md="12">
                                              <div className="md-form">
                                              <label htmlFor="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                              <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.id} type="text" id="form8" className="form-control" onChange={this.changeHandler} required validate/>
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                          </MDBCol>
                                      </MDBRow>
                                      }{this.state.actionType==="EDIT"&&
                                      <MDBRow>
                                        <MDBCol md="12">
                                            <div className="md-form">
                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.priority}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.priority} type="number"   id="form65" name="priority" className="form-control" onChange={this.changeHandler}  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                          </MDBCol>
                                      </MDBRow>
                                      }
                                      <MDBRow>
                                          <MDBCol md="6">
                                              <div className="md-form">
                                              <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishName}</label>
                                              <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.name_en} type="text" id="form645" name="name_en" className="form-control" onChange={this.changeHandler} required  validate/>
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                              <div className="md-form">
                                              <label htmlFor="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicName}</label>
                                              <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.name_ar} type="text" id="form56" name="name_ar" className="form-control" onChange={this.changeHandler} required validate/>
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                          </MDBCol>
                                      </MDBRow>
                                      </div>
                                      <br></br>
                                      <br></br>
                                      <div className="text-center">
                                        {this.state.actionType==="EDIT"&&
                                          <MDBBtn
                                          type="submit" style={{background:'#605363'}}
                                          rounded
                                          className="btn-block z-depth-1a"
                                          >
                                              <MDBIcon icon="edit" className="mr-2" />
                                              {allStrings.updateCity}          
                                          </MDBBtn>
                                        }{this.state.actionType==="VIEW"&&
                                          <MDBBtn onClick={()=>{this.deleteCity()}} rounded color="danger" className="btn-block z-depth-1a">
                                              <MDBIcon icon="trash-alt" className="mr-2" />
                                              {allStrings.remove}
                                          </MDBBtn>
                                        }
                                        {this.state.actionType === "VIEW"&&
                                          <MDBBtn
                                          onClick={()=>{this.toggle()}} 
                                          color="primary"
                                          rounded
                                          className="btn-block z-depth-1a"
                                          >
                                              <MDBIcon icon="plus" className="mr-2" />
                                              {allStrings.addArea}        
                                          </MDBBtn>
                                        }
                                          
                                      </div>
                                  </form>
                                  </MDBCol>
                              </MDBRow>
                          </MDBContainer>
                          </CardContent>
                        </Card>
                    </div>
                </MDBCol>
              </MDBRow>
              {this.state.actionType==="VIEW"&&this.areasTable()}
        </MDBContainer>
        </Spin>
      </div> 
    )
  }
}


const mapToStateProps = (state) => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
});

const mapDispatchToProps = {
};

export default withRouter(
  connect(mapToStateProps, mapDispatchToProps)(CityInfo)
);