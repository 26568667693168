import React from 'react';
import './category info.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message ,Empty} from "antd";
import Tooltip from '@material-ui/core/Tooltip';
import { Info ,Edit} from '@material-ui/icons';
import Skeleton from 'react-loading-skeleton';
import Pagination from '@material-ui/lab/Pagination';
import { UploadOutlined } from '@ant-design/icons';
import { setUser } from "../../redux/actions/AuthActions";

class CategoryInfo extends React.Component {
  state = {
    actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
    id:this.props.location.state.data.id,
    name_en:this.props.location.state.data.name_en,
    name_ar:this.props.location.state.data.name_ar,
    priority:this.props.location.state.data.priority,
    selectedFile: null,
    img: null,
    imgName:'',
    imgsType:'url',
    categories:[],
    loading:true,
    refresh:false,
    page:1,
    pages:1,
    modal: false,
  };

  constructor(props){
    super(props)
    this.fileChangedHandler = this.fileChangedHandler.bind(this);
    this.getCategories(1,false)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
  }
  componentDidMount(){
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }
  getCategories = (page,refresh) =>{
    this.setState({loading:true})
    axios.get(`${BASE_END_POINT}categories/${this.state.id}/pagenation-subCategories?page=${page}`,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log("Categories   ",response.data)
      this.setState({
        loading:false,
        refresh:false,
        categories:response.data.data,
        page:response.data.page,
        pages:response.data.pageCount,
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })

  }
  updateCategory = () => {
    const {name_ar,name_en,img,priority} = this.state

    if(hasValue(name_ar)&&hasValue(name_en)){
        let l = message.loading('Wait..', 2.5)
        var data = new FormData()
        data.append('name_ar',name_ar)
        data.append('name_en',name_en)
        data.append('priority',priority)
        if(img){
            data.append('img',img); 
        }
        axios.put(`${BASE_END_POINT}categories/${this.props.location.state.data.id}`,data,{
        headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization':`Bearer ${this.props.currentUser.token}`
        },
        })
        .then(response=>{
        console.log("USER UPDATED  ",response.data)
        l.then(() => message.success(allStrings.done, 2.5) )
        this.props.history.goBack() 
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
    }
  }

  deleteCategory = () => {
      let l = message.loading('Wait..', 2.5)
      axios.delete(`${BASE_END_POINT}categories/${this.props.location.state.data.id}`,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${this.props.currentUser.token}`
      },
      })
      .then(response=>{
        console.log("USER DELETED")
        l.then(() => message.success(allStrings.done, 2.5) )
        this.props.history.goBack() 
      })
      .catch(error=>{
          console.log(error.response)
          l.then(() => message.error(allStrings.error, 2.5))
      })
  }
  
  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.updateCategory()
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  
  fileChangedHandler = event => {
    this.setState({
      img: event.target.files[0],
      imgName: event.target.files[0].name,
      imgType:'data'
    })
  }
  removeFile = () => {
    this.setState({
        img:null,
        imgName:'',
        imgType:"url"
    });
  }
  
  imageSection = () =>{
      
      return(
        <MDBCol  xl ="4" lg = "5"  md="12" sm="12">
        <div className="infoCard">
            <div style={{width:"100%" ,padding:'10px'}}>
              <img alt="" style={{borderRadius:'6px',width:"100%",height:'280px'}} src={this.props.location.state.data.img}></img>
            </div>
            <MDBBtn onClick={()=>{this.deleteCategory()}} rounded color="danger" className="btn-block z-depth-1a">
                <MDBIcon icon="trash-alt" className="mr-2" />
                {allStrings.remove}
            </MDBBtn>
        </div>
        
    </MDBCol>
      )
  }
  paginationButtons = () =>(
    <CardActions style={{display: 'inline-flex'}}>
        <Button 
        onClick={()=>{
          if(this.state.page!==1){
            this.getCategories(1)
            this.setState({page:1})
          }
        }}
        className="pagenation" >{allStrings.first}</Button>

        <Button 
        onClick={()=>{
          if(this.state.page>1){
            this.getCategories(this.state.page-1)
            this.setState({page:this.state.page-1})
          }
        }}
        className="pagenation">{allStrings.prev}</Button>
        
        <Pagination
        onChange={(event,page)=>{
        console.log("page   ",page)
        if(page!==this.state.page){
          this.getCategories(page)
          this.setState({page:page})
        }
        
        }} 
        defaultPage={1} page={this.state.page} count={this.state.pages} style={{color:`#605363 !important`}} />
        
        <Button 
        onClick={()=>{
          if(this.state.page<this.state.pages){
            this.getCategories(this.state.page+1)
            this.setState({page:this.state.page+1})
          }
        }}
        className="pagenation">{allStrings.next}</Button>

        <Button 
          onClick={()=>{
          if(this.state.page!==this.state.pages){
            this.getCategories(this.state.pages)
            this.setState({page:this.state.pages})
          }
        }}
        className="pagenation">{allStrings.last}</Button>

    </CardActions>
  )
  render(){
    if (this.props.currentUser.data.type === "SUB-ADMIN" && !this.props.currentUser.data.permission.pages.includes('CATEGORIES')) return <Redirect to='/Home' />

  return (
    <div className="App">
        
        <MDBContainer>
            <MDBRow>
                <MDBCol xl={this.state.actionType==="VIEW"?"8":"12"} lg = {this.state.actionType==="VIEW"?"7":"12"} md="12" sm="12">
                    <div className="infoCard">
                        <Card className="editCard" style={{ overflow: "visible", outline: "none",marginBottom: "0px"}} >
                        <CardMedia style={{ height: "0px" }} />
                        <CardHeader></CardHeader>
                        <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon" style={{background:'#605363'}}>
                                <span className="material-icons" style={{color:'white',fontSize:23}}>category</span>
                            </div>
                            <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                            marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0'}}>
                            <p>{this.state.actionType==="VIEW"?allStrings.info:allStrings.edit}</p>
                            </div>
                        </div>
                        <CardContent style={{ minHeight: "390px", outline: "none" }}>
                          <MDBContainer>
                              <MDBRow>
                                  <MDBCol md="12">
                                  <form
                                  className="needs-validation"
                                  onSubmit={this.submitHandler}
                                  noValidate
                                  >
                                      <div className="grey-text">
                                          {this.state.actionType==="EDIT"&&
                                          <MDBRow>
                                            <MDBCol md="12">
                                                <div class="md-form">
                                                    <label for="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                                    <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.id} type="text" id="form8" class="form-control" onChange={this.changeHandler} required validate/>
                                                    <div class="valid-feedback">{allStrings.correct}</div>
                                                    <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                          </MDBRow>
                                          }
                                          <MDBRow>
                                            <MDBCol md="6">
                                              <div class="md-form">
                                                <label for="form38" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicName}</label>
                                                <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.name_ar} type="text" id="form38"  name="name_ar"  class="form-control" onChange={this.changeHandler} required validate/>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                            </MDBCol>
                                            <MDBCol md="6">
                                              <div class="md-form">
                                                <label for="form2" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishName}</label>
                                                <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z0-9\s]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.name_en} type="text" id="form2" name="name_en" class="form-control" onChange={this.changeHandler} required validate/>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                            </MDBCol>
                                          </MDBRow>
                                        
                                        
                                        {this.state.actionType==="EDIT"&&
                                          <MDBRow>
                                            <MDBCol md="12">
                                                <div className="fileUpload2"> 
                                                    <input  key={this.state.img} type='file' className="upImgs2" onChange={this.fileChangedHandler} ></input>
                                                    <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                                                </div>
                                                <div className="preview">
                                                {this.state.img&&
                                                <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine'}}>
                                                    <img alt="" src={this.state.imgType==="data"?URL.createObjectURL(this.state.img):this.state.img}  className="previewImg2"/>
                                                    <span className="imgName">{this.state.imgName}</span>
                                                    <MDBIcon icon="trash" className="mr-2 removeImg2"
                                                    onClick={() => this.removeFile()}  ></MDBIcon>
                                                </div>
                                                }
                                                </div>
                                                
                                            </MDBCol>
                                          </MDBRow>
                                        }
                                      </div>
                                      <br></br>
                                      <br></br>
                                      <div className="text-center">
                                        {this.state.actionType==="EDIT"&&
                                          <MDBBtn
                                          style={{background:'#605363',color:'#fff'}}
                                          rounded
                                          type="submit"
                                          className="btn-block z-depth-1a"
                                          >
                                              <MDBIcon icon="edit" className="mr-2" />
                                              {allStrings.updateCategory}        
                                          </MDBBtn>
                                        }
                                        {this.state.actionType === "VIEW"&&
                                          <MDBBtn
                                          onClick={()=>{this.props.history.push('/addSubCategory',{data:this.state.id}) }} 
                                          color="primary"
                                          rounded
                                          className="btn-block z-depth-1a"
                                          >
                                              <MDBIcon icon="plus" className="mr-2" />
                                              {allStrings.addSubCategory}        
                                          </MDBBtn>
                                        }
                                      </div>
                                  </form>
                                  </MDBCol>
                                  
                              </MDBRow>
                          </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol>
            {this.state.actionType==="VIEW"&&this.imageSection()}    
            </MDBRow>
            {this.state.actionType==="VIEW"&&
            <MDBRow>
                <MDBCol md="12">
                    <div className="infoTable">
                        <div className="topInfoTable" style={{ background: "#605363" }}>
                            <h4>
                            <span className="material-icons" style={{color:'white',fontSize:23}}>category</span>{ allStrings.subCategory}
                            </h4>
                        </div>
                        <div className="tableDate">
                        <div className="table-responsive">
                            <table className="table table-striped">
                            <thead>
                              {this.props.isRTL?
                              <tr style={{textAlign:'center'}}>
                                  <th>{allStrings.more}</th>
                                  <th>{allStrings.arabicName}</th>
                                  <th>{allStrings.englishName}</th>
                                  <th scope="col">#</th>
                              </tr>
                              :
                              <tr style={{textAlign:'center'}}>
                                    <th scope="col">#</th>
                                    <th>{allStrings.arabicName}</th>
                                    <th>{allStrings.englishName}</th>
                                    <th>{allStrings.more}</th>
                              </tr>
                              }
                                
                            </thead>
                            <tbody>
                            {this.state.loading?
                                <tr style={{height: '200px'}}>
                                    <th colSpan={this.state.tableType === "collected" ?"5":"4"} className="noData">
                                    <Skeleton height={400}/> 
                                    </th>
                                </tr>
                                :
                                this.state.categories.length > 0 ?
                                this.state.categories.map(val =>(
                                  this.props.isRTL?
                                  <tr  style={{textAlign:'center'}}>
                                      <td>
                                      <span style={{margin: "4px",cursor:'pointer'}} onClick={()=>{this.props.history.push('/subCategoryInfo',{data:val}) }} >
                                          <Tooltip title={allStrings.info} placement="top">
                                          <Info style={{color:'#33d633'}}/>
                                          </Tooltip>
                                      </span>
                                      <span onClick={()=>{this.props.history.push('subCategoryInfo',{data:val,actionType:'EDIT'}) }} style={{margin: "4px",cursor:'pointer'}}>
                                          <Tooltip title={allStrings.edit} placement="top" arrow>
                                              <Edit style={{color:'cornflowerblue',fontSize: '20px'}}/>
                                          </Tooltip>
                                      </span> 
                                      </td>
                                      <td>{val.name_ar}</td>
                                      <td>{val.name_en}</td>
                                      
                                      <td >{val.id}</td>
                                  </tr>
                                  :
                                  <tr  style={{textAlign:'center'}}>
                                      <td >{val.id}</td>
                                      <td>{val.name_ar}</td>
                                      <td>{val.name_en}</td>
                                      <td
                                      >
                                      <span style={{margin: "4px",cursor:'pointer'}} onClick={()=>{this.props.history.push('/subCategoryInfo',{data:val}) }} >
                                          <Tooltip title={allStrings.info} placement="top">
                                          <Info style={{color:'#33d633'}}/>
                                          </Tooltip>
                                      </span>
                                      <span onClick={()=>{this.props.history.push('subCategoryInfo',{data:val,actionType:'EDIT'}) }} style={{margin: "4px",cursor:'pointer'}}>
                                          <Tooltip title={allStrings.edit} placement="top" arrow>
                                              <Edit style={{color:'cornflowerblue',fontSize: '20px'}}/>
                                          </Tooltip>
                                      </span> 
                                      </td>
                                  </tr>
                               
                                 ))
               
                                 :
                                 <tr style={{height: '200px'}}>
                                     <th colSpan="12" className="noData">
                                     <Empty description={allStrings.noData} style={{margin:'0rem'}}/>
                                     </th>
                                 </tr>
                               }
                              
                               
                                
                            </tbody>
                            </table>
                            {this.state.categories.length > 0 &&
                                <div style={{textAlign:'center',margin:'auto',marginTop:'3rem'}}>
                                    {this.paginationButtons()}
                                </div>
                            }
                        </div>
                        </div>
                    </div>
                </MDBCol>
            </MDBRow>
            }
        </MDBContainer>
      
    </div> 
  )
  }
}



const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
    setUser,
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(CategoryInfo)
  );