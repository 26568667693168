import React from "react";
import "./login.css";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
} from "mdbreact";
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import {setUser} from '../../redux/actions/AuthActions'
import {hasValue} from '../../validations/validations'
import {message} from 'antd';
import {NavLink} from 'react-router-dom';

class Login extends React.Component {
  state = {
    phone: "",
    password: "",
  };

  constructor(props){
    super(props)
    console.log("isr   ",this.props.history)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
    window.scrollTo(0,0)
  }

  login = () =>{
      const {phone,password} = this.state
      if(hasValue(phone)&&hasValue(password)){

        let l = message.loading('Wait..', 2.5)
        console.log("HAS VAL")
        const data={
            phone:phone,
            password:password
        }
        axios.post(`${BASE_END_POINT}signin`,JSON.stringify(data),{
            headers:{
                "Content-Type":"application/json",
                'Accept-Language':this.props.isRTL?'ar':'en'
            }
        })
        .then(response=>{ 
          console.log("type",response.data)
            if(response.data.data.type ==='ADMIN'){
                l.then(() => message.success('Welcome', 2.5) )
                this.props.setUser(response.data)
                localStorage.setItem('@USERCOTTAGE',JSON.stringify(response.data))
                localStorage.setItem("@LANG",'ar')
                this.props.history.replace('/Home')   
                
            }else{
               l.then(() => message.error(allStrings.thisUserIsnotAdmin, 2.5))
            }
        })
        .catch(error=>{
            console.log("ERROR   ",error.response)
            
                console.log("auth")
                l.then(() => message.error(allStrings.userDataIncorrect, 2.5))
           
        })
      }
  }

  submitHandler = (event) => {
    event.preventDefault();
    console.log(event.target);
    event.target.className += " was-validated";
    this.login()
  };

  changeHandler = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    return (
      <div className="login">
        <MDBContainer className="loginContainer">
          <MDBRow>
            <MDBCol  xl="6" lg="6" md="4" sm="12" className="noPadding">
              <div className="loginLeft">

              </div>
            </MDBCol>
            <MDBCol  xl="6" lg="6" md="8" sm="12">
              <div className="loginRight">
                <div className="logoDiv">
                  <img alt="" style={{
                      margin: 'auto',
                      display: 'block',
                      marginTop: '0rem'
                  }} width="160px" src={require('../../assets/images/logo.png')}></img>
                </div>
                <br></br>
                <div className="formContainer">
                <MDBContainer>
                      <MDBRow>
                        <MDBCol md="12">
                          <form
                            className="needs-validation"
                            onSubmit={this.submitHandler}
                            noValidate
                          >
                            
                            <div className="grey-text">
                              <MDBInput
                                name="phone"
                                hint={allStrings.phone}
                                style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}}
                                //icon="envelope"
                                group
                                type="phone"
                                onChange={this.changeHandler}
                                required
                                validate
                                error="wrong"
                                success="right"
                              />
                              <div class="valid-feedback">{allStrings.correct}</div>
                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                              <MDBInput
                                onChange={this.changeHandler}
                                name="password"
                                required
                                hint={allStrings.password}
                                style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}}
                                //icon="lock"
                                group
                                type="password"
                                validate
                              />
                              <div class="valid-feedback">{allStrings.correct}</div>
                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                            </div>
                            <NavLink to="/forgetPassword">
                              <p className="forgetP">{allStrings.forgetPassword}</p>
                            </NavLink>
                           
                            <div className="text-center">
                              <MDBBtn
                                type="submit"
                                style={{ backgroundColor: '#605363 ',width:'170px' }}
                                rounded
                                className="btn z-depth-1a"
                              >
                                {allStrings.signin}
                              </MDBBtn>
                            </div>
                          </form>
                        </MDBCol>
                      </MDBRow>
                    </MDBContainer>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

const mapToStateProps = state => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  })
  
  const mapDispatchToProps = {
    setUser,
  }
  
  export default  withRouter(
    connect(mapToStateProps,mapDispatchToProps)(Login)
  );
  
