import React from 'react';
import './user info.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon,MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message,Image,Spin,TreeSelect } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import Rater from "react-rater";
import "react-rater/lib/react-rater.css";
import Tooltip from '@material-ui/core/Tooltip';

const { TreeNode } = TreeSelect;
const { SHOW_PARENT } = TreeSelect;

class UserInfo extends React.Component {
    state = {
      actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
        id:this.props.location.state.data.id,
        user: this.props.location.state?this.props.location.state.data:[],
        firstname: this.props.location.state.data.firstname,
        lastname: this.props.location.state.data.lastname,
        email:this.props.location.state.data.email,
        type:this.props.location.state.data.type,
        phone:this.props.location.state.data.phone,
        accountType:this.props.location.state.data.accountType,
        country:this.props.location.state.data.country?this.props.location.state.data.country.id:1,
        vehicleStatus:this.props.location.state.data.vehicleStatus,
        verify:this.props.location.state.data.verify,
        blockReason:"",
        img:this.props.location.state?this.props.location.state.data.img !== ""?this.props.location.state.data.img:null:null,
        selectedFile: null,
        imagePreviewUrl:[],
        idImgs:this.props.location.state.data.idImgs?this.props.location.state.data.idImgs:[],
        idImgsPreviewUrl:this.props.location.state.data.idImgs?this.props.location.state.data.idImgs:[],
        idImgsName:[],
        idImgsType:'url',
        licenseImgs:this.props.location.state.data.licenseImgs?this.props.location.state.data.licenseImgs:[],
        licenseImgsPreviewUrl:this.props.location.state.data.licenseImgs?this.props.location.state.data.licenseImgs:[],
        licenseImgsName:[],
        licenseImgsType:'url',
        allImgs:[],
        loading:true,
        refresh:false,
        page:1,
        pages:1,
        imgType:'url',
        countries:[],
        modal:false,
        payType:"BALANCE",
        modal2:false,
    };
      
    constructor(props){
      super(props)
      window.scrollTo(0,0)
      this.getUserData()
      this.getCountries()
      this.fileChangedHandler = this.fileChangedHandler.bind(this);
      if(this.props.isRTL){
        allStrings.setLanguage('ar')
      }else{
        allStrings.setLanguage('en')
      } 
    }
    getCountries = () => {
      axios.get(`${BASE_END_POINT}countries/withoutPagenation/get`,{
          headers: {
            'Content-Type': 'application/json',
            'Authorization':`Bearer ${this.props.currentUser.token}`
          },
         })
      .then(response=>{
        console.log("countries",response.data)
        this.setState({countries:response.data.data})
      })
      .catch(error=>{
        console.log("ALL countries ERROR")
        console.log(error.response)
      })
    }
    getCities = (country) => {
        axios.get(`${BASE_END_POINT}cities/${country}/countries/withoutPagenation/get`,{
            headers: {
              'Content-Type': 'application/json',
              'Authorization':`Bearer ${this.props.currentUser.token}`
            },
          })
        .then(response=>{
          console.log(response.data)
          this.setState({cities:response.data.data})
        })
        .catch(error=>{
          console.log("ALL cities ERROR")
          console.log(error.response)
        })
    }
    getUserData = () => {
      axios.get(`${BASE_END_POINT}${this.state.id}/getUser`,{
        headers: {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
        }})
      .then(response=>{
          console.log('theUser  ',response.data)
          let allImgs = [response.data.data.img]
          allImgs.push(...response.data.data.idImgs)
          allImgs.push(...response.data.data.licenseImgs)
          console.log('allImgs  ',allImgs)

          this.setState({allImgs:allImgs})
          this.setState({
            loading:false,
            allImgs:allImgs,
            blockReason:response.data.data.blockReason?response.data.data.blockReason:"",
            phone:response.data.data.phone?response.data.data.phone:"",
            email:response.data.data.email?response.data.data.email:"",
            firstname:response.data.data.firstname?response.data.data.firstname:"",
            lastname:response.data.data.lastname?response.data.data.lastname:"",
            accountType:response.data.data.accountType?response.data.data.accountType:"",
            country:response.data.data.country?response.data.data.country.id:[],
            city:response.data.data.city?response.data.data.city.id:[],
            verify:response.data.data.verify,
            idImgs:response.data.data.idImgs?response.data.data.idImgs:[],
            licenseImgs:response.data.data.licenseImgs?response.data.data.licenseImgs:[],
            debtCost:response.data.data.debtCost?response.data.data.debtCost:0,
            balance:response.data.data.balance?response.data.data.balance:0,
            })
          
          
          })
      .catch(error=>{
          console.log('user ERROR  ',error.respose)
      })
    }
    componentDidMount(){
      if(this.props.isRTL){
        allStrings.setLanguage('ar')
      }else{
        allStrings.setLanguage('en')
      }
    }
    update = () => {
        const {imgType,img,firstname,phone,email,password,country,countryCode,lastname,licenseImgs,
          licenseImgsType,idImgs,idImgsType} = this.state
        let l = message.loading('Wait..', 2.5)
      
        if(hasValue(firstname)&&hasValue(lastname)&&hasValue(email)&&hasValue(phone) ){
            
            console.log(countryCode)
            var data = new FormData()
            data.append('country',country)
            data.append('firstname',firstname)
            data.append('type',this.state.user.type)
            data.append('email',email)
            if(password !== '' && password !== undefined){
              data.append('password',password)
            }
            data.append('phone',phone)
            if(img != null && imgType !=="url"){
                data.append('img',img)
            }
            if(idImgs != null && idImgsType){
              for(var i=0 ; i<= idImgs.length-1 ; i++){
                data.append(`idImgs`,idImgs[i] )
              } 
            }
            if(licenseImgs != null && licenseImgsType){
              for(var j=0 ; j<= licenseImgs.length-1 ; j++){
                data.append(`licenseImgs`,licenseImgs[j] )
              } 
            }
            //////
            
            console.log(Array.from(data))
            
            axios.put(`${BASE_END_POINT}user/${this.state.id}/updateInfo`,data,{
            headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization':`Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
            },
            })
            .then(response=>{
            l.then(() => message.success(allStrings.done, 2.5) )
              this.props.history.goBack()
            })
            .catch(error=>{
                console.log(error.response)
                console.log("error",error.response.data.errors.msg[0].msg)
                l.then(() => message.error(error.response.data.errors.msg[0].msg, 2.5))
            })
        }
    }
    submitHandler = event => {
      event.preventDefault();
      event.target.className += " was-validated";
      this.update()
    };
  
    changeHandler = event => {
      this.setState({ [event.target.name]: event.target.value });
    };

    
    fileChangedHandler = event => {
      this.setState({
        img: event.target.files[0],
        imgName: event.target.files[0].name,
        imgType:"data"
      })
    }
    removeFile = () => {
      this.setState({
          img:null,
          imgName:'',
          imgType:"url"
      });
    }
    fileChangedIdImgs = (event) => {
      let idImgs =[];
      let idImgsPreviewUrl = [];
      let idImgsName = []
      for(var i=0 ; i<= event.target.files.length-1 ; i++){
          idImgsPreviewUrl.push(URL.createObjectURL(event.target.files[i]))
          idImgsName.push(event.target.files[i].name)
      }
      idImgs.push(event.target.files)
      this.setState({
          idImgs: event.target.files,
          idImgsType:'data',
          idImgsPreviewUrl:idImgsPreviewUrl,
          idImgsName:idImgsName
      });
      
    };
    removeIdImgs = (url) => {
      let arr = this.state.idImgsPreviewUrl;
      let arr2 =  Array.from(this.state.idImgs);
      var index = arr.indexOf(url);
      if (index !== -1) arr.splice(index, 1);
      if (index !== -1) arr2.splice(index, 1);
      this.setState({
        idImgsPreviewUrl: arr,
        idImgs:arr2,
      });
      if(arr2.length === 0){
        this.setState({
          idImgs:null,
        });
      }
  
    };
    fileChangedLicenseImgs = (event) => {
      let licenseImgs =[];
      let licenseImgsPreviewUrl = [];
      let licenseImgsName = []
      for(var i=0 ; i<= event.target.files.length-1 ; i++){
        licenseImgsPreviewUrl.push(URL.createObjectURL(event.target.files[i]))
          licenseImgsName.push(event.target.files[i].name)
      }
      licenseImgs.push(event.target.files)
      this.setState({
        licenseImgs: event.target.files,
        licenseImgsType:'data',
        licenseImgsPreviewUrl:licenseImgsPreviewUrl,
        licenseImgsName:licenseImgsName
      });
      
    };
    removeLicenseImgs = (url) => {
      let arr = this.state.licenseImgsPreviewUrl;
      let arr2 =  Array.from(this.state.licenseImgs);
      var index = arr.indexOf(url);
      if (index !== -1) arr.splice(index, 1);
      if (index !== -1) arr2.splice(index, 1);
      this.setState({
        licenseImgsPreviewUrl: arr,
        licenseImgs:arr2,
      });
      if(arr2.length === 0){
        this.setState({
          licenseImgs:null,
        });
      }
  
    };
    imageSection = () =>(
      <MDBCol xl="4" lg="5" md="12" sm="12">
      <div className="infoCard" style={{marginTop:'80px'}}>
        <div className="StoreSlider">
          
          <div style={{height: '280px',overflow: 'hidden'}}>
            <Image.PreviewGroup >
              {this.state.allImgs.map((val,i)=>
                <Image placeholder={true} width="100%" height="100%" style={{display: i===0?"block":"none",borderRadius:'6px'}}   src={val} />
              )}
            </Image.PreviewGroup>
          </div>
          <br></br>
          <p style={{textAlign: 'center'}}>{allStrings.idImgs}</p>
          <div style={{height: '280px',overflow: 'hidden'}}>
            <Image.PreviewGroup >
              {this.state.idImgs.map((val,i)=>
                <Image placeholder={true} width="100%" height="100%" style={{display: i===0?"block":"none",borderRadius:'6px'}}   src={val} />
              )}
            </Image.PreviewGroup>
          </div>
          <br></br>
          <p style={{textAlign: 'center'}}>{allStrings.licenseImgs}</p>
          <div style={{height: '280px',overflow: 'hidden'}}>
            <Image.PreviewGroup >
              {this.state.licenseImgs.map((val,i)=>
                <Image placeholder={true} width="100%" height="100%" style={{display: i===0?"block":"none",borderRadius:'6px'}}   src={val} />
              )}
            </Image.PreviewGroup>
          </div>
        </div>
      </div>
    </MDBCol>
    
    ) 
    buttonsSection = () =>{
      return(
        <div className="btnOption">
          <MDBRow>
            <MDBCol md="6">
              <MDBBtn
                rounded
                color="info"
                style={{ borderRadius: "2px" }}
                className="btn-block z-depth-1a"
                onClick={()=>{
                  this.verify(true)
                }}
              >
                <MDBIcon icon="check-circle" className="mr-2" />
                {allStrings.verify}
              </MDBBtn>
            </MDBCol>
            <MDBCol md="6">
              <MDBBtn
                rounded
                color="warning"
                style={{ borderRadius: "2px" }}
                className="btn-block z-depth-1a"
                onClick={()=>{
                  this.verify(false)
                }}
              >
                <MDBIcon icon="ban" className="mr-2" />
                {allStrings.unVerify}
              </MDBBtn>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="6">
              <MDBBtn
                rounded
                color="warning"
                style={{ borderRadius: "2px" }}
                className="btn-block z-depth-1a"
                onClick={this.toggle} 
              >
                <MDBIcon icon="check-circle" className="mr-2" />
                {allStrings.block}
              </MDBBtn>
            </MDBCol>
            <MDBCol md="6">
              <MDBBtn
                rounded
                color="success"
                style={{ borderRadius: "2px" }}
                className="btn-block z-depth-1a"
                onClick={()=>{
                  this.block(false)
                }}
              >
                <MDBIcon icon="ban" className="mr-2" />
                {allStrings.unBlock}
              </MDBBtn>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="6">
              <MDBBtn
                rounded
                style={{ borderRadius: "2px" }}
                className="btn-block z-depth-1a"
                onClick={()=>{
                  this.toggle2();
                  this.setState({payType:'BALANCE'})
                }}
              >
                <MDBIcon icon="check-circle" className="mr-2" />
                {allStrings.payToUser}
              </MDBBtn>
            </MDBCol>
            <MDBCol md="6">
              <MDBBtn
                rounded
                style={{ borderRadius: "2px" }}
                className="btn-block z-depth-1a"
                onClick={()=>{
                  this.toggle2();
                  this.setState({payType:'DEBT'})
                }}
              >
                <MDBIcon icon="ban" className="mr-2" />
                {allStrings.payDebt}
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </div>
      )
    }
    delete = () => {
      let l = message.loading('Wait..', 2.5)
      axios.delete(`${BASE_END_POINT}${this.state.id}/delete`,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
      },
      })
      .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
        this.props.history.goBack()
      })
      .catch(error=>{
          console.log(error.response)
          l.then(() => message.error(allStrings.error, 2.5))
      })
    }
    verify = (verify) => {
      let uri ='';
      if(verify){
        uri = `${BASE_END_POINT}${this.state.id}/verify`
      }else{
        uri = `${BASE_END_POINT}${this.state.id}/unVerify`
      }
      let l = message
      .loading('Wait..', 2.5)
      axios.put(uri,{},{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.props.currentUser.token}`,
          'Accept-Language':this.props.isRTL?'ar':'en'
        },
      })
      .then(response=>{
            if(verify){
                l.then(() => message.success(allStrings.done, 2.5))
                this.setState({verify:true})
            }else{
              l.then(() => message.success(allStrings.done, 2.5))
              this.setState({verify:false})
            }
            //this.props.history.goBack()
      })
      .catch(error=>{
        console.log('Error   ',error.response)
        l.then(() => message.error(allStrings.error, 2.5))
      })
    }
    block = (block) => {
      let uri ='';
      let data = {}
      if(block){
       uri = `${BASE_END_POINT}${this.state.id}/block`
       data={blockReason:this.state.reason}
      }else{
       uri = `${BASE_END_POINT}${this.state.id}/unblock`
      }
     let l = message
      .loading('Wait..', 2.5)
      axios.put(uri,data,{
       headers: {
         'Content-Type': 'application/json',
         'Authorization': `Bearer ${this.props.currentUser.token}`,
         'Accept-Language':this.props.isRTL?'ar':'en'
       },
     })
      .then(response=>{
           if(block){
               l.then(() => message.success(allStrings.done, 2.5))
               this.setState({accountType:"BLOCKED",blockReason:this.state.reason})
               this.toggle()
           }else{
              l.then(() => message.success(allStrings.done, 2.5))
           }
           //this.props.history.goBack()
      })
      .catch(error=>{
       console.log('Error   ',error.response)
       l.then(() => message.error(allStrings.error, 2.5))
      })
    }
    
    submitHandlerBlock = (event) => {
      event.preventDefault();
      event.target.className += " was-validated";
      this.block(true)
    };
    changeHandlerBlock = (event) => {
        console.log(event.target.value);
        this.setState({ [event.target.name]: event.target.value });
    };
    toggle = () => {
      console.log(this.state.modal)
      this.setState({
        modal: !this.state.modal,
      });
    }
    modal = () =>(
      <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
        <MDBModalHeader toggle={this.toggle} >{allStrings.completeData}</MDBModalHeader>
          <MDBModalBody>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12">
                  <form style={{paddingTop:'1rem'}}
                    className="needs-validation"
                    onSubmit={this.submitHandlerBlock}
                    noValidate
                  >
                    <div className="grey-text">
                      <MDBRow>
                        <MDBCol md="12">
                          <div className="md-form">
                            <label htmlFor="form92" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.reason}</label>
                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text" id="form92" className="form-control" onChange={this.changeHandlerBlock} required validate  name="reason"/>
                            <div class="valid-feedback">{allStrings.correct}</div>
                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </div>
                    <br></br>
                    <br></br>
                    <div className="text-center">
                      <MDBBtn type="submit" style={{ background: '#605363' }} rounded className="btn-block z-depth-1a" >
                        <MDBIcon className="mr-2" />
                        {allStrings.block}
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
        </MDBModalBody>
      </MDBModal>
    );
    payToUser = () => {
      let l = message.loading('Wait..', 2.5)
      let data ={cost:this.state.cost}
      axios.put(`${BASE_END_POINT}${this.props.location.state.data.id}/payToUser`,data,{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.props.currentUser.token}`,
          'Accept-Language':this.props.isRTL?'ar':'en'
        },
      })
      .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
        this.setState({ cost:0});

        this.toggle2()
      })
      .catch(error=>{
          console.log(error.response)
          l.then(() => message.error(allStrings.err, 2.5))
      })
   
    }
    payDebt = () => {
      let l = message.loading('Wait..', 2.5)
      let data ={cost:this.state.cost}
      axios.put(`${BASE_END_POINT}${this.props.location.state.data.id}/payDebt`,data,{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.props.currentUser.token}`,
          'Accept-Language':this.props.isRTL?'ar':'en'
        },
      })
      .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
        this.setState({ cost:0});

        this.toggle2()
      })
      .catch(error=>{
          console.log(error.response)
          l.then(() => message.error(allStrings.err, 2.5))
      })
   
    }
    submitHandlerPay = (event) => {
      event.preventDefault();
      event.target.className += " was-validated";
      this.state.payType == "BALANCE"?this.payToUser():this.payDebt()
    };
    changeHandlerPay = (event) => {
        console.log(event.target.value);
        this.setState({ [event.target.name]: event.target.value });
    };
    toggle2 = () => {
      this.setState({
        modal2: !this.state.modal2,
      });
    }
    modal2 = () =>(
      <MDBModal isOpen={this.state.modal2} toggle={this.toggle2} size="lg">
        <MDBModalHeader toggle={this.toggle2} >{allStrings.completeData}</MDBModalHeader>
          <MDBModalBody>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12">
                  <form style={{paddingTop:'1rem'}}
                    className="needs-validation"
                    onSubmit={this.submitHandlerPay}
                    noValidate
                  >
                    <div className="grey-text">
                      <MDBRow>
                        <MDBCol md="12">
                          <div className="md-form">
                            <label htmlFor="form92" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.money}</label>
                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="number" min={0} id="form92" className="form-control" onChange={this.changeHandlerPay} required validate  name="cost" defaultValue={this.state.cost}/>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </div>
                    <br></br>
                    <br></br>
                    <div className="text-center">
                      <MDBBtn type="submit" style={{ background: '#233b5b' }} rounded className="btn-block z-depth-1a" >
                        <MDBIcon className="mr-2" />
                        {allStrings.pay}
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
        </MDBModalBody>
      </MDBModal>
    );
  render(){
  return (
    <div className="App">
     
      <Spin spinning={this.state.loading} tip={allStrings.loading} size="large" style={{color:'#605363'}}>
        <MDBContainer>
        {this.modal()}
        {this.modal2()}
            <MDBRow>
              <MDBCol xl={this.state.actionType==="VIEW"?"8":"12"} lg = {this.state.actionType==="VIEW"?"7":"12"} md="12" sm="12">

                <div className="infoCard">
                  <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                       
                        <CardHeader></CardHeader>
                        <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon" style={{background:'#605363'}}>
                                <span className="material-icons" style={{color:'white',fontSize:23}}>group</span>
                            </div>
                            <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                            marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0'}}>
                            <p>{this.state.actionType==="VIEW"?allStrings.userProfile:allStrings.edit}</p>
                            </div>
                        </div>
                        {this.state.type ==="OFFICE"&&this.state.actionType==="VIEW"&&
                          <div style={{width:'100%',display:'inline-block'}}>
                              <Rater style={{float:this.props.isRTL? 'left':'right',marginRight:'20px',marginLeft:'20px'}} rating={this.state.rate} total={5} interverify={false}/>
                          </div>
                        }
                        
                    <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                onSubmit={this.submitHandler}
                                noValidate
                                >
                                    <div className="grey-text">
                                    {this.state.actionType==="VIEW"&&
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.userType}</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.user.type === "VENDOR"?allStrings.vendor:this.state.user.type === "ADMIN"?allStrings.admin:this.state.user.type==="DRIVER"?allStrings.driver:this.state.type==="SUBERVISOR"?allStrings.supervisor:allStrings.client} type="text" id="form8" className="form-control" validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.id} type="text" id="form8" className="form-control"  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                       
                                    </MDBRow>
                                    }
                                    {this.state.actionType==="VIEW"&&
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.balance}</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.balance} type="text" id="form8" className="form-control" validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.debtCost}</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.debtCost} type="text" id="form8" className="form-control"  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                       
                                    </MDBRow>
                                    }
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form615" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.phone}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^0-9+]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.phone} type="text" id="form615" name="phone" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                          <div className="md-form">
                                              <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.email}</label>
                                              <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^a-zA-Z0-9$@$!%*?&#^-_. +]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.email} type="email" id="form65" name="email" className="form-control" onChange={this.changeHandler} required validate/>
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                          </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow  style={{flexFlow:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.firstName}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.firstname} type="text" id="form645" name="firstname" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.lastName}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.lastname} type="text" id="form645" name="lastname" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        
                                    </MDBRow>
                                    
                                    <MDBRow  style={{flexFlow:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="6">
                                        <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.country}</label>
                                        <TreeSelect
                                        value= {this.state.country}
                                        showSearch={true} 
                                        style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                        treeCheckable = {false}    
                                        disabled={this.state.actionType==="VIEW"?true:false}     
                                        treeNodeFilterProp="title"                      
                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        placeholder={allStrings.country}
                                        showCheckedStrategy= {SHOW_PARENT}
                                        onChange={(value) => {
                                            this.setState({country:value,cities:[]});
                                            console.log(value)
                                            this.getCities(value)
                                        }} 
                                        >
                                            {this.state.countries.map(val=>(
                                                <TreeNode value={val.id} title={this.props.isRTL?val.name_ar:val.name_en} key={val.id} />
                                            ))}
                                        </TreeSelect>
                                        </MDBCol>
                                        <MDBCol md="6">
                                        </MDBCol>
                                    </MDBRow>
                                    {this.state.accountType ==="BLOCKED"&&this.state.actionType==="VIEW"&&
                                    <MDBRow>
                                      <MDBCol md="12">
                                        <div className="md-form">
                                          <label htmlFor="form092" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.reason}</label>
                                          <input disabled value={this.state.blockReason}  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text" id="form092" className="form-control"  name="blockReason"/>
                                          <div class="valid-feedback">{allStrings.correct}</div>
                                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                        </div>
                                      </MDBCol>
                                    </MDBRow>
                                    }
                                    {this.state.actionType==="EDIT"&&
                                    <MDBRow>
                                      <MDBCol md="12">
                                          <div className="md-form">
                                          <label htmlFor="form108" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.password}</label>
                                          <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text" id="form108" className="form-control" name="password" validate/>
                                          <div class="valid-feedback">{allStrings.correct}</div>
                                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                          </div>
                                      </MDBCol>
                                    </MDBRow>
                                    }
                                    
                                    {this.state.actionType ==="VIEW"&&
                                        <div style={{textAlign: 'center',marginTop:'30px'}}>    
                                        {this.state.type ==="USER"&&                                        
                                            <Tooltip title={allStrings.orders} placement="top">
                                              <span onClick={()=>{this.props.history.push("orders",{client:this.state.id}) }}  
                                              className="material-icons" style={{color:'#117183',fontSize:50,cursor:'pointer'}}>today</span>
                                            </Tooltip>
                                        }{this.state.type ==="VENDOR"&&                                        
                                            <Tooltip title={allStrings.orders} placement="top">
                                              <span onClick={()=>{this.props.history.push("orders",{vendor:this.state.id}) }}  
                                              className="material-icons" style={{color:'#117183',fontSize:50,cursor:'pointer'}}>today</span>
                                            </Tooltip>
                                        }
                                        {this.state.type ==="DRIVER"&&                                        
                                            <Tooltip title={allStrings.orders} placement="top">
                                              <span onClick={()=>{this.props.history.push("orders",{driver:this.state.id}) }}  
                                              className="material-icons" style={{color:'#117183',fontSize:50,cursor:'pointer'}}>today</span>
                                            </Tooltip>
                                        }{this.state.type ==="VENDOR"&&
                                          <span>
                                            <Tooltip title={allStrings.products} placement="top">
                                              <span>
                                              <i class="fas fa-map" onClick={()=>{this.props.history.push("products",{owner:this.state.id}) }} style={{margin: '10px',top: '-13px',cursor:'pointer',position: 'relative',color:'#117183',fontSize:40}}></i>
                                              </span>
                                            </Tooltip>
                                          </span>
                                        }
                                        </div>
                                        }
                                    {this.state.actionType==="EDIT"&&
                                    <MDBRow>
                                        <MDBCol md="12">
                                        <label htmlFor="form8930" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.profileImg}</label>

                                            <div className="fileUpload2"> 
                                                <input  key={this.state.img} type='file' className="upImgs2" onChange={this.fileChangedHandler} ></input>
                                                <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                                            </div>
                                            <div className="preview">
                                            {this.state.img&&
                                            <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine'}}>
                                                <img alt="" src={this.state.imgType==="data"?URL.createObjectURL(this.state.img):this.state.img} className="previewImg2"/>
                                                
                                                <span className="imgName">{this.state.imgName}</span>
                                                <MDBIcon icon="trash" className="mr-2 removeImg2"
                                                onClick={() => this.removeFile()}  ></MDBIcon>
                                            </div>
                                            }
                                            </div>
                                            
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    {this.state.actionType ==="EDIT"&&
                                        <MDBRow>
                                            <MDBCol md="12">
                                            <label htmlFor="form8930" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.idImgs}</label>

                                                <div className="fileUpload2"> 
                                                    <input multiple key={this.state.idImgs} type='file' className="upImgs2" onChange={this.fileChangedIdImgs} ></input>
                                                    <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                                                </div>
                                                <div className="preview">
                                                {this.state.idImgsPreviewUrl.map((val,i) => (
                                                  <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine' ,marginBottom:'10px'}}>
                                                      <img alt="" src={this.state.idImgsType==="data"?val:val} className="previewImg2"/>
                                                      <span className="imgName">{this.state.idImgsName[i]}</span>
                                                      <MDBIcon icon="trash" className="mr-2 removeImg2"
                                                      onClick={() => this.removeIdImgs(val)}  ></MDBIcon>
                                                  </div>
                                                ))}
                                                </div>
                                                
                                            </MDBCol>
                                        </MDBRow>
                                    }
                                    {this.state.actionType ==="EDIT"&&
                                        <MDBRow>
                                            <MDBCol md="12">
                                            <label htmlFor="form8930" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.licenseImgs}</label>

                                                <div className="fileUpload2"> 
                                                    <input multiple key={this.state.licenseImgs} type='file' className="upImgs2" onChange={this.fileChangedLicenseImgs} ></input>
                                                    <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                                                </div>
                                                <div className="preview">
                                                {this.state.licenseImgsPreviewUrl.map((val,i) => (
                                                  <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine' ,marginBottom:'10px'}}>
                                                      <img alt="" src={this.state.licenseImgsType==="data"?val:val} className="previewImg2"/>
                                                      <span className="imgName">{this.state.licenseImgsName[i]}</span>
                                                      <MDBIcon icon="trash" className="mr-2 removeImg2"
                                                      onClick={() => this.removeLicenseImgs(val)}  ></MDBIcon>
                                                  </div>
                                                ))}
                                                </div>
                                                
                                            </MDBCol>
                                        </MDBRow>
                                    }
                                    

                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="text-center">
                                    {this.state.actionType==="EDIT"&&
                                      <MDBBtn
                                      type="submit" style={{background:'#605363'}}
                                      rounded
                                      className="btn-block z-depth-1a"
                                      >
                                          <MDBIcon icon="edit" className="mr-2" />
                                          {allStrings.update}        
                                      </MDBBtn>
                                    }{this.state.actionType==="VIEW"&&
                                      <MDBBtn
                                        color="red"
                                        rounded
                                        style={{ borderRadius: "2px",background:'red' }}
                                        className="btn-block z-depth-1a"
                                        onClick={()=>{
                                            this.delete()
                                        }}
                                      >
                                        <MDBIcon icon="trash-alt" className="mr-2" />
                                        {allStrings.remove}
                                      </MDBBtn>
                                    }
                                  </div>
                                </form>
                                </MDBCol>
                                
                            </MDBRow>
                        </MDBContainer>
                    </CardContent>
                  </Card>
                </div>
              </MDBCol>
            {this.state.actionType==="VIEW"&&this.imageSection()}    
            </MDBRow>
            {this.state.actionType==="VIEW"&&this.buttonsSection()}
            
            
        </MDBContainer>
      </Spin>
    </div> 
  )
  }
}



const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
});

const mapDispatchToProps = {
};

export default withRouter(
  connect(mapToStateProps, mapDispatchToProps)(UserInfo)
); 
