import React from 'react';
import Table from '../../component/table/table'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import { MDBContainer,  MDBRow, MDBCol} from "mdbreact";
import './notifications.css'
import {message} from 'antd';
import allStrings from '../../assets/strings'
import { connect } from 'react-redux';
import {NavLink, withRouter,Redirect} from 'react-router-dom'
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import { CSVLink } from "react-csv";

let headers = [
  { label: "#", key: "id" },
  { label: "title_AR", key: "title_ar" },
  { label: "title_EN", key: "title_en" },
  { label: "description_AR", key: "description_ar" },
  { label: "description_EN", key: "description_en" },
];

let csvData = [

];

class notifications extends React.Component {
  
  state={
    notifications:[],
    notifications2:[],
    csvData:[],
    loading:true,
    refresh:false,
    page:1,
    pages:1,
  }

  constructor(props){
    super(props)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }

  componentDidMount(){
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
    this.getnotifications(1,false)
    this.getnotificationssWithoutPagenation()
  }


  getnotifications = (page,refresh) =>{
    this.setState({loading:true})
    axios.get(`${BASE_END_POINT}notif?page=${page}`,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log("notifications   ",response.data)
      var data = response.data.data.map(item=>[item.id,item.title_ar,item.title_en,"action"])
      console.log("DATA   ",data)
      this.setState({
        loading:false,
        refresh:false,
        notifications:response.data.data,
        notifications2:data,
        page:response.data.page,
        pages:response.data.pageCount,
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })
  }
  getnotificationssWithoutPagenation = () =>{
    let url = `${BASE_END_POINT}notif/withoutPagenation/get`
    
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log("notificationss   ",response.data)
        csvData= response.data.data.map(item => {
          return {
            ...{id:item.id},
            ...{title_ar:item.title_ar?item.title_ar:""},
            ...{title_en:item.title_en?item.title_en:""},
            ...{description_ar:item.description_ar?item.description_ar:""},
            ...{description_en:item.description_en?item.description_en:""},
          };
        });
        console.log(csvData)
        this.setState({csvData:csvData})
      
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
    })
  }

  deletenotifications = (id) => {
    console.log("ID   ",id)
    let l = message.loading('Wait..', 2.5)
    axios.delete(`${BASE_END_POINT}notif/${id}`,{
     headers: {
       'Content-Type': 'application/json',
       'Authorization':`Bearer ${this.props.currentUser.token}`
     },
    })
    .then(response=>{
      console.log("Year DELETED")
      l.then(() => message.success(allStrings.done, 2.5) )
      this.getnotifications(this.state.page)
    })
    .catch(error=>{
        console.log(error.response)
        l.then(() => message.error(allStrings.error, 2.5))
    })
 }

  addNewRecordButton = () =>(
    <div style={{width:'100%'}}>
      <NavLink to="Addnotifications">
        <Fab
          style={{background:'#605363',float:'left',margin:'50px 30px'}}
          variant="extended"
          size="medium"
          color="primary"
          aria-label="add"
        >
          <AddIcon />{allStrings.addNewRecored}
        </Fab>
      </NavLink>
    </div>
  )

  paginationButtons = () =>(
    <CardActions style={{display: 'inline-flex'}}>
        <Button 
        onClick={()=>{
          if(this.state.page!==1){
            this.getnotifications(1)
            this.setState({page:1})
          }
        }}
        className="pagenation" >{allStrings.first}</Button>

        <Button 
        onClick={()=>{
         if(this.state.page>1){
            this.getnotifications(this.state.page-1)
            this.setState({page:this.state.page-1})
         }
        }}
        className="pagenation">{allStrings.prev}</Button>
        
        <Pagination
        onChange={(event,page)=>{
        console.log("page   ",page)
        if(page!==this.state.page){
          this.getnotifications(page)
          this.setState({page:page})
        }
        
        }} 
        defaultPage={1} page={this.state.page} count={this.state.pages} style={{color:`#605363 !important`}} />
        
        <Button 
        onClick={()=>{
          if(this.state.page<this.state.pages){
            this.getnotifications(this.state.page+1)
            this.setState({page:this.state.page+1})
          }
        }}
        className="pagenation">{allStrings.next}</Button>

        <Button 
         onClick={()=>{
          if(this.state.page!==this.state.pages){
            this.getnotifications(this.state.pages)
            this.setState({page:this.state.pages})
          }
        }}
        className="pagenation">{allStrings.last}</Button>

    </CardActions>
  )

  render(){
    if (this.props.currentUser.data.type === "SUB-ADMIN" && !this.props.currentUser.data.permission.pages.includes('FAQ')) return <Redirect to='/Home' />

  return (
    <div className="App">
        
      <MDBContainer> 
      <MDBRow>
        <MDBCol md="12">
          <div className="screenTable">
          <Table
            csv = {
              <CSVLink 
                  data={this.state.csvData} 
                  headers={headers} 
                  filename={"notificationss.csv"}
                >
                  <img alt="" src={require('../../assets/images/export-csv.png')} width="28"></img>
                </CSVLink>
            }
            title={allStrings.notifs}
            icon='circle_notifications'
            data={this.state.notifications}
            data2={this.state.notifications2}
            tableColumns={["#",allStrings.arabicTitle,allStrings.englishTitle,allStrings.action]}
            loading={this.state.loading}
            deleteUser={(id)=>{this.deletenotifications(id)}}
            path='/notificationsInfo'
            actionIndex={3}
            enableEdit={false}
            enableView={false}
            />
            {this.paginationButtons()}
            {/* {this.addNewRecordButton()} */}
            </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer >
    </div> 
  )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = {
  ChangeLanguage
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(notifications)
);

