import React from 'react';
import './productOrderInfo.css'
import { MDBContainer,  MDBRow, MDBCol} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";

class productOrderInfo extends React.Component {
        state = {
            product:this.props.location.state.data?this.props.location.state.data.product:null,
            count:this.props.location.state.data?this.props.location.state.data.count:'',
            size:this.props.location.state.data?this.props.location.state.data.size:null,
            color:this.props.location.state.data?this.props.location.state.data.color:null,
            unitCost:this.props.location.state.data?this.props.location.state.data.unitCost:null,
            vendor:this.props.location.state.data?this.props.location.state.data.vendor:null,
        };

      constructor(props){
        super(props)
        if(this.props.isRTL){
          allStrings.setLanguage('ar')
        }else{
          allStrings.setLanguage('en')
        } 
        window.scrollTo(0,0)
      }

  render(){
    if (this.props.currentUser.data.type === "SUB-ADMIN" && !this.props.currentUser.data.permission.pages.includes('PRODUCTS')) return <Redirect to='/Home' />

  return (
    <div className="App">
        <MDBContainer> 
            <MDBRow>
             
                <MDBCol md="12">
                    <div className="infoCard">
                        <Card className="editCard" style={{ overflow: "visible", outline: "none",marginBottom: "0px"}} >
                        <CardMedia style={{ height: "0px" }} />
                        <CardHeader></CardHeader>
                        <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon" style={{background:'#605363'}}>
                                <span className="material-icons" style={{color:'white',fontSize:23}}>info</span>
                            </div>
                            <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                            marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0'}}>
                            <p>{this.state.actionType==="VIEW"?allStrings.info:allStrings.info}</p>
                            </div>
                        </div>
                        <CardContent style={{ minHeight: "390px", outline: "none" }}>
                        <MDBContainer>
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                noValidate
                                >
                                    <div className="grey-text">
                                    <MDBRow>
                                        <MDBCol md="12">
                                          <div class="md-form">
                                            <label for="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.count}</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.count} type="text" id="form8" class="form-control"  required validate/>
                                          </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="6">
                                          <div class="md-form">
                                            <label for="form81" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.size}</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} 
                                            value={this.state.size?this.state.size.name:""} type="text" id="form8" class="form-control"  required validate/>
                                          </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                          <div class="md-form">
                                            <label for="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.color}</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} 
                                            value={this.state.color?this.state.color.name:""} type="text" id="form8" class="form-control"  required validate/>
                                          </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="10" xs="10">
                                            <div class="md-form">
                                            <label for="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.product}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.product?this.state.product.name:''} type="text" id="form80" class="form-control"  />
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="2" xs="2">
                                        <p style={{textAlign: 'center',marginTop: '2rem',color: '#2BBBAD'}}>
                                            <span style={{cursor:'pointer',position: 'absolute',padding: '3px'}} class="material-icons"
                                            onClick={()=>{this.props.history.push('productInfo',{data:this.state.product}) }}>
                                              remove_red_eye
                                            </span>
                                            <span style={{cursor:'pointer',fontSize: '16px',fontWeight: '500',marginLeft: '2rem'}}
                                              onClick={()=>{this.props.history.push('productInfo',{data:this.state.product}) }}>
                                              {allStrings.show}
                                            </span> 
                                          </p>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="10" xs="10">
                                            <div class="md-form">
                                            <label for="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.vendor}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.vendor?this.state.vendor.firstname + " " + this.state.vendor.lastname:''} type="text" id="form80" class="form-control"  />
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="2" xs="2">
                                        <p style={{textAlign: 'center',marginTop: '2rem',color: '#2BBBAD'}}>
                                            <span style={{cursor:'pointer',position: 'absolute',padding: '3px'}} class="material-icons"
                                            onClick={()=>{this.props.history.push('userInfo',{data:this.state.vendor}) }}>
                                              remove_red_eye
                                            </span>
                                            <span style={{cursor:'pointer',fontSize: '16px',fontWeight: '500',marginLeft: '2rem'}}
                                              onClick={()=>{this.props.history.push('userInfo',{data:this.state.vendor}) }}>
                                              {allStrings.show}
                                            </span> 
                                          </p>
                                        </MDBCol>
                                    </MDBRow>
                                    </div>
                                    <br></br>
                                    <br></br>
                                    
                                </form>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol>
            </MDBRow>
           
      </MDBContainer>
      
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(productOrderInfo)
  );