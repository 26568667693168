import LocalizedStrings from 'react-localization';

export default  new LocalizedStrings({
    en: {
        usersTable:'USERS',
        firstName:'First name',
        lastName:'Last name',
        email:'Email',
        phone:'Phone',
        block:'Block',
        action:'Action',
        yes:'yes',
        no:'no',
        areYouSureDelete:'Are you sure to delete ?',
        addNewRecored:'ADD NEW RECORD',
        first:'FIRST',
        last:'LAST',
        next:'NEXT',
        prev:'PREV',
        login:"Login",
        signin:'SIGN IN',
        password:'Password',
        userDataIncorrect:'User data in correct',
        thisUserIsnotAdmin:'This user is not admin',
        active:'Active',
        type:'Type',
        signUpFrom:'Signup from',
        address:'Address',
        updateProfile:'Update Profile',
        area:'Area',
        activeButton:'ACTIVE',
        disActiveButton:'DIS-ACTIVE',
        blockButton:'BLOCK',
        unblockButton:'UN-BLOCK',
        remove:'REMOVE',
        productsTable:'PRODUCTS',
        name:'Name',
        company:'Company',
        price:'Price',
        quantity:'Quantity',
        hasOffer:'Has offer',
        offerPrice:'Offer price',
        offerRatio:'Offer ratio',
        top:'Top',
        category:'Category',
        sallCount:'sallCount',
        userProfile:'User Info',
        addOffer:'ADD OFFER',
        removeOffer:'REMOVE OFFER',
        disActive:'Dis-Active',
        normal:'NORMAL',
        categoriesTable:'CATEGORIES',
        arabicName:'Arabic name',
        englishName:'English name',
        categoryInfo:'Category Info',
        updateCategory:'Update Category',
        cities:'Cities',
        citiesTable:'CITIES',
        deliveryCost:'Delivary cost',
        cityInfo:'City Info',
        updateCity:'Update city',
        areaInfo:'Area Info',
        updateArea:'Update area',
        username:'UserName',
        forgetPassword:'Forget Password?',
        sendCode:'Send Code',
        enter:'Enter',
        code:'Code',
        newPassword:'New Password',
        newTime:'Repeate Password',
        enterCorrectPhone:'UnCorrect Phone',
        enterCorrectEmail:'UnCorrect Email',
        correctCode:'UnCorrect Code',
        passwordMustEqualConfirmPassword:'Password Not Equal Confirm Password',
        done:'done',
        addCategory:'Add Category',
        areYouSure:"Are You Sure?",
        ok:'Ok',
        cancel:'Cancel',
        noData:'No Data',
        completeData:'Complete Require Data',
        arabicname:'Arabic Category Name',
        englishname:'English Category Name',
        img:'Img',
        chooseFile:'Choose File ',
        dropHere:'Drop File Here',
        add:'Add',
        edit:'Edit',
        addProduct:'Add Product',
        idFilter:' Filter (ID)',
        nameFilter:' Filter (Name)',
        cardFilter:'Filter (Card)',
        salesManFilter:'Filter (salesMan)',
        salesMan:'SalesMan',
        all:'All',
        print:'Print',
        mainImg:'Main Image',
        arabicProductName:'Arabic Product Name',
        englishProductName:'English Product Name',
        writeArabicName:'Write Arabic Name',
        writeEnglishName:'Write English Name',
        EnterQuantity:'Enter Quantity',
        purchasePrice:'Purchase Price',
        EnterPrice:'Enter Price',
        commission:'Commission',
        imgsGroup:'Imgs Group',
        cashPrice:'Cash Price',
        Properties:'Properties',
        installmentPackages:'Installment Packages',
        firstPaid:'First Paid',
        months:'Months',
        installment:'Installment',
        theInstallment:'Installment',
        pound:'Pound',
        available:'View On App',
        onlyInstallment:'Only Installment',
        ratio:'Ratio',
        property:'Property',
        description:'Description',
        theMonths:'Months',
        addAds:'Add Ads',
        day:'Day ',
        show:'Visable',
        notShow:'Not Visable',
        product:'Product',
        imgOrVideo:'img / video',
        duration:'Duration',
        enterName:'Enter Name',
        enterDuration:'Enter Duration',
        chooseCategory:'Choose Category ',
        enterPhone:'Enter Phone ',
        enterLink:'Enter Link (Optional)',
        link:'Link',
        chooseCompanys:'Choose Companies (Optional)',
        chooseProducts:'Choose Products (Optional)',
        optional:'Optional',
        startDate:'Start Date',
        companies:'Companies',
        categories:'Categories',
        products:'Products',
        chooseDate:'Choose Date',
        visable:'Visable',
        addClient:'Add Client',
        chooseCity:'Choose City',
        city:'City',
        note:'Note',
        anotherPhone:'Another Phone',
        EnterPhone:'Enter Phone',
        EnterAnotherPhone:'Enter Another Phone',
        enterNote:'Enter Notes',
        enterAddress:'Enter Address',
        enterCardNum:'Enter Card Num',
        cardNum:'Card Num',
        profileImg:'Profile img',
        receipts:'Reciepts Imgs',
        cardImg:'Card Img',
        client:'Client',
        guarantor:'Guarantor',
        enterPassword:'Enter Password',
        delayPremuim:'Delay Premuim',
        payCash:'Pay Cash',
        collectPremuims:'Collect Premuims',
        printReceitp:'Print Receitp',
        sendNotif:'Send Notif',
        showLocation:'Show Location',
        sendMessage:'Send Message',
        recievedProducts:'recievedProducts',
        DliveryNewProduct:'Dlivery New Product',
        buySalesMan:'Buy SalesMan',
        collectSalesMan:'Collect SalesMan',
        firstPay:'First Pay',
        premuimCost:'Premuim Cost',
        premuimsCount:'Premuims Count',
        delayPremuims:'Delay Premuims',
        payPremuims:'Paid Premuims',
        remainPremuims:'Remain Premuims',
        payCost:'Paid Cost',
        remainCost:'Remain Cost',
        cash:'Cash',
        chooseProduct:'Choose Product',
        count:'Count',
        adsViewsCount:'Ads Views Count',
        allSoldProducts:'All Sold Products',
        TotalSalesOfAllCompanies:'Total Sales Of All Companies',
        companiesInApp:'companies In App',
        allUsersCount:'All Users Count',
        newUsers:'New Users',
        topSalesMan:'Top SalesMan',
        topCompany:'Top Company',
        topProduct:'Top Sold Product',
        topProducts:'Top Sold Products',
        ads:'Ads',
        showAll:'Show All',
        dalyViews:'Daly Views',
        allViews:'All Views',
        overView:'Over View',
        statistic:'Statistic',
        viewsCount:'Views Count',
        ordersCount:'Orders Count',
        title:'Title',
        theAdd:'The Add',
        addSalesMan:'Add SalesMan',
        lastSeen:'Last seen',
        collectedClients:'Collected Clients',
        Receivables:'Receivables',
        TransferCollections:'TransferCollections',
        receiveMoney:'Receive Money',
        changeSalesMan:'changeSalesMan',
        receivedProducts:'Received Products',
        daily:'Total Daily',
        allPremiums:'All Collect',
        additionCollect:'Addition Collect',
        finishedPremiums:'Finished Premiums',
        pendingPremiums:'Pending Premiums',
        Dues:'Dues',
        recieveReceivables:'Recieve Receivables',
        money:'Money',
        date:'Date',
        to:'To',
        from:'From',
        addSupplier:'Add Supplier',
        addTransaction:'Add Transaction',
        theNaming:'Naming',
        receive:'Receive',
        give:'Give',
        cost:'Cost',
        addCost:'Add Cost',
        chooseSalesMan:'Choose SalesMan',
        chooseReceiptType:'Choose Receipt Type',
        onlyLastMonth:'Only Last Month',
        paid:'Paid',
        monthNum:'Month Num',

        storeName:'Store Name',
        subCategory:'Sub-Category',
        StoresTable:'Stores Table',
        storeAbout:'About Store',
        workTime:'Work Time',
        salesCode:'Sales Code',
        chooseSubCategory:'Choose SubCategory',
        package:'Packge',
        discount:'Discount',
        firstPackage:'First Package',
        secondPackage:'Second Package',
        chooseArea:'Choose Area',
        storeProfile:'store Info',
        updateStore:'Update Store',
        AddCategory:'Add Category',
        addSubCategory:'Add Sub Category',
        storeUsers:' Store Users ',
        priority:'Priority',
        addStoreUser:'Add Store User',
        storePhone:'Store Phone',
        storeName_ar:'Arabic Store Name',
        childUsers:'Child Users',
        addArea:'Add Area',
        addCity:'Add City',
        branches:'Branches',
        branchName:'Arabic Branch Name',
        main:'Main',
        branch:'Branch',
        chooseStoreType:'Choose Store Type',
        chooseMainStore:'Choose Main Store',
        mainStore:'Main Store',
        storeType:'Store Type',
        salesCodeType:'salesCodeType',
        self:'Self',
        branchName_en:'English Branch Name ',
        casher:"Casher",
        endPrice:'End Price',
        NoRule:'No Discount Rule',
        Stores:'Stores',
        bills:'Bills',
        home:'Home',
        clients:'Clients',
        moga:'Moga',
        rule:'Discount Rule',
        BillInfo:'Bill Info',
        canceled:'Canceled',
        addDiscountRule:'Add Discount Rule',
        rules:'Discount Rules',
        RuleInfo:'Discount Rule Info',
        updateRule:'Update Rule',
        billPassword:"bills Password",
        facebookLink:"Facebook Link",
        reNew:'Re New',
        problemType:'Problem Type',
        problem:'Problem',
        problems:'Problems',
        reports:'Reports',
        isReply:'is Reply',
        ProblemInfo:'Problem Info',
        reply:'Reply',
        SendNotif:'Send Notif',
        userType:'User Type',
        about:'About Us',
        NewsTable:'News Table',
        NewsInfo:'News Info',
        addNews:'Add News',
        news:'News',
        update:'Update',
        EventsTable:'Events Table',
        EventInfo:'Event Info',
        addEvent:'Add Event',
        Events:'Events',
        error:'error' ,
        eventDate:'Event Date',
        loadMore:'Load More',
        faculites:'Faculites',
        years:'Years',
        AddYear:'addYear',
        YearsTable:'Year Table',
        YearInfo:'Year Info',
        FaculitesTable:'Faculites Table',
        FaculityInfo:'Faculity Info',
        addFaculty:'Add Faculty',
        yearsNum:'Years Number',
        hasDivision:'Has Division',
        contactUs:'contactUs',
        faculty:'Faculty',
        year:'Year',
        department:'Department',
        division:'Divisions',
        DivisionsTable:'Divisions Table',
        AddDivision:'Add Division',
        DivisionInfo:'Division Info', 
        Divisions:'Divisions',
        DepartmentsTable:'Departments Table',
        AddDepartment:'Add Department',
        DepartmentInfo:'Department Info',
        Departments:'Departments',
        Subjects:'Subjects',
        Subject:'Subject',
        AddSubject:'Add Subject',
        SubjectInfo:'Subject Info',
        SubjectsTable:'Subjects Table',
        isAnswer:'Is Answer',
        student:'Student',
        teacher:'Teacher',
        question:'Question',
        questions:'Questions',
        AddQuestion:'Add Question',
        QuestionInfo:'Question Info',
        answer:'Answer',
        firstname:'First Name',
        admin:'Admin',
        studentsTable:'Students Table',
        students:'Students',
        TeachersTable:'Teachers Table',
        teachers:'Teacher',
        users:'Users',
        EventsAndNews:'Events & News',
        CommunityTable:'Community Table',
        AddCommunity:'Add Community',
        CommunityInfo:'Community Info',
        owner:'Owner',
        Communities:'Communities',
        lectureNumber:'Lecture Number',
        softCopy:'Soft Copy',
        hardCopy:'Hard Copy',
        LecturesTable:'Lectures Table',
        Lectures:'Lectures',
        Lecture:'Lecture',
        LectureInfo:'Lecture Info',
        AddLecture:'Add Lecture',
        files:'Files',
        videos:'Videos',
        arabicDescription:'Arabic Description',
        englishDescription:'English Description',
        arabicTitle:'Arabic Title',
        englishTitle:'English Title',
        Schedules:'Lectures Schedules',
        AddSchedule:'Add Schedule',
        Schedule:'Schedule',
        time:'Time',
        selectTime:'Select Time',
        selectDate:'Select Date',
        ScheduleInfo:'Schedule Info',
        addMoney:'Add Money',
        balance:'Balance',
        addTeacher:'Add Teacher',
        subjectsCount:'Subjects Count',
        studentsCount:'Student Count',
        teachersCount:'Teachers Count',
        questionsCount:'Questions Count',
        messagesCount:'Messages Count',
        lecturesCount:'Lectures Count',
        topLectureBuy:'Top Lecture Buy',
        topLecture:'Top Lecture',
        topStudent:'Top Student Balance',
        topActiveTeacher:'Top Active Teacher',
        topTeacher:'Top Teacher',
        SearchAbout:'Search (products - users)',
        buyCount:'Buy Count',
        openFile:'Open File',
        activeCode:'Active Code',
        removeDeviceData:'Remove Device Data',
        viewers:'Viewers',
        addViewers:'Add Viewers',
        upload:'Upload',
        Profile:'Profile',
        logout:'Logout',
        addViewer:'Add Viewer',
        country:'Country',
        aboutUs_en:'English About Us ',
        aboutUs_ar:'Arabic About Us ',
        AddTerms:'Add Terms',
        theActivity:'The Activity',
        dayCost:'Day Cost',
        winPoints:'Win Points',
        pointCost:'Point Cost',
        adminsTable:'Admins Table',
        AddUser:'Add User',
        Cities:'Cities',
        Terms:"Terms & Conditions",
        setting:'Setting',
        notifs:'Notifications',
        countryCode:'Country Code',
        numbersCount:'Numbers Count',
        isoCode:'ISO Code',
        CountriesTable:'Countries Table',
        Countries:'Countries',
        countryInfo:'Country Info',
        updateCountry:'Update Country',
        addCountry:'Add Country',
        hint:'Hint',
        activities:'Activities',
        admins:'Admins',
        workTime_en:'Work Time English',
        workTime_ar:'Work Time Arabic',
        whatsApp:'WhatsApp',
        webSite:'Web Site',
        showOn:'Show On',
        offers:'Offers',
        offerInfo:'Offer Info',
        end:'End',
        endDate:'End Date',
        logo:'Logo',
        background:'Background',
        gallery:'Gallery',
        addActivity:'Add Activity',
        latitude:'Latitude',
        longtude:"Longtude",
        topActivity:'Top Activity',
        usersCount:'Users Count',
        activityCount:'Activities Count',
        bookingCount:'Booking Count',
        offerCount:'Offer Count',
        problemCount:'Problem Count',
        topActivityRate:'Top Activity Rate',
        rate:'Rate',
        topUser:'Top user Booking',
        favActivity:'Fav Activity',
        favCount:'Fav Count',
        info:"Information",
        offer:'Offer',
        Booking:'Booking',
        bookingCode:'Booking Code',
        services:'Services',
        couponNumber:'Coupon Number',
        AddBrand:'Add Brand',
        AddSize:'Add Size',
        AddCoupon:'Add Coupon',
        theReview:'The Review',
        sizeInfo:'Size Info',
        brandInfo:'Brand Info',
        couponInfo:'Coupon Info',
        sizes:'Sizes',
        brands:'Brands',
        coupons:'Coupons',
        addColor:'Add Color',
        colorInfo:'Color Info',
        anoncementInfo:'Anoncement Info',
        addAnoncement:'Add Anoncement',
        anoncements:'Anoncements',
        Artists:'Artists',
        colors:'Colors',
        arabicQuestion:'Question in arabic',
        englishQuestion:'Question in english',
        englishAnswer:'Answer in english',
        arabicAnswer:'Answer in arabic',
        brand:'Brand',
        color:'Color',
        size:'Size',
        offerType:'Offer Type',
        Orders:'Orders',
        Search:'Search',
        online:'Online',
        finalTotal:'Final Total',
        total:'Total',
        hasPromoCode:'Has Promo Code',
        paymentSystem:'Payment System',
        PendingOrders:'Pending Orders',
        acceptedOrders:'Accepted Orders',
        deliverdOrders:'Deliverd Orders',
        refusedOrders:'Refused Orders',
        store:'Store',
        orderInfo:'Order Info',
        status:'Status',
        pending:'Pending',
        accepted:'Accepted',
        refused:'Refused',
        deliverd:'Deliverd',
        street:'Street',
        placeType:'Place Type',
        floor:'Floor',
        apartment:'Apartment',
        coupon:'Coupon',
        refuse:'Refuse',
        accept:'Accept',
        areas:'Areas',
        reason:'Reason',
        freeShipping:'FreeShipping',
        verifyRequests:'Verify Requests',
        artist:'Artist',
        artistsCount:'Artists Count',
        adminCount:'Admin Count',
        anoncementCount:'Anoncement Count',
        productsCount:'Products Count',
        pendingOrdersCount:'Pending Orders Count',
        topUserRate:'Top User Rate',
        topProductSale:'Top Product Sale',
        topProductRate:'Top Products Rate',
        topProductsRate:'Top Products Rate',
        saleCount:'Sales Count',
        discountType:'Discount Type',
        fixed:'Fixed Money',
        expireDate:'Expire Date',
        ended:'Ended',
        rejected:'Rejected',
        startTime:"StartTime",
        endTime:"EndTime",
        liveInfo:'Live Info',
        lives:'Lives',
        reject:'Reject',
        bookings:'Bookings',
        bookingInfo:'Booking Info',
        completed:'Completed',
        personsCount:'personsCount',
        service:'Service',
        shipping:'Shipping',
        free:'Free',
        notFree:'Not Free',
        notes:'Notes',
        theViewers:'The Viewers',
        durationSec:'Duration(Sec)',
        theReviewWriter:'The Review Writer',
        beFirst:'Be First',
        delivaryDate:'Delivary Date',
        refusedDate:'Refused Date',
        replyDate:'Reply Date',
        disAvailable:'Not View On App',
        ReviewReports:'Review Reports',
        cancelledOrders:' Cancelled Orders',
        permissions:'Permissions',
        addPermission:'Add Permission',
        permissionInfo:'Permission Info',
        Store:'Store',
        supervisors:'Supervisors',
        supervisor:'Supervisor',
        singleTimeUsed:'Single time used',
        InstaVerify:'Instagram Verify',
        more:'more...', 
        sizeColor:'Size & Color',
        addBalance:'Add Balance',
        cancelDate:'Cancel Date',
        artistBooking:' Artist Booking',
        clientBooking:'Client Booking',
        verify:'Verify',
        outForDelivery:'Out For Delivery',
        uploadImg:'Upload Image',
        stockCount:'Stock Count',
        clickType:'Click Type',
        number:'Number',
        notClickable:'Not Clickable',
        createdAt:'Created At',
        forceUpdate:'Force Update',
        buildingNo:'Building No',
        dailyActive:'Daily Active',
        unVerify:'Un Verify',
        unBlock:'Remove Block',
        vendorsCount:'Vendors Count',
        blog:'Blog',
        arabicAddress:'Arabic Address',
        englishAddress:'English Address',
        vendors:'Vendors',
        vendor:'Vendor',
        iosAppVersion:'Ios Version',
        androidAppVersion:'Android Version',
        tax:'Tax',
        privacy_ar:'Arabic Privacy',
        privacy_en:'English Privacy',
        terms_ar:'Arabic Terms',
        terms_en:'English Terms',
        idImgs:'Id Imgs',
        licenseImgs:'License Imgs',
        debtCost:'Debt Cost',
        driver:'Driver',
        drivers:'Drivers',
        pay:'Pay',
        payDebt:'PayDebt',
        payToUser:'Pay To User',
        assigned:'Assigned to Driver',
        offerPhase:'Waiting drivers offer  ' ,
        driverCost:'Driver cost',
        orders:'Orders',
        vendors:'Vendors'
     },

    ar: {
        vendors:'البائعين',
        orders:'الطلبات',
        driverCost:'عموله السائق',
        offerPhase:'بانتظار عروض السائقين',
        assigned:' تم تعيين سائق',
        payToUser:'دفع مستحقات',
        payDebt:'دفع مديونيه',
        pay:'ادفع',
        debtCost:'المديونيه',
        driver:'السائق',
        drivers:'السائقين',
        idImgs:'صور الهويه',
        licenseImgs:'صور السجل التجارى',
        privacy_ar:'سياسه التطبيق باللغه العربيه',
        privacy_en:'سياسه التطبيق باللغه الانجليزيه',
        terms_en:'الشروط والاحكام باللغه الانجليزيه',
        terms_ar:'الشروط والاحكام باللغه العربيه',
        tax:'الضريبه',
        iosAppVersion:'اصدار الايفون',
        androidAppVersion:'اصدار الاندرويد',
        vendor:'البائع',
        vendors:'البائعين',
        englishAddress:'العنوان بالانجليزيه',
        arabicAddress:'العنوان باللغه العربيه',
        blog:'المقالات',
        vendorsCount:'عدد البائعين',
        unVerify:'ازاله التفعيل',
        unBlock:'ازاله الحظر',
        dailyActive:'النشاط اليومى',
        buildingNo:'رقم المبنى',
        forceUpdate:'التحديث الاجبارى',
        createdAt:'تاريخ الانشاء',
        notClickable:'غير قابله للضغط',
        number:'الرقم',
        clickType:'نوع الضغط',
        stockCount:'العدد بالمخزن',
        uploadImg:'رفع صوره',
        outForDelivery:'جارى التوصيل',
        verify:'مؤكد',
        artistBooking:'حجز ارتست',
        clientBooking:'حجز عميل',
        cancelDate:'وقت الالغاء',
        addBalance:'اضافه رصيد',
        sizeColor:'لون & حجم',
        more:'...المزيد',
        InstaVerify:'تأكيد الانستجرام',
        singleTimeUsed:'الاستخدام مره واحده',
        supervisor:'مشرف',
        supervisors:'المشرفين',
        Store:'المتجر',
        permissionInfo:'بيانات الصلاحيه',
        addPermission:'اضافه صلاحيات',
        permissions:'الصلاحيات',
        cancelledOrders:'الطلبات الملغاه',
        ReviewReports:'بلاغات التعليقات',
        disAvailable:'عدم الظهور بالتطبيق',
        replyDate:'وقت الرد',
        refusedDate:'تاريخ الرفض',
        delivaryDate:'تاريخ التوصيل',
        beFirst:'العرض اولا',
        theReviewWriter:'كاتب التقييم',
        durationSec:'المده (ثانيه)',
        theViewers:'المشاهدين',
        notes:'تفاصيل',
        notFree:'غير مجانى',
        free:'مجانى',
        shipping:'الشحن',
        service:'الخدمه',
        personsCount:'عدد الافراد',
        completed:'مكتمل',
        bookingInfo:'تفاصيل الحجز',
        bookings:'الحجوزات',
        reject:'رفض',
        lives:'البث',
        liveInfo:'تفاصيل البث',
        endTime:'وقت النهايه',
        startTime:'وقت البدايه',
        rejected:'مرفوض',
        ended:'انتهى',
        discountType:' نوع الخصم',
        fixed:'مبلغ ثابت',
        expireDate:' تاريخ الانتهاء',
        saleCount:'عدد المبيعات',
        artistsCount:'عدد خبراء التجميل',
        adminCount:'عدد الادمنز',
        anoncementCount:'عدد الاخبار',
        productsCount:'عدد المنتجات',
        pendingOrdersCount:'الطلبات المنتظره',
        topUserRate:' اعلى مستخدم تقييما',
        topProductSale:'الاكثر مبيعا',
        topProductRate:'اعلى منتج تقييما',
        topProductsRate:'المنتجات الاعلى تقييم',
        artist:'خبير تجميل',
        verifyRequests:'طلبات التأكيد',
        freeShipping:'توصيل مجانى',
        reason:'السبب',
        areas:'المناطق',
        accept:'قبول',
        refuse:'رفض',
        coupon:'كود الخصم',
        apartment:'الشقه',
        floor:'الطابق',
        placeType:'نوع المكان',
        street:'الشارع',
        pending:'تحت الانتظار',
        accepted:' تم الموافقه',
        refused:'مرفوض',
        deliverd:'تم التوصيل',
        status:'الحاله',
        orderInfo:'تفاصيل الطلب',
        store:'المتجر',
        refusedOrders:'طلبات مرفوضه',
        deliverdOrders:'طلبات تم توصيلها',
        acceptedOrders:' جارى تسليمها',
        PendingOrders:'طلبات معلقه',
        hasPromoCode:'لديه كوبون',
        paymentSystem:'نوع الدفع',
        finalTotal:'التكلفه النهائيه',
        total:"التكلفه",
        online:'اون لاين',
        Search:'بــحــث',
        Orders:'الطلبات',
        offerType:'نوع العرض',
        size:'الحجم',
        color:'اللون',
        brand:'العلامه التجاريه',
        englishAnswer:'الاجابه باللغه الانجليزيه',
        arabicAnswer:'الاجابه باللغه العربيه',
        englishQuestion:'السؤال بالانجليزيه',
        arabicQuestion:'السؤال بالعربيه',
        colors:'الالوان',
        Artists:'خبراء التجميل',
        anoncements:'اعلانات',
        addAnoncement:'اضافه اعلان',
        anoncementInfo:'تفاصيل الاعلان',
        colorInfo:'بيانات اللون',
        addColor:'اضافه لون',
        sizes:'الاحجام',
        coupons:'الكوبونات',
        brands:'العلامات التجاريه',
        sizeInfo:'بيانات الحجم',
        brandInfo:'بيانات العلامه التجاريه',
        couponInfo:'بيانات الكوبون',
        theReview:'التعليق',
        AddCoupon:'اضافه كوبون',
        AddSize:'اضافه حجم',
        AddBrand:'اضافه علامه تجاريه',
        couponNumber:'رقم الكوبون',
        services:'خدمات',
        bookingCode:'كود الحجز',
        Booking:'الحجوزات',
        offer:'العرض',
        info:'البيانات',
        favCount:'عدد التفضيلات',
        favActivity:'النشاط المفضل',
        topUser:'اكثر العملاء حجزا',
        rate:'التقييم',
        topActivityRate:'اعلى الانشطه تقييما',
        problemCount:'عدد البلاغات',
        offerCount:'عدد العروض',
        bookingCount:'عدد الحجوزات',
        activityCount:'عدد النشاطات',
        usersCount:'عدد المستخدميين',
        topActivity:'افضل النشاطات',
        longtude:'خط الطول',
        latitude:'خط العرض',
        addActivity:'اضافه نشاط',
        gallery:'الصور',
        logo:'لوجو',
        background:'بالك جراوند',
        endDate:'تاريخ النهايه',
        end:'انتهى',
        addOffer:'اضافه عرض',
        offerInfo:'تفاصيل العرض',
        offers:'العروض',
        showOn:'مكان العرض',
        webSite:'الموقع الالكترونى',
        whatsApp:'واتساب',
        workTime_ar:'اوقات العمل باللغه العربيه',
        workTime_en:'اوقات العمل بالانجليزيه',
        admins:'الادمنز',
        activities:'الانشطه',
        hint:'تفاصيل',
        addCountry:'اضافه بلد',
        updateCountry:'تعديل البلد',
        countryInfo:'تفاصيل البلد',
        Countries:'البلاد',
        CountriesTable:'جدول البلاد',
        isoCode:'كود ايزو',
        countryCode:'كود الدوله',
        numbersCount:'عدد الارقام',
        notifs:'الاشعارات',
        setting:'الاعدادات',
        Terms:'الشروط والاحكام',
        Cities:'المدن',
        AddUser:'اضافه مستخدم',
        usersTable:'جدول المستخدميين',
        adminsTable:'جدول الادمنز',
        pointCost:'تكلفه النقطه',
        winPoints:'نقاط الربح',
        dayCost:'تكلفه اليوم',
        theActivity:'النشاط',
        AddTerms:'اضافه الشروط والاحكام',
        aboutUs_en:'من نحن باللغه الانجليزيه',
        aboutUs_ar:'من نحن باللغه العربيه',
        country:'البلد',
        addViewer:'اضافه مشاهد',
        logout:'الخروج',
        Profile:'الملف الشخصى',
        upload:'رفع الملف',
        viewers:'المسموح لهم بالمشاهده',
        addViewers:'اضافه مشاهدين',
        removeDeviceData:'مسح بيانات الجهاز',
        activeCode:'كود التفعيل',
        openFile:'عرض الملف',
        buyCount:'عدد مرات الشراء',
        SearchAbout:' بحث عن منتجات - مستخدمين',
        topTeacher:'انشط المعلمين',
        topActiveTeacher:'الاكثر نشاطا',
        topLectureBuy:'الاكثر مبيعا',
        topLecture:'الاكثر مبيعا',
        topStudent:'اعلى الطلاب رصيد',
        subjectsCount:'عدد المواد',
        studentsCount:'عدد الطلاب',
        teachersCount:'عدد المعلمين',
        questionsCount:'عدد الاسأله',
        lecturesCount:'عدد المحاضرات',
        messagesCount:'عدد الرسائل',
        addTeacher:'اضافه معلم',
        balance:'المستحقات',
        addMoney:'اضافه مبلغ',
        ScheduleInfo:'بيانات الجدول',
        selectDate:'اختر التاريخ',
        selectTime:'اختر الوقت',
        time:'الوقت',
        Schedule:'الجدول',
        AddSchedule:'اضافه جدول محاضره',
        Schedules:'جداول المحاضرات',
        arabicDescription:'الوصف بالعربيه',
        englishDescription:'الوصف بالانجليزيه',
        arabicTitle:'العنوان بالعربي',
        englishTitle:'العنوان بالانجليزيه',
        videos:'مقاطع الفيديو',
        files:'الملفات',
        lectureNumber:'رقم المحاضره',
        softCopy:'Pdf نسخه ',
        hardCopy:'نسخه مطبوعه',
        LecturesTable:'جدول المحاضرات',
        Lectures:'المحاضرات',
        Lecture:'المحاضره',
        LectureInfo:'بيانات المحاضره',
        AddLecture:'اضافه محاضره',
        Communities:'المنشوارات',
        CommunityTable:'جدول المنشورات',
        AddCommunity:'اضافه منشور',
        CommunityInfo:'بيانات المنشور',
        owner:'المالك',
        EventsAndNews:'اخبار واحداث',
        users:'المستخدمين',
        teachers:'المعلمين',
        TeachersTable:'جدول المعلمين',
        students:'الطلاب',
        studentsTable:'جدول الطلاب',
        admin:'أدمن',
        firstname:'الاسم الاول',
        lastname:'الاسم الاخير',
        answer:'الاجابه',
        QuestionInfo:'بيانات السؤال',
        AddQuestion:'اضافه سؤال',
        questions:'الاسأله',
        question:'السؤال',
        student:'الطالب',
        teacher:'المعلم',
        isAnswer:'تمت الاجابه؟',
        Subjects:'المواد الدراسيه',
        Subject:'الماده الدراسيه',
        AddSubject:'اضافه ماده دراسيه',
        SubjectInfo:'بيانات الماده الدراسيه',
        SubjectsTable:'جدول المواد الدراسيه',
        AddDepartment:'اضافه قسم',
        DepartmentInfo:'بيانات القسم',
        Departments:'الاقسام',
        DepartmentsTable:'جدول الاقسام',
        Divisions:'الشعب',
        faculty:'الكليه',
        year:'السنه ',
        department:'القسم',
        division:'الشعبه',
        DivisionsTable:'جدول الشعب',
        AddDivision:'اضافه شعبه',
        DivisionInfo:' بيانات الشعبه', 
        contactUs:'تواصل معنا',
        FaculitesTable:'جدول الكليات',
        FaculityInfo:'بيانات الكليه',
        addFaculty:'اضافه كليه',
        yearsNum:'عدد السنوات',
        hasDivision:'لديها شعب',
        YearInfo:'بيانات السنه',
        YearsTable:'جدول السنه',
        AddYear:'اضافه سنه',
        years:'السنوات',
        faculites:'الكليات',
        loadMore:'المزيد',
        eventDate:'تاريخ الحدث',
        error:'حدث خطأ',
        EventsTable:'جدول الاحداث',
        EventInfo:'بيانات الحدث',
        addEvent:'اضافه حدث',
        Events:'الاحداث',
        update:'تعديل',
        news:'الاخبار',
        addNews:'اضافه خبر',
        NewsInfo:'بيانات الخبر',
        NewsTable:'جدول الاخبار',
        about:'من نحن',
        userType:'نوع المستخدم',
        SendNotif:'ارسال اشعار',
        reply:'الرد',
        ProblemInfo:'بيانات المشكله',
        isReply:'تم الرد',
        problems:'المشاكل',
        reports:'البلاغات',
        problemType:' نوع المشكله',
        problem:'المشكله',
        reNew:'اعاده تعيين',
        billPassword:"باسورد الفواتير",
        facebookLink:"صفحه الفيسبوك",
        updateRule:'تعديل البند',
        RuleInfo:'بيانات بند الخصم',
        rules:'بنود الخصم',
        addDiscountRule:'اضافه بند خصم',
        canceled:'ملغاه',
        BillInfo:'بيانات الفاتوره',
        rule:'بند الخصم',
        moga:'موجا', 
        clients:'العملاء',
        home:'الصفحه الرئيسيه',
        bills:'الفواتير',
        Stores:'الاماكن',
        NoRule:'لايوجد بند خصم',
        endPrice:'السعر النهائى',
        casher:'كاشير',
        branchName_en:'اسم الفرع بالانجليزيه',
        self:'ذاتى',
        salesCodeType:"نوع كود البيع",
        chooseStoreType:'اختر نوع الفرع',
        chooseMainStore:'اختر الفرع الرئيسى',
        mainStore:'الفرع الرئيسى',
        storeType:'نوع الفرع',
        branch:'فرع',
        main:'رئيسى',
        branchName:' اسم الفرع بالعربيه',
        branches:'الفروع',
        addCity:'اضافه مدينه',
        addArea:'اضافه منطقه',
        childUsers:'المدعوون',
        storePhone:' هاتف المحل',
        storeName_ar:'اسم المحل بالعربيه',
        addStoreUser:'اضافه مستخدم للمحل',
        priority:'الاولويه',
        storeUsers:' مستخدمين المحل ',
        addSubCategory:'اضافه قسم فرعى',
        AddCategory:'اضافه قسم',
        updateStore:'تعديل المحل',
        storeProfile:'بيانات المحل',
        chooseArea:'اختر المنطقه',
        secondPackage:'الباقه الثانيه',
        firstPackage:'الباقه الاولى',
        package:'الباقه ',
        discount:'الخصم',
        chooseSubCategory:'اختر القسم الفرعى',
        salesCode:'كود البيع',
        workTime:'مواعيد العمل',
        storeAbout:'معلومات عن المحل',
        subCategory:'قسم فرعى',
        email:'الايميل',
        storeName:'اسم المحل',
        StoresTable:'جدول المحلات',

        monthNum:'ترتيب القسط',
        paid:'المدفوع',
        onlyLastMonth:'اخر شهر فقط',
        chooseSalesMan:'اختر المندوب',
        chooseReceiptType:'اختر شكل الايصال',
        addCost:'اضافه مبلغ',
        cost:'المبلغ',
        give:'تسليم',
        receive:'استلام',
        theNaming:'المسمى',
        addTransaction:'اضافه معامله ماليه',
        addSupplier:'اضافه مورد',
        from:'مـن',
        to:'الـى',
        date:'التاريخ',
        money:'المبلغ',
        recieveReceivables:'المبالغ المستلمه',
        Dues:'المستحقات',
        pendingPremiums:'التحصيلات المتبقيه',
        finishedPremiums:'التحصيلات المكتمله',
        additionCollect:'التحصيل الاضافى',
        allPremiums:'التحصيل الكلى',
        daily:'اجمالى اليوم',
        receivedProducts:'المنتجات المستلمه',
        changeSalesMan:'تغيير المندوب',
        receiveMoney:'استلام مبلغ',
        TransferCollections:'نقل التحصيلات',
        Receivables:'المستحقات',
        collectedClients:'عملاء التحصيل',
        lastSeen:'اخر تواجد',
        addSalesMan:'اضافه مندوب',
        theAdd:'الاعلان',
        title:'العنوان',
        viewsCount:'عدد المشاهدات',
        ordersCount:'عدد الطلبات',
        statistic:'احصائيات',
        overView:'نظره عامه',
        dalyViews:'المشاهدات اليوميه',
        duration:'المده(باليوم) ',
        allViews:'المشاهدات الكليه',
        showAll:'عرض الكل',
        ads:'الاعلانات',
        topProducts:'المنتجات الاكثر مبيعا',
        topSalesMan:'المندوب الاكثر مبيعات',
        topCompany:' الشركه الاكثر مبيعا',
        topProduct:'المنتج الاكثر مبيعا',
        newUsers:'المستخدمين الجدد',
        allUsersCount:'عدد المستخدمين الكلى',
        companiesInApp:'عدد الشركات فالتطبيق',
        TotalSalesOfAllCompanies:'اجمالى المبيعات لجميع الشركات',
        allSoldProducts:'عدد المنتجات المباعه',
        adsViewsCount:'اجمالى مشاهدات الاعلانات',
        product:'المنتج',
        chooseProduct:'اختر المنتج',
        count:'الكميه',
        cash:'كاش',
        payPremuims:'الاقساط المدفوعه',
        premuimCost:'قيمه القسط',
        premuimsCount:'عددد الاقساط',
        delayPremuims:'الاقساط المرحله',
        remainPremuims:'الاقساط المتبقيه',
        payCost:'المبلغ المدفوع',
        firstPay:'المقدم',
        remainCost:'المبلغ المتبقى',
        buySalesMan:'مندوب البيع',
        collectSalesMan:'مندوب التحصيل',
        DliveryNewProduct:'تسليم سلعه جديده',
        recievedProducts:'المنتجات المستلمه',
        showLocation:'اظهار الموقع',
        sendMessage:'رساله بالتطبيق',
        printReceitp:'طباعه ايصال',
        sendNotif:'ارسال اشعار',
        delayPremuim:'ترحيل قسط',
        payCash:'دفع كاش',
        collectPremuims:'تحصيل اقساط',
        enterPassword:'ادخل الرقم السرى',
        guarantor:'الضامن',
        client:'العميل',
        receipts:'ايصالات الامانه',
        cardImg:'صوره البطاقه',
        chooseCity:'اختر المحافظه',
        city:'المحافظه',
        note:'الملاحظات',
        anotherPhone:'رقم موبايل اخر',
        EnterPhone:'ادخل رقم الموبايل',
        EnterAnotherPhone:'ادخل رقم موبايل اخر',
        enterNote:'ادخل الملاحظات',
        enterAddress:'ادخل العنوان',
        enterCardNum:'ادخل رقم البطاقه',
        cardNum:'رقم البطاقه',
        profileImg:'صوره البروفايل',
        addClient:'اضافه عميل',
        enterPhone:'ادخل رقم الهاتف (اختيارى)  ',
        enterLink:' ادخل الرابط (اختيارى) ',
        link:'الرابط',
        visable:'عرض',
        chooseDate:'اختر التاريخ',
        products:'المنتجات',
        categories:'الاقسام',
        enterName:'ادخل الاسم',
        enterDuration:'ادخل المده',
        chooseCategory:' اختر القسم',
        chooseCompanys:'(اختيارى) اختر الشركات',
        chooseProducts:'(اختيارى) اختر المنتجات',
        optional:'اختيارى',
        startDate:'تاريخ البدايه',
        companies:'الشركات',
        imgOrVideo:'صوره/فيديو',
        show:'معروض',
        notShow:'غير معروض',
        day:'يوم ',
        addAds:'اضافه اعلان',
        theMonths:'الشهور',
        Properties:'خصائص المنتج',
        installmentPackages:'باقات التقسيط',
        firstPaid:'المقدم',
        months:'الشهور',
        installment:'قسط',
        theInstallment:'القسط',
        pound:'جنيه',
        available:'يظهر بالتطبيق',
        onlyInstallment:'تقسيط فقط',
        ratio:'نسبه',
        property:'ميزه',
        description:'وصف',
        properties:'',
        cashPrice:'سعر الكاش',
        imgsGroup:'مجموعه صور',
        writeArabicName:'ادخل الاسم بالعربيه',
        writeEnglishName:'ادخل الاسم بالانجليزيه',
        quantity:'الكميه',
        EnterQuantity:'ادخل الكميه',
        purchasePrice:'سعر الشراء',
        EnterPrice:'ادخل المبلغ',
        commission:'مكافئه البيع',
        mainImg:'الصوره الرئيسيه',
        arabicProductName:'اسم المنتج بالعربيه',
        englishProductName:'اسم المنتج بالانجليزيه', 
        print:'طباعه',
        all:'الكل',
        idFilter:' ( فلتر ( الكود ',
        nameFilter:'( فلتر ( الاسم',
        cardFilter:'( فلتر ( البطاقه',
        salesMan:'المندوب',
        salesManFilter:'فلتر (المندوب )',
        addProduct:'اضافه منتج',
        edit:'تعديل',
        add:'اضافه',
        dropHere:'قم بالسحب واسقط هنا',
        arabicname:'اسم القسم باللغه العربيه',
        englishname:'اسم القسم باللغه الانجليزيه',
        img:'الصوره ',
        chooseFile:'اختر الملف',
        completeData:'املأ البيانات المطلوبه',
        noData:'لا يوجد بيانات',
        areYouSure:"هل انت متأكد؟",
        ok:'موافق',
        cancel:'غير موافق',
        addCategory:'اضافه قسم',
        enterCorrectPhone:'رقم هاتف غير صحيح',
        enterCorrectEmail:'بريد الكترونى غير صحيح',
        correctCode:'رمز خاطئ',
        passwordMustEqualConfirmPassword:'يجب ان يكون الرقم السرى يشابه الرقم المؤكد',
        done:'تم',
        newPassword:'الباسورد الجديد',
        newTime:'مره اخرى',
        code:'الرمز',
        enter:'ادخال',
        sendCode:'ارسال الرمز',
        forgetPassword:'نسيت كلمه السر؟',
        username:"اسم المستخدم",
        areaInfo:'معلومات المنطقة',
        updateArea:'تعديل المنطقة',
        updateCity:'تعديل المدينة',
        cityInfo:'معلومات المدينة',
        citiesTable:'المدن',
        deliveryCost:'سعر التوصيل',
        cities:'المدن',
        categoryInfo:'معلومات القسم',
        updateCategory:'تعديل القسم',
        categoriesTable:'الاقسام',
        arabicName:'الاسم بالعربية',
        englishName:'الاسم بالانجليزيه',
        normal:'طبيعى',
        disActive:'ايقاف التنشيط',
        removeOffer:'حذف العرض',
        userProfile:'بيانات المستخدم',
        sallCount:'الخصم',
        offerPrice:'السعر بعد الخصم',
        offerRatio:'نسبة الخصم',
        top:'توب',
        category:'القسم',
        name:'الاسم',
        company:'الشركة',
        price:'السعر',
        hasOffer:'لدية عرض ',
        productsTable:'المنتجات',
        remove:'حذف',
        activeButton:'تنشيط',
        disActiveButton:'ايقاف التنشيط',
        blockButton:'الحظر',
        unblockButton:'فك الحظر',
        area:'المنطقة',
        updateProfile:'تعديل الملف الشخصى',
        address:'العنوان',
        signUpFrom:'التسجيل من خلال',
        active:'نشط',
        type:'النوع',
        thisUserIsnotAdmin:'هذا المستخدم ليس مسئولا',
        userDataIncorrect:'بيانات المستخدم غير صحيحة',
        firstName:'الاسم الاول',
        lastName:'باقى الاسم',
        phone:'رقم الموبايل',
        block:'الحظر',
        action:'الاجرائات',
        yes:'نعم',
        no:'لا',
        areYouSureDelete:'هل انت متاكد من الحذف ؟',
        addNewRecored:'اضافة عنصر جديد',
        first:'البداية',
        last:'الاخير',
        next:'التالى',
        prev:'السابق',
        login:"الدخول",
        signin:'تسجيل الدخول',
        password:'كلمة المرور',
    },
       
});
