import React from 'react';
import './country info.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon,MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Tooltip from '@material-ui/core/Tooltip';
import { Info ,Edit} from '@material-ui/icons';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message,Spin,Empty } from "antd";
import Pagination from '@material-ui/lab/Pagination';
import Skeleton from 'react-loading-skeleton';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import { UploadOutlined } from '@ant-design/icons';

class CountryInfo extends React.Component {
  state = {
    actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
    id:this.props.location.state.data.id,
    name_en:this.props.location.state.data.name_en,
    name_ar:this.props.location.state.data.name_ar,
    countryCode:this.props.location.state.data.countryCode,
    isoCode:this.props.location.state.data.isoCode,
    numbersCount:this.props.location.state.data.numbersCount,
    hint:this.props.location.state.data.hint,
    cities:[],
    page:1,
    pages:1,
    cityName_en:'',
    cityName_ar:'',
    deliveryCost:0,
    modal:false,
    img:null,
    selectedFile: null,
    imagePreviewUrl:[],
    imgName:'',
  };

  constructor(props){
    
      super(props)
      console.log(this.props.location.state)
      if(this.props.isRTL){
          allStrings.setLanguage('ar')
      }else{
          allStrings.setLanguage('en')
      } 
  }

  componentDidMount(){
    
      this.getcities()
      if(this.props.isRTL){
          allStrings.setLanguage('ar')
      }else{
          allStrings.setLanguage('en')
      } 
  }

  getcities = (page) => {
      axios.get(`${BASE_END_POINT}cities/${this.props.location.state.data.id}/countries?page=${page}`)
      .then(response=>{
          console.log('cities  ',response.data.data)
          this.setState({
            loading:false,
            cities:response.data.data,
            page:response.data.page,
            pages:response.data.pageCount,
          })
      })
      .catch(error=>{
          this.setState({loading:false})
          console.log('cities ERROR  ',error)
          console.log('cities ERROR  ',error.respose)
      })
  }

  updateCountry = () => {
      const {name_en,name_ar,img,isoCode,countryCode,numbersCount,hint} = this.state
      if(hasValue(name_en)&&hasValue(name_ar)
      &&hasValue(isoCode)&&hasValue(countryCode)){
          let l = message.loading('Wait..', 2.5)
          var data = new FormData()
          data.append('name_ar',name_ar)
          data.append('name_en',name_en)
          data.append('isoCode',isoCode)
          data.append('countryCode',countryCode)
          data.append('numbersCount',numbersCount)
          data.append('hint',hint)
          if(img){
              data.append('img',img); 
          }
          console.log(Array.from(data))
          axios.put(`${BASE_END_POINT}countries/${this.props.location.state.data.id}`,data,{
          headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization':`Bearer ${this.props.currentUser.token}`
          },
          })
          .then(response=>{
          l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.goBack() 
          })
          .catch(error=>{
              console.log(error.response)
              l.then(() => message.error(allStrings.error, 2.5))
          })
      }
  }
  deleteCountry = () => {
      let l = message.loading('Wait..', 2.5)
      axios.delete(`${BASE_END_POINT}countries/${this.props.location.state.data.id}`,{
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`Bearer ${this.props.currentUser.token}`
        },
      })
      .then(response=>{
        console.log("country DELETED")
        l.then(() => message.success(allStrings.done, 2.5) )
        this.props.history.goBack() 
      })
      .catch(error=>{
          console.log(error.response)
          l.then(() => message.error(allStrings.error, 2.5))
      })
  }
  addCity = () => {
      const {cityName_en,cityName_ar,deliveryCost} = this.state
      if(hasValue(cityName_en)&&hasValue(cityName_ar)){
          let l = message.loading('Wait..', 2.5)
          const data={
              'name_en':cityName_en,
              'name_ar':cityName_ar,
              'deliveryCost':deliveryCost,
              'country':this.props.location.state.data.id
          }
          console.log(data)
          axios.post(`${BASE_END_POINT}cities`,JSON.stringify(data),{
              headers: {
              'Content-Type': 'application/json',
              'Authorization':`Bearer ${this.props.currentUser.token}`
              },
          })
          .then(response=>{
              console.log("CITY Added  ",response.data)
              l.then(() => message.success(allStrings.done, 2.5) )
              this.toggle()
              this.getcities()
              //this.props.history.goBack() 
          })
          .catch(error=>{
              console.log(error.response)
              l.then(() => message.error(allStrings.error, 2.5))
          })
      }
  }

  submitHandler = event => {
      event.preventDefault();
      console.log(event.target.className)
      event.target.className += " was-validated";
      this.updateCountry()
  };

  changeHandler = event => {
      this.setState({ [event.target.name]: event.target.value });
  };
    
  submitHandlerAddCity = (event) => {
      event.preventDefault();
      event.target.className += " was-validated";
      this.addCity()
  };
  changeHandlerAddCity = (event) => {
      console.log(event.target.value);
      this.setState({ [event.target.name]: event.target.value });
  };

    
  fileChangedHandler = event => {
    this.setState({
      img: event.target.files[0],
      imgName: event.target.files[0].name,
      imgType:'data'
    })
  }
  removeFile = () => {
    this.setState({
        img:null,
        imgName:'',
        imgType:"url"
    });
  }

  imageSection = () =>{
      
      return(
        <MDBCol  xl ="4" lg = "5"  md="12" sm="12">
        <div className="infoCard">
            <div style={{width:"100%" ,padding:'10px'}}>
              <img alt="" style={{borderRadius:'6px',width:"100%",height:'280px'}} src={this.props.location.state.data.img}></img>
            </div>
        </div>
        
    </MDBCol>
      )
  }
  citiesTable = () =>{
    return(
      <MDBRow>
        <MDBCol md="12">
            <div className="infoTable">
                <div className="topInfoTable" style={{ background: '#605363' }}>
                    <h4>
                    <MDBIcon icon="map" /> {allStrings.cities}
                    </h4>
                </div>
                <div className="tableDate">
                    <div className="table-responsive">
                        <table className="table table-striped">
                        <thead>
                          {this.props.isRTL?
                            <tr style={{textAlign:'center'}}>
                                <th>{allStrings.more}</th>
                                <th>{allStrings.englishName}</th>
                                <th>{allStrings.arabicName}</th>
                                <th scope="col">#</th>
                            </tr>
                          :
                            <tr style={{textAlign:'center'}}>
                                <th scope="col">#</th>
                                <th>{allStrings.englishName}</th>
                                <th>{allStrings.arabicName}</th>
                                <th>{allStrings.more}</th>
                            </tr>
                          }
                            
                        </thead>
                        <tbody>
                        {this.state.loading?
                            <tr style={{height: '200px'}}>
                                <th colSpan="6" className="noData">
                                <Skeleton height={400}/> 
                                </th>
                            </tr>
                            :
                            this.state.cities.length > 0 ?
                            this.state.cities.map(city =>(
                              this.props.isRTL?
                              <tr style={{textAlign:'center'}}>
                                  <td>
                                    <span onClick={()=>{this.props.history.push("cityInfo",{data:city}) }}  style={{margin: "4px",cursor:'pointer'}}>
                                        <Tooltip title={allStrings.info} placement="top">
                                        <Info style={{color:'#33d633'}}/>
                                        </Tooltip>
                                    </span>
                                    <span onClick={()=>{this.props.history.push('cityInfo',{data:city,actionType:'EDIT'}) }} style={{margin: "4px",cursor:'pointer'}}>
                                      <Tooltip title={allStrings.edit} placement="top" arrow>
                                          <Edit style={{color:'cornflowerblue',fontSize: '20px'}}/>
                                      </Tooltip>
                                    </span> 
                                  </td>
                                  <td>{city.name_en}</td>
                                  <td>{city.name_ar}</td>
                                  <td>{city.id}</td>
                              </tr>
                              :
                              <tr style={{textAlign:'center'}}>
                                  <td>{city.id}</td>
                                  <td>{city.name_en}</td>
                                  <td>{city.name_ar}</td>
                                  <td>
                                  <span style={{margin: "4px",cursor:'pointer'}} onClick={()=>{this.props.history.push("cityInfo",{data:city}) }} >
                                      <Tooltip title={allStrings.info} placement="top">
                                      <Info style={{color:'#33d633'}}/>
                                      </Tooltip>
                                  </span>
                                  <span onClick={()=>{this.props.history.push('cityInfo',{data:city,actionType:'EDIT'}) }} style={{margin: "4px",cursor:'pointer'}}>
                                      <Tooltip title={allStrings.edit} placement="top" arrow>
                                          <Edit style={{color:'cornflowerblue',fontSize: '20px'}}/>
                                      </Tooltip>
                                  </span>  
                                  </td>
                              </tr>
                            ))
                            :
                                <tr style={{height: '200px'}}>
                                    <th colSpan="6" className="noData">
                                    <Empty description={allStrings.noData} style={{margin:'0rem'}}/>
                                    </th>
                                </tr>
                            }
                        </tbody>
                        </table>
                        {this.state.cities.length > 0 &&
                            <div style={{textAlign:'center',margin:'auto',marginTop:'3rem'}}>
                                {this.paginationButtons()}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </MDBCol>
      </MDBRow>
    )
  }
  paginationButtons = () =>(
    <CardActions style={{display: 'inline-flex'}}>
        <Button 
        onClick={()=>{
          if(this.state.page!==1){
            this.getcities(1)
            this.setState({page:1})
          }
        }}
        className="pagenation" >{allStrings.first}</Button>

        <Button 
        onClick={()=>{
          if(this.state.page>1){
            this.getcities(this.state.page-1)
            this.setState({page:this.state.page-1})
          }
        }}
        className="pagenation">{allStrings.prev}</Button>
        
        <Pagination
        onChange={(event,page)=>{
        console.log("page   ",page)
        if(page!==this.state.page){
          this.getcities(page)
          this.setState({page:page})
        }
        
        }} 
        defaultPage={1} page={this.state.page} count={this.state.pages} style={{color:`${'#605363'} !important`}} />
        
        <Button 
        onClick={()=>{
          if(this.state.page<this.state.pages){
            this.getcities(this.state.page+1)
            this.setState({page:this.state.page+1})
          }
        }}
        className="pagenation">{allStrings.next}</Button>

        <Button 
          onClick={()=>{
          if(this.state.page!==this.state.pages){
            this.getcities(this.state.pages)
            this.setState({page:this.state.pages})
          }
        }}
        className="pagenation">{allStrings.last}</Button>

    </CardActions>
  )
  addNewRecordButton = () =>(
    <div style={{width:'100%'}}>
        <Fab
          onClick={this.toggle} 
          style={{background:'#605363',float:'left',margin:'50px 30px'}}
          variant="extended"
          size="medium"
          color="primary"
          aria-label="add"
        >
          <AddIcon />{allStrings.addCity}
        </Fab>
    </div>
  )
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  }
  modal = () =>(
    <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
      <MDBModalHeader toggle={this.toggle} >{allStrings.completeData}</MDBModalHeader>
        <MDBModalBody>
          <MDBContainer>
            <MDBRow>
              <MDBCol md="12">
                <form style={{paddingTop:'1rem'}}
                  className="needs-validation"
                  onSubmit={this.submitHandlerAddCity}
                  noValidate
                >
                  <div className="grey-text">
                    <MDBRow>
                      <MDBCol md="6">
                        <div className="md-form">
                          <label htmlFor="form92" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishName}</label>
                          <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text" id="form92" className="form-control" onChange={this.changeHandlerAddCity} required validate  name="cityName_en"/>
                          <div class="valid-feedback">{allStrings.correct}</div>
                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                        </div>
                      </MDBCol>
                      <MDBCol md="6">
                        <div className="md-form">
                          <label htmlFor="form89" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicName}</label>
                          <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text" id="form89" className="form-control" onChange={this.changeHandlerAddCity} required validate name="cityName_ar"/>
                          <div class="valid-feedback">{allStrings.correct}</div>
                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </div>
                  <br></br>
                  <br></br>
                  <div className="text-center">
                    <MDBBtn type="submit" style={{ background: '#605363' }} rounded className="btn-block z-depth-1a" >
                      <MDBIcon icon="plus" className="mr-2" />
                      {allStrings.add}
                    </MDBBtn>
                  </div>
                </form>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
      </MDBModalBody>
    </MDBModal>
  );
  render(){
    if (this.props.currentUser.data.type === "SUB-ADMIN" && !this.props.currentUser.data.permission.pages.includes('COUNTRIES')) return <Redirect to='/Home' />

  return (
    <div className="App">
      <Spin spinning={this.state.loading} tip={allStrings.loading} size="large" style={{color:'#605363'}}>
        {this.modal()}
        <MDBContainer> 
              <MDBRow>  
                <MDBCol xl={this.state.actionType==="VIEW"?"8":"12"} lg = {this.state.actionType==="VIEW"?"7":"12"} md="12" sm="12">
                  <div className="infoCard">
                    <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                        <CardMedia
                        style={{height:"0px"}}
                        />
                        <CardHeader></CardHeader>
                        <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon" style={{background:'#605363'}}>
                                <span className="material-icons" style={{color:'white',fontSize:23}}>flag</span>
                            </div>
                            <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                            marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0'}}>
                            <p>{this.state.actionType==="VIEW"?allStrings.countryInfo:allStrings.edit}</p>
                            </div>
                        </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                          <MDBContainer>
                              
                              <MDBRow>
                                  <MDBCol md="12">
                                  <form
                                  className="needs-validation"
                                  onSubmit={this.submitHandler}
                                  noValidate
                                  >
                                      <div className="grey-text">
                                      {this.state.actionType==="VIEW"&&
                                      <MDBRow>
                                          <MDBCol md="12">
                                              <div className="md-form">
                                              <label htmlFor="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                              <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.id} type="text" id="form8" className="form-control" onChange={this.changeHandler} required validate/>
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                          </MDBCol>
                                      </MDBRow>
                                      }
                                      <MDBRow>
                                          <MDBCol md="6">
                                              <div className="md-form">
                                              <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishName}</label>
                                              <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.name_en} type="text" id="form645" name="name_en" className="form-control" onChange={this.changeHandler} required  validate/>
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                              <div className="md-form">
                                              <label htmlFor="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicName}</label>
                                              <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.name_ar} type="text" id="form56" name="name_ar" className="form-control" onChange={this.changeHandler} required validate/>
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                          </MDBCol>
                                      </MDBRow>
                                      <MDBRow>
                                          <MDBCol md="6">
                                              <div className="md-form">
                                              <label htmlFor="form6" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.countryCode}</label>
                                              <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.countryCode} type="text" id="form6" name="countryCode" className="form-control" onChange={this.changeHandler} required  validate/>
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                              <div className="md-form">
                                              <label htmlFor="form5" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.isoCode}</label>
                                              <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.isoCode} type="text" id="form5" name="isoCode" className="form-control" onChange={this.changeHandler} required validate/>
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                          </MDBCol>
                                      </MDBRow>
                                      <MDBRow>
                                          <MDBCol md="6">
                                              <div className="md-form">
                                              <label htmlFor="form0" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.numbersCount}</label>
                                              <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.numbersCount} type="text" id="form0" name="numbersCount" className="form-control" onChange={this.changeHandler} required  validate/>
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                              <div className="md-form">
                                              <label htmlFor="form00" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.hint}</label>
                                              <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.hint} type="text" id="form00" name="hint" className="form-control" onChange={this.changeHandler} required validate/>
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                          </MDBCol>
                                      </MDBRow>
                                      {this.state.actionType==="EDIT"&&
                                      <MDBRow>
                                          <MDBCol md="12">
                                              <div className="fileUpload2"> 
                                                  <input  key={this.state.img} type='file' className="upImgs2" onChange={this.fileChangedHandler} ></input>
                                                  <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                                              </div>
                                              <div className="preview">
                                              {this.state.img&&
                                              <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine'}}>
                                                  <img alt="" src={this.state.imgType==="data"?URL.createObjectURL(this.state.img):this.state.img}  className="previewImg2"/>
                                                  <span className="imgName">{this.state.imgName}</span>
                                                  <MDBIcon icon="trash" className="mr-2 removeImg2"
                                                  onClick={() => this.removeFile()}  ></MDBIcon>
                                              </div>
                                              }
                                              </div>
                                              
                                          </MDBCol>
                                      </MDBRow>
                                      }
                                      </div>
                                      <br></br>
                                      <br></br>
                                      <div className="text-center">
                                      {this.state.actionType==="EDIT"&&
                                          <MDBBtn
                                          type="submit" style={{background:'#605363'}}
                                          rounded
                                          className="btn-block z-depth-1a"
                                          >
                                              <MDBIcon icon="edit" className="mr-2" />
                                              {allStrings.updateCountry}          
                                          </MDBBtn>
                                      }{this.state.actionType==="VIEW"&&
                                          <MDBBtn
                                            onClick={()=>{this.toggle()}} 
                                            color="primary"
                                            rounded
                                            className="btn-block z-depth-1a"
                                          >
                                              <MDBIcon icon="plus" className="mr-2" />
                                              {allStrings.addCity}        
                                          </MDBBtn>
                                      }{this.state.actionType==="VIEW"&&
                                          <MDBBtn onClick={()=>{this.deleteCountry()}} rounded color="danger" className="btn-block z-depth-1a">
                                              <MDBIcon icon="trash-alt" className="mr-2" />
                                              {allStrings.remove}
                                          </MDBBtn>
                                      }
                                          
                                          
                                      </div>
                                  </form>
                                  </MDBCol>
                              </MDBRow>
                          </MDBContainer>
                        </CardContent>
                    </Card>
                  </div>
                </MDBCol>
                {this.state.actionType==="VIEW"&&this.imageSection()} 
              </MDBRow>
              {this.state.actionType==="VIEW"&&this.citiesTable()}
        </MDBContainer>
      </Spin>
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(CountryInfo)
  );