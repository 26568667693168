
import React, { useState, useEffect } from 'react';
import './menu.css'
import {
  HomeOutlined,
  TagsOutlined,
  TeamOutlined,
  DesktopOutlined,
  AppleOutlined,
  FlagOutlined,
  UserOutlined,
  NotificationOutlined ,
  ApartmentOutlined,
  InfoCircleOutlined,
  MessageOutlined,
  ControlOutlined,
  ShopOutlined,
  FontSizeOutlined,
  HighlightOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  ReadOutlined,
  SafetyOutlined
} from '@ant-design/icons';
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import {setUser} from '../../redux/actions/AuthActions'
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import {Collapsed} from '../../redux/actions/MenuAction'

const { Sider } = Layout;
const { SubMenu } = Menu;

export const SiderMenu = ({isRTL,currentUser,setUser,ChangeLanguage,Collapsed,menu}) => {
  const [collapsed, setCollapsed] = useState(menu);
  const location = useLocation();
console.log(collapsed)
  // Similar to componentDidMount and componentDidUpdate:
  if(isRTL){
    allStrings.setLanguage('ar')
  }else{
    allStrings.setLanguage('en')
  } 

  useEffect(() => {
    if(isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
  });
console.log(isRTL)
const onCollapse = (collapsed) =>{ setCollapsed(collapsed);Collapsed(collapsed);localStorage.setItem("@MENU",collapsed)};
  return (
    <Sider 
    style={{
      overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left:0
        //left: isRTL?'auto':0,
        //right: isRTL?0:'auto',
        //direction:isRTL?'rtl':'ltr'
    }}
    collapsible collapsed={collapsed} onCollapse={onCollapse} >
      {!collapsed?
      <div className="logo" >
        <img alt="" style={{width: '60%',position: 'relative',top:isRTL?'-6px':'-6px',left:'30px'}} src={require('../../assets/images/logo.png')} ></img>
        <p style={{letterSpacing:'6px',color:'#fff',fontSize:isRTL?'34px':'26px',fontStyle: 'oblique',
            fontWeight: '800',fontFamily: 'fantasy',display:'inline-block',marginLeft:isRTL?'10px':'0px',marginTop:isRTL?'-10px': 0}}></p>
      </div>
      :
      <div className="logo" >
        <img alt="" style={{width: '95%',position: 'relative',top: '2px',left:'0px'}} src={require('../../assets/images/logo.png')} ></img>       
      </div>      
      }
      
      
      
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
      >
        <Menu.Item key="/Home">
          <HomeOutlined />
          <span className="menuTitle">{allStrings.home}</span>
          <Link to="/Home"></Link>
        </Menu.Item>
        
        <SubMenu  key="sub2" icon={<UserOutlined style={{/*
            float:isRTL? 'right':'none',
            position:isRTL?'absolute':'relative',
            right:isRTL?collapsed?28:45:'auto'*/
            }} />} title={allStrings.users}>
            {/*CLIENTS */}
            {currentUser.data.type ==="ADMIN" ?
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Users" > 
            <UserOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}}/>
                <span className="menuTitle"  style={{marginRight:isRTL? '6px':'0px'}}>{allStrings.users}</span>
              <Link to="/Users"></Link>
            </Menu.Item>
            :
            currentUser.data.type ==="SUB-ADMIN" && currentUser.data.permission.pages.includes('CLIENTS')&&
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Users" > 
            <UserOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}}/>
                <span className="menuTitle"  style={{marginRight:isRTL? '6px':'0px'}}>{allStrings.users}</span>
              <Link to="/Users"></Link>
            </Menu.Item>
            }
            {/*vendors */}
            {currentUser.data.type ==="ADMIN" ?
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Vendors" > 
            <UserOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}} />
                <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.vendors}</span>
              <Link to="/vendors"></Link>
            </Menu.Item>
            :
            currentUser.data.type ==="SUB-ADMIN" && currentUser.data.permission.pages.includes('VENDORS')&&
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Vendors" > 
            <UserOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}} />
                <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.vendors}</span>
              <Link to="/vendors"></Link>
            </Menu.Item>
            }
            {currentUser.data.type ==="ADMIN" ?
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Drivers" > 
            <UserOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}} />
                <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.drivers}</span>
              <Link to="/drivers"></Link>
            </Menu.Item>
            :
            currentUser.data.type ==="SUB-ADMIN" && currentUser.data.permission.pages.includes('DRIVERS')&&
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Drivers" > 
            <UserOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}} />
                <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.drivers}</span>
              <Link to="/drivers"></Link>
            </Menu.Item>
            }
            {currentUser.data.type ==="ADMIN" &&
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Admins" > 
            <TeamOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}} />
                <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.admin}</span>
              <Link to="/Admins"></Link>
            </Menu.Item>
            }
            {currentUser.data.type ==="ADMIN" &&
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/addUser" > 
            <UserOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}}/>
                <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.AddUser}</span>
              <Link to="/addUser"></Link>
            </Menu.Item>
            }
        </SubMenu>

        {/*categories */}
        {currentUser.data.type ==="ADMIN" ?
         <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Categories"> 
         <ApartmentOutlined  style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
           <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.categories}</span>
           <Link to="/Categories"></Link>
         </Menu.Item>
        :
        currentUser.data.type ==="SUB-ADMIN" && currentUser.data.permission.pages.includes('CATEGORIES')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Categories"> 
        <ApartmentOutlined  style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
          <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.categories}</span>
          <Link to="/Categories"></Link>
        </Menu.Item>
        }
         {/*anoncements */}
         {currentUser.data.type ==="ADMIN" ?
         <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/anoncements"> 
         <DesktopOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
           <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.anoncements}</span>
           <Link to="/anoncements"></Link>
         </Menu.Item>
        :
        currentUser.data.type ==="SUB-ADMIN" && currentUser.data.permission.pages.includes('ANONCEMENTS')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/anoncements"> 
        <DesktopOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
          <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.anoncements}</span>
          <Link to="/anoncements"></Link>
        </Menu.Item>
        }
        {currentUser.data.type ==="ADMIN" ?
        <SubMenu key="sub3" icon={<ShoppingCartOutlined />} title={allStrings.Orders}>
          {/*<Menu.Item key="/PendingOrders"> 
          <ShoppingCartOutlined  />
            <span className="menuTitle">{allStrings.PendingOrders}</span>
            <Link to="/PendingOrders"></Link>
          </Menu.Item>*/}
          <Menu.Item key="/AcceptedOrders"> 
          <ShoppingCartOutlined  />
            <span className="menuTitle">{allStrings.acceptedOrders}</span>
            <Link to="/AcceptedOrders"></Link>
          </Menu.Item>
          <Menu.Item key="/CancelledOrders"> 
          <ShoppingCartOutlined  />
            <span className="menuTitle">{allStrings.cancelledOrders}</span>
            <Link to="/CancelledOrders"></Link>
          </Menu.Item>
          <Menu.Item key="/OutForDelivery"> 
          <ShoppingCartOutlined  />
            <span className="menuTitle">{allStrings.outForDelivery}</span>
            <Link to="/OutForDelivery"></Link>
          </Menu.Item>
          <Menu.Item key="/DeliverdOrders"> 
          <ShoppingCartOutlined  />
            <span className="menuTitle">{allStrings.deliverdOrders}</span>
            <Link to="/DeliverdOrders"></Link>
          </Menu.Item>
          
        </SubMenu>
        :
        currentUser.data.type ==="SUB-ADMIN" && currentUser.data.permission.pages.includes('ORDERS')&&
        <SubMenu key="sub3" icon={<ShoppingCartOutlined />} title={allStrings.Orders}>
          {/*<Menu.Item key="/PendingOrders"> 
          <ShoppingCartOutlined  />
            <span className="menuTitle">{allStrings.PendingOrders}</span>
            <Link to="/PendingOrders"></Link>
          </Menu.Item>*/}
          <Menu.Item key="/AcceptedOrders"> 
          <ShoppingCartOutlined  />
            <span className="menuTitle">{allStrings.acceptedOrders}</span>
            <Link to="/AcceptedOrders"></Link>
          </Menu.Item>
          <Menu.Item key="/DeliverdOrders"> 
          <ShoppingCartOutlined  />
            <span className="menuTitle">{allStrings.deliverdOrders}</span>
            <Link to="/DeliverdOrders"></Link>
          </Menu.Item>
          
        </SubMenu>
        }
        
        <SubMenu key="sub1" icon={<ShopOutlined />} title={allStrings.store}>
          {/*SIZE */}
         {currentUser.data.type ==="ADMIN" ?
          <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Sizes"> 
          <FontSizeOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.sizes}</span>
            <Link to="/Sizes"></Link>
          </Menu.Item>
          :
          currentUser.data.type ==="SUB-ADMIN" && currentUser.data.permission.pages.includes('STORE')&&
          <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Sizes"> 
          <FontSizeOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.sizes}</span>
            <Link to="/Sizes"></Link>
          </Menu.Item>
          }
          {currentUser.data.type ==="ADMIN" ?
          <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Colors"> 
          <HighlightOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.colors}</span>
            <Link to="/Colors"></Link>
          </Menu.Item>
          :
          currentUser.data.type ==="SUB-ADMIN" && currentUser.data.permission.pages.includes('STORE')&&
          <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Colors"> 
          <HighlightOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.colors}</span>
            <Link to="/Colors"></Link>
          </Menu.Item>
          }
          {currentUser.data.type ==="ADMIN" ?
          <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Brands"> 
          <AppleOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.brands}</span>
            <Link to="/Brands"></Link>
          </Menu.Item>
          :
          currentUser.data.type ==="SUB-ADMIN" && currentUser.data.permission.pages.includes('STORE')&&
          <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Brands"> 
          <AppleOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.brands}</span>
            <Link to="/Brands"></Link>
          </Menu.Item>
          }
          {currentUser.data.type ==="ADMIN" ?
          <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Products"> 
          <ShoppingOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.products}</span>
            <Link to="/Products"></Link>
          </Menu.Item>
          :
          currentUser.data.type ==="SUB-ADMIN" && currentUser.data.permission.pages.includes('PRODUCTS')&&
          <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Products"> 
          <ShoppingOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.products}</span>
            <Link to="/Products"></Link>
          </Menu.Item>
          }
          {currentUser.data.type ==="ADMIN" ?
          <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Coupons"> 
          <TagsOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.coupons}</span>
            <Link to="/Coupons"></Link>
          </Menu.Item>
          :
          currentUser.data.type ==="SUB-ADMIN" && currentUser.data.permission.pages.includes('COUPONS')&&
          <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Coupons"> 
          <TagsOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.coupons}</span>
            <Link to="/Coupons"></Link>
          </Menu.Item>
          }
        </SubMenu>
        {/*COUNTRIES */}
        {currentUser.data.type ==="ADMIN" ?
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Countries"> 
        <FlagOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
          <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.Countries}</span>
          <Link to="/Countries"></Link>
        </Menu.Item>
        :
        currentUser.data.type ==="SUB-ADMIN" && currentUser.data.permission.pages.includes('COUNTRIES')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Countries"> 
        <FlagOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
          <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.Countries}</span>
          <Link to="/Countries"></Link>
        </Menu.Item>
        }
        {currentUser.data.type ==="ADMIN" ?
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Blog"> 
        <ReadOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.blog}</span>
          <Link to="/Blog"></Link>
        </Menu.Item>
        :
        currentUser.data.type ==="SUB-ADMIN" && currentUser.data.permission.pages.includes('BLOG')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Blog"> 
        <ReadOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.blog}</span>
          <Link to="/Blog"></Link>
        </Menu.Item>
        }
        {/*ABOUT */}
        {currentUser.data.type ==="ADMIN" ?
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/about"> 
            <InfoCircleOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}}/>
                <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.about}</span>
              <Link to="/about"></Link>
            </Menu.Item>
            :
            currentUser.data.type ==="SUB-ADMIN" && currentUser.data.permission.pages.includes('ABOUT')&&
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/about"> 
              <InfoCircleOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}}/>
                  <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.about}</span>
                <Link to="/about"></Link>
            </Menu.Item>
        }
        {/*terms */}
        {currentUser.data.type ==="ADMIN" ?
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Terms"> 
            <SafetyOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}} />
                <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.Terms}</span>
              <Link to="/Terms"></Link>
            </Menu.Item>
            :
            currentUser.data.type ==="SUB-ADMIN" && currentUser.data.permission.pages.includes('SETTINGS')&&
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Terms"> 
            <SafetyOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}} />
                <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.Terms}</span>
              <Link to="/Terms"></Link>
            </Menu.Item>
            }
        {/*SETTINGS */}
        {currentUser.data.type ==="ADMIN" ?
          <Menu.Item style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Setting"> 
          <ControlOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}} />
              <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.setting}</span>
            <Link to="/Setting"></Link>
          </Menu.Item>
          :
          currentUser.data.type ==="SUB-ADMIN" && currentUser.data.permission.pages.includes('SETTINGS')&&
          <Menu.Item style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Setting"> 
            <ControlOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}} />
                <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.setting}</span>
              <Link to="/Setting"></Link>
          </Menu.Item>
        }
        {/*NOTIFS */}
        {currentUser.data.type ==="ADMIN" ?
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/SendNotifs"> 
        <NotificationOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.sendNotif}</span>
          <Link to="/SendNotifs"></Link>
        </Menu.Item>
        :
        currentUser.data.type ==="SUB-ADMIN" && currentUser.data.permission.pages.includes('NOTIFS')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/SendNotifs"> 
        <NotificationOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.sendNotif}</span>
          <Link to="/SendNotifs"></Link>
        </Menu.Item>
        }
        
        {/*CONTACT-US */}
        {currentUser.data.type ==="ADMIN" ?
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/ContactUs"> 
        <MessageOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.contactUs}</span>
          <Link to="/ContactUs"></Link>
        </Menu.Item>
        :
        currentUser.data.type ==="SUB-ADMIN" && currentUser.data.permission.pages.includes('CONTACT-US')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/ContactUs"> 
        <MessageOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.contactUs}</span>
          <Link to="/ContactUs"></Link>
        </Menu.Item>
        }
        <Menu.Item></Menu.Item>
        <Menu.Item></Menu.Item>
        <Menu.Item></Menu.Item>
        
        
        
      </Menu>
      
    </Sider>
  );
};
const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
  menu: state.menu.menu,
})

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
  Collapsed
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(SiderMenu)
);



