import React from 'react';
import './search.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn } from "mdbreact";
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import {withRouter,Redirect} from 'react-router-dom'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import {setUser} from '../../redux/actions/AuthActions'
import {Input,Checkbox} from 'antd';
import LoadCard from '../../component/load card/load card'
import { SearchOutlined  } from '@ant-design/icons';

class Search extends React.Component {
  
  state = {
    users:[],
    vendors:[],
    products:[],
    loading:true,
    refresh:false,
    page:1,
    pages:1,
    showvendors:true,
    showproducts:true,
    showusers:true,
    value:this.props.history.location.state?this.props.history.location.state.data.search:'',
    checkedValues:["vendors","users","products"]
  }
  constructor(props){
    super(props)
    console.log("isr   ",this.props.history)
    this.getuserWithoutPagenation()
    this.getvendorsWithoutPagenation()
    this.getproducts()
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
    window.scrollTo(0,0)
  }
  getvendorsWithoutPagenation = () => {
    this.setState({loading:true})
    let value = this.state.value
    console.log(value)
    let url =``;
    if(value !== ''){
        url = `${BASE_END_POINT}getAll?type=VENDOR&search=${value}`
    }else{
      url = `${BASE_END_POINT}getAll?type=VENDOR`
    }
    
    axios.get(url)
    .then(response=>{
      console.log(response.data.data)
      this.setState({vendors:response.data.data})
    })
    .catch(error=>{
      console.log("ALL products ERROR")
      console.log(error.response)
    })
  }
  getuserWithoutPagenation = () => {
    this.setState({loading:true})
    let value = this.state.value
    let url =``;
    if(value !== ''){
        url = `${BASE_END_POINT}getAll?type=USER&search=${value}`
    }else{
      url = `${BASE_END_POINT}getAll?type=USER`
    }
    axios.get(url)
    .then(response=>{
      console.log(response.data.data)
      this.setState({users:response.data.data,})
    })
    .catch(error=>{
      console.log("ALL products ERROR")
      console.log(error.response)
    })
  }
  getproducts = () => {
    this.setState({loading:true})
    let value = this.state.value
    let url =``;
    if(value !== ''){
        url = `${BASE_END_POINT}products?search=${value}`
    }else{
      url = `${BASE_END_POINT}products`
    }
    axios.get(url)
    .then(response=>{
      console.log(response.data.data)
      this.setState({products:response.data.data,loading:false})
    })
    .catch(error=>{
      console.log("ALL products ERROR")
      console.log(error.response)
    })
  }
  componentDidMount(){
  }
  onChange = (checkedValues) => {
    console.log('checked = ', checkedValues);
    let vendors = checkedValues.indexOf("vendors");
    let users = checkedValues.indexOf("users");
    let products = checkedValues.indexOf("products");
    console.log(products)
    if(vendors !== -1){
      this.setState({showvendors:true})
    }else{
      this.setState({showvendors:false})
    }
    if(users !== -1){
      this.setState({showusers:true})
    }else{
      this.setState({showusers:false})
    }
    if(products !== -1){
      this.setState({showproducts:true})
    }else{
      this.setState({showproducts:false})
    }
  }
 
  render(){
    console.log(this.state.value)
    if (this.props.currentUser.data.type !== "ADMIN") return <Redirect to='/Home' /> 
  return (
    <div className="Category search">
      <div className="filters" style={{marginBottom: "2rem"}}>
        <MDBContainer> 
          <MDBRow>
            <MDBCol xl="4" lg="4" md="4" sm="12">
              <MDBBtn  
              onClick={() => {
                this.getuserWithoutPagenation()
                this.getproducts()
                this.getvendorsWithoutPagenation()
                document.getElementById('theSearch').value = "";
              }} 
                style={{width:'150px',padding:'9px',height:'40px',float:'right',margin:0 }}
                    className="btn z-depth-1a "
                    color="primary"
                >
                  {allStrings.Search}
              </MDBBtn>
            </MDBCol>
            <MDBCol xl="8" lg="8" md="8" sm="12">
              <Input 
              id="theSearch"
              defaultValue={this.state.value}
                onKeyUp={(e)=>{
                      console.log("ENTER   ",e.target.value)
                      this.setState({value:e.target.value})
                }} 
                placeholder={allStrings.Search}
                size="large"  prefix={<SearchOutlined />} />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <Checkbox.Group defaultValue={this.state.checkedValues}
         style={{ width: '100%',textAlign: 'end',padding: '10px 30px' }}  onChange={this.onChange}>
          <Checkbox value="vendors">{allStrings.vendors}</Checkbox>
          <Checkbox value="products">{allStrings.products}</Checkbox>
          <Checkbox value="users">{allStrings.users}</Checkbox>
        </Checkbox.Group>
      </div>
      <MDBContainer> 
        {this.state.showvendors === true &&
        <MDBRow>
          <MDBCol md="12">
            <label  htmlFor="defaultFormRegisterNameEx2"
            style={{
              float:'right',
              color: 'slategray',
              fontSize: '18px',
              marginBottom: '10px',
            }}>
            {allStrings.vendors}
            </label>
          </MDBCol>
        </MDBRow>
        }
        {this.state.showvendors === true &&
        <div className="searchCountainer">
          <MDBRow>
            {this.state.loading?
              <LoadCard/>
              :
              this.state.vendors.length > 0 ?
                this.state.vendors.map(val=>(
                  <MDBCol xl="4" lg="6" md="12" sm="12">
                  <div className="categoryCard">
                  <MDBContainer> 
                    <MDBRow>
                      <MDBCol xl="2" lg="2" md="2" sm="2">
                      </MDBCol>
                      <MDBCol xl="6" lg="6" md="6" sm="6" className="noPadding">
                        <div className="categoryInfo" onClick={() => this.props.history.push('/userInfo',{data:val})}>
                          <p style={{
                            fontWeight: '600',
                            color:'#605363',
                            marginBottom: '6px',
                            fontSize: '1.2rem'
      
                          }}>{val.firstname + ' ' + val.lastname.substr(0,7)}</p>
                          <p style={{
                          color: '#A4A3B0',
                          fontSize: '14px',
                          marginBottom: '8px'}}>{val.phone}</p>
                         
                        </div>
                      </MDBCol>
                      <MDBCol xl="4" lg="4" md="4" sm="4">
                        <div className="categoryImg" >
                          <img alt="" style={{width: '85px',
                          height: '85px',
                          borderRadius: '100%'}} src={val.img?val.img:require('../../assets/images/profileicon.jpg')}></img>
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer> 
                  </div>
                  </MDBCol>
                ))
                :
                <div className="noData">{allStrings.noData}</div>
            }
            </MDBRow>
        </div>
      }{this.state.showusers === true &&
        <MDBRow>
          <MDBCol md="12">
            <label  htmlFor="defaultFormRegisterNameEx2"
            style={{
              float:'right',
              color: 'slategray',
              fontSize: '18px',
              marginBottom: '10px',
            }}>
            {allStrings.users}
            </label>
          </MDBCol>
        </MDBRow>
       }{this.state.showusers === true &&
        <div className="searchCountainer">
          <MDBRow>
          {this.state.loading?
            <LoadCard/>
            :
            this.state.users.length > 0 ?
              this.state.users.map(val=>(
                <MDBCol xl="4" lg="6" md="12" sm="12">
                <div className="categoryCard" >
                <MDBContainer> 
                  <MDBRow>
                    <MDBCol xl="2" lg="2" md="2" sm="2">
                    </MDBCol>
                    <MDBCol xl="6" lg="6" md="6" sm="6" className="noPadding">
                      <div className="categoryInfo" onClick={() => this.props.history.push('/userInfo',{data:val})} >
                        <p style={{
                          fontWeight: '600',
                          color:'#605363',
                          marginBottom: '6px',
                          fontSize: '1.2rem'
    
                        }}>{val.firstname + ' ' + val.lastname.substr(0,7)}</p>
                      
                        <p style={{
                        color: '#605363',
                        fontSize: '16px',
                        marginBottom: '8px',
                        display:'inline-block'}}>{val.phone} </p>
                      </div>
                    </MDBCol>
                    <MDBCol xl="4" lg="4" md="4" sm="4">
                      <div className="categoryImg" >
                        <img alt="" style={{width: '85px',
                        height: '85px',
                        borderRadius: '100%'}} src={val.img?val.img:require('../../assets/images/profileicon.jpg')}></img>
                      </div>
                    </MDBCol>
                  </MDBRow>
                
                </MDBContainer> 
                </div>
                </MDBCol>
              ))
              :
              <div className="noData">{allStrings.noData}</div>
          }
          </MDBRow>
        </div>
        }{this.state.showproducts === true &&
        <MDBRow>
          <MDBCol md="12">
            <label  htmlFor="defaultFormRegisterNameEx2"
            style={{
              float:'right',
              color: 'slategray',
              fontSize: '18px',
              marginBottom: '10px',
            }}>
            {allStrings.products}
            </label>
          </MDBCol>
        </MDBRow>
        }{this.state.showproducts === true &&
        <div className="searchCountainer">
          <MDBRow>
          {this.state.loading?
            <LoadCard/>
            :
            this.state.products.length > 0 ?
              this.state.products.map(val=>(
                <MDBCol xl="4" lg="6" md="12" sm="12">
                <div className="categoryCard" >
                <MDBContainer> 
                  <MDBRow>
                    <MDBCol xl="2" lg="2" md="2" sm="2">
                    </MDBCol>
                    <MDBCol xl="8" lg="8" md="8" sm="8" className="noPadding">
                      <div className="categoryInfo" onClick={() => this.props.history.push('/productInfo',{data:val})} >
                        <p style={{
                          fontWeight: '600',
                          color:'#605363',
                          marginBottom: '6px',
                          fontSize: '1.2rem'
    
                        }}>{".. " +val.name.substr(0,15)}</p>
                      
                        <p style={{
                        color: '#605363',
                        fontSize: '16px',
                        margin: 0,
                        marginBottom: '10px'}}>{val.category?val.category.name:""}</p>
                        
                      </div>
                    </MDBCol>
                    <MDBCol xl="4" lg="4" md="4" sm="4">
                      <div className="categoryImg" >
                        <img alt="" style={{width: '85px',
                        height: '85px',
                        borderRadius: '100%'}} src={val.images[0]?val.images[0].images[0]:require('../../assets/images/profileicon.jpg')}></img>
                      </div>
                    </MDBCol>
                  </MDBRow>
                
                </MDBContainer> 
                </div>
                </MDBCol>
              ))
              :
              <div className="noData">{allStrings.noData}</div>
          }
          </MDBRow>
        </div>
      }
      </MDBContainer> 
    </div> 
  )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
}

export default  withRouter( connect(mapToStateProps,mapDispatchToProps) (Search))
