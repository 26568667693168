import {LANG} from '../actions/types';
const initState = {
    isRTL:true,
}

const LanguageReducer = (state=initState,action) => {
    switch(action.type){
        case LANG:
            //console.log("Lang")
        return {...state,isRTL:action.payload}
        default:
        return state;
    }
}

export default LanguageReducer;