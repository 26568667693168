/* eslint-disable eqeqeq */
import React from "react";
import "./product info.css";
import {MDBContainer,MDBRow,MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import Rater from "react-rater";
import "react-rater/lib/react-rater.css";
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { setUser } from "../../redux/actions/AuthActions";
import { hasValue } from "../../validations/validations";
import { message,TreeSelect} from "antd";
import Skeleton from 'react-loading-skeleton';
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@material-ui/icons';
const { TreeNode } = TreeSelect;
const { SHOW_PARENT } = TreeSelect;

class ProductInfo extends React.Component {
  
  state = {
    actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
    product: this.props.location.state?this.props.location.state.data:[],
    productImgs: [],
    name_en:this.props.location.state?this.props.location.state.data.name_en?this.props.location.state.data.name_en:'':'',
    name_ar:this.props.location.state?this.props.location.state.data.name_ar?this.props.location.state.data.name_ar:'':'',
    description_ar:this.props.location.state?this.props.location.state.data.description_ar?this.props.location.state.data.description_ar:'':'',
    description_en:this.props.location.state?this.props.location.state.data.description_en?this.props.location.state.data.description_en:'':'',
    category:this.props.location.state.data.category?this.props.location.state.data.category.id:[],
    subCategory: this.props.location.state.data.subCategory?this.props.location.state.data.subCategory.id:[],
    brand: this.props.location.state.data.brand?this.props.location.state.data.brand.id:[],
    owner: this.props.location.state.data.owner?this.props.location.state.data.owner:[],

    color: this.props.location.state.data.color?[]:[],
    rate:this.props.location.state.data.rate,
    id:this.props.location.state.data.id,
    available:this.props.location.state.data.available,
    viewsCount:this.props.location.state.data.viewsCount,
    stock:[],
    quantity:this.props.location.state.data.quantity?this.props.location.state.data.quantity:0,
    
    selectedFile: null,
    imgs: null,
    imgsType:'url',
    imgsPreviewUrl:this.props.location.state.data.images?this.props.location.state.data.images:[],
    
    categories:[],
    subcategories:[],
    brands:[],
    colors:[],
    sizes:[],
    loading:false,
    refresh:false,
    page:1,
    pages:1,
    modal:false,
    pages1:1,
    page1:1,
    productsizes:[],
    productColors:[],
    offers:[],
    theStock:[{size:null, quantity:0,price:0,colors:[{color:null,quantity:0}]}]
  };

  constructor(props){
    super(props)
    window.scrollTo(0,0)
    
    this.getData()
    if(this.props.location.state.actionType !="EDIT"){
      this.getOffers()
    }
    
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
  }
  getData = () => {
    axios.get(`${BASE_END_POINT}products/${this.state.id}`,{
      headers: {
      'Content-Type': 'application/json',
      'Authorization':`Bearer ${this.props.currentUser.token}`,
      'Accept-Language':this.props.isRTL?'ar':'en'
      }})
    .then(response=>{
        console.log('products  ',response.data)
        console.log(response.data.data.stock[0].price)
        this.setState({
          stock:response.data.data.stock,
          description_en:response.data.data.description_en?response.data.data.description_en:'',
          description_ar:response.data.data.description_ar?response.data.data.description_ar:'',
          name_en:response.data.data.name_en?response.data.data.name_en:'',
          name_ar:response.data.data.name_ar?response.data.data.name_ar:'',
          category:response.data.data.category?response.data.data.category.id:[],
          subCategory: response.data.data.subCategory?response.data.data.subCategory.id:[],
          owner: response.data.data.owner?response.data.data.owner:[],
          brand: response.data.data.brand?response.data.data.brand.id:[],
          color: response.data.data.color?[]:[],
          rate:response.data.data.rate,
          available:response.data.data.available,
          viewsCount:response.data.data.viewsCount,
          quantity:response.data.data.quantity?response.data.data.quantity:0,
        })
        ///
        let stock = response.data.data.stock
        
        console.log("stock",stock)
        let arr = []
        for(let i=0; i< stock.length; i++){
          let colors = stock[i].colors
          let index = {
            size: stock[i].size.id,
            quantity: stock[i].quantity,
            price: stock[i].price,
            stockId:stock[i].id
          }
          let sizeColors = []
          console.log("colors",colors)
          if(colors.length > 0){
            for(let j=0; j< colors.length; j++){
              let color = colors[j].color.id
              if(color != null){
                sizeColors.push({color: color,quantity: colors[j].quantity})
              }
              if(sizeColors.length > 0){
                index.colors = sizeColors
              }
            }
          }else{
            if(this.props.location.state.actionType =="EDIT"){
              index.colors = [{color: null,quantity: 0}]
            }else{
              index.colors = []
            }
            
          }
          
          arr.push(index)
        }
        console.log("arr",arr)
        
        this.setState({theStock:arr})
        ////
        let productsizes=[]
        for (const v of response.data.data.stock) {
          productsizes.push(v.size)
        }
        console.log("sizess",productsizes)
        this.setState({productsizes: productsizes})
        let productColors=[]
        for (const v of response.data.data.stock) {
          for (const i of v.colors) {
            productColors.push(i.color)
          }
        }
        console.log("colors",productColors)
        this.setState({productColors: productColors})
    })
    .catch(error=>{
        console.log('products ERROR  ',error)
        console.log('products ERROR  ',error.respose)
    })
  }
  getOffers = (page) => {
    axios.get(`${BASE_END_POINT}products/${this.state.id}/findAll/offers?page=${page}`,{
      headers: {
      'Content-Type': 'application/json',
      'Authorization':`Bearer ${this.props.currentUser.token}`,
      'Accept-Language':this.props.isRTL?'ar':'en'
      }})
    .then(response=>{
      console.log('offers',response.data)
      this.setState({offers:response.data.data})
    })
    .catch(error=>{
      console.log("ALL offers ERROR")
      console.log(error.response)
    })
  }
  getsizes = () => {
    axios.get(`${BASE_END_POINT}sizes/withoutPagenation/get`)
    .then(response=>{
      console.log(response.data)
      this.setState({sizes:response.data.data})
    })
    .catch(error=>{
      console.log("ALL sizes ERROR")
      console.log(error.response)
    })
  }
  getCategory = () => {
    axios.get(`${BASE_END_POINT}categories`)
    .then(response=>{
      console.log(response.data)
      this.setState({categories:response.data.data})
    })
    .catch(error=>{
      console.log("ALL categories ERROR")
      console.log(error.response)
    })
  }
  getSubCategories = (category) => {
      axios.get(`${BASE_END_POINT}categories/${category}/sub-categories`)
      .then(response=>{
        this.setState({subcategories:response.data.data})
      })
      .catch(error=>{
        console.log(' subcategories ERROR  ',error)
        console.log('subcategories ERROR  ',error.respose)
      })
      
  }
  getBrands = () => {
    axios.get(`${BASE_END_POINT}brands/withoutPagenation/get`)
    .then(response=>{
      console.log(response.data)
      this.setState({brands:response.data.data})
    })
    .catch(error=>{
      console.log("ALL brands ERROR")
      console.log(error.response)
    })
  }
  getColors = () => {
    axios.get(`${BASE_END_POINT}colors/withoutPagenation/get`)
    .then(response=>{
      console.log(response.data)
      this.setState({colors:response.data.data})
    })
    .catch(error=>{
      console.log("ALL colors ERROR")
      console.log(error.response)
    })
  }
  componentDidMount(){
    this.getCategory()
    this.getBrands()
    this.getColors()
    this.getsizes()
    this.getSubCategories(this.props.location.state.data.category.id)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  
    if(this.props.location.state.data.color){
      let arr = []
      let array = this.props.location.state.data.color
      array.forEach(element => {
        arr.push(element.id)
      });
      this.setState({color:arr})
    }
    let allImgs = [...this.props.location.state.data.images]
    let images = [];
    allImgs.forEach(val => {
      val.images.forEach(img => {
        images.push({
          original:img,
          thumbnail:img,
        })
      });
    });
    this.setState({productImgs:images})
  }
  update = () => {
    const {name_en,description_ar,category,subCategory,name_ar,imgs
      ,description_en,brand,freeShipping} = this.state
      let l = message.loading('Wait..', 2.5)
      let stock = [...new Set(this.state.theStock.filter(e => e.size != null))]
      if(subCategory===null||category===null ||brand === null || stock.length == 0){
        l.then(() => message.error(allStrings.completeData, 1.5) )
      }
      if(hasValue(name_en)&&hasValue(description_en)&&subCategory!=null && category!=null &&brand != null && stock.length != 0){
        
        var data = new FormData()
        console.log(category)
        data.append('name_en',name_en)
        if(hasValue(name_ar)){
          data.append('name_ar',name_ar)
        }else{
          data.append('name_ar',name_en)
        }
        if(hasValue(name_ar)){
          data.append('description_ar',description_ar)
        }else{
          data.append('description_ar',description_en)
        }
        data.append('freeShipping',freeShipping)
        data.append('description_en',description_en)
        data.append('category',category)
        data.append('subCategory',subCategory)
        data.append('brand',brand)
      
        let arr = []
        let quantity=0;
        for(let i=0; i< stock.length; i++){
          quantity = quantity + parseInt(stock[i].quantity)
          let colors = stock[i].colors
          let index = {
            size: stock[i].size,
            quantity: stock[i].quantity,
            price: stock[i].price
          }
          if(stock[i].stockId) index.stockId = stock[i].stockId
          let sizeColors = []
          if(colors.length>0){
            for(let j=0; j< colors.length; j++){
              let color = colors[j].color
              
              if(color != null){
                sizeColors.push({color: color,quantity: colors[j].quantity})
              }
              if(sizeColors.length > 0){
                index.colors = sizeColors
              }
            }
          }else{
            index.colors = []
          }
          arr.push(index)
        }

        data.append('quantity',quantity)
        data.append('theStock',JSON.stringify(arr))
        if(imgs != null){
          for(var i=0 ; i<= imgs.length-1 ; i++){
            data.append(`img`,imgs[i] )
          } 
        }
       
        //////
        
        console.log(Array.from(data))
        
        axios.put(`${BASE_END_POINT}products/${this.props.location.state.data.id}`,data,{
        headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization':`Bearer ${this.props.currentUser.token}`
        },
        })
        .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.push('/products')
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
    }
  }
  

  deleteProduct = () => {
    let l = message.loading('Wait..', 2.5)
    axios.delete(`${BASE_END_POINT}products/${this.props.location.state.data.id}`,{
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.props.currentUser.token}`
    },
    })
    .then(response=>{
      l.then(() => message.success(allStrings.done, 2.5) )
      this.props.history.goBack()
    })
    .catch(error=>{
        console.log(error.response)
        l.then(() => message.error(allStrings.error, 2.5))
    })
  }
   
  activeProduct = (active) => {
    let uri ='';
    if(active){
     uri = `${BASE_END_POINT}products/${this.props.location.state.data.id}/active`
    }else{
     uri = `${BASE_END_POINT}products/${this.props.location.state.data.id}/dis-active`
    }
   let l = message
    .loading('Wait..', 2.5)
    axios.put(uri,{},{
     headers: {
       'Content-Type': 'application/json',
       'Authorization': `Bearer ${this.props.currentUser.token}`
     },
   })
    .then(response=>{
         if(active){
             l.then(() => message.success(allStrings.done, 2.5))
             this.setState({active:true})
         }else{
            l.then(() => message.success(allStrings.done, 2.5))
            this.setState({active:false})
         }
         this.props.history.goBack()
    })
    .catch(error=>{
     console.log('Error   ',error.response)
     l.then(() => message.error(allStrings.error, 2.5))
    })
  }

  submitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.update()
  };
  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  fileChangedHandler = (event) => {
    let imgs =[];
    let imgsPreviewUrl = [];
    let imgsName = []
    for(var i=0 ; i<= event.target.files.length-1 ; i++){
        imgsPreviewUrl.push(URL.createObjectURL(event.target.files[i]))
        imgsName.push(event.target.files[i].name)
    }
    imgs.push(event.target.files)
    this.setState({
        imgs: event.target.files,
        imgsType:'data',
        imgsPreviewUrl:imgsPreviewUrl,
        imgsName:imgsName
    });
    
  };
  removeFile = (url) => {
    let arr = this.state.imgsPreviewUrl;
    let arr2 =  Array.from(this.state.imgs);
    var index = arr.indexOf(url);
    if (index !== -1) arr.splice(index, 1);
    if (index !== -1) arr2.splice(index, 1);
    this.setState({
      imgsPreviewUrl: arr,
      imgs:arr2,
    });
    if(arr2.length === 0){
      this.setState({
        imgs:null,
      });
    }

  };
  imageSection = () =>(
    <MDBCol xl="4" lg="5" md="12" sm="12">
    <div className="infoCard">
      <div className="StoreSlider">
        <ImageGallery items={this.state.productImgs} />
      </div>
    </div>
  </MDBCol>
  )

  buttonsSection = () =>{
      return(
        <div className="btnOption">
        <MDBRow>
          <MDBCol md="4">
            <MDBBtn
              color="red"
              rounded
              style={{ borderRadius: "2px",background:'red' }}
              className="btn-block z-depth-1a"
              onClick={()=>{
                  this.deleteProduct()
              }}
            >
              <MDBIcon icon="trash-alt" className="mr-2" />
              {allStrings.remove}
            </MDBBtn>
          </MDBCol>
          
          <MDBCol md="4">
            <MDBBtn
              rounded
              color="info"
              style={{ borderRadius: "2px" }}
              className="btn-block z-depth-1a"
              onClick={()=>{
                this.activeProduct(true)
              }}
            >
              <MDBIcon icon="check-circle" className="mr-2" />
              {allStrings.available}
            </MDBBtn>
          </MDBCol>
          <MDBCol md="4">
            <MDBBtn
              rounded
              color="warning"
              style={{ borderRadius: "2px" }}
              className="btn-block z-depth-1a"
              onClick={()=>{
                this.activeProduct(false)
              }}
            >
              <MDBIcon icon="ban" className="mr-2" />
              {allStrings.disAvailable}
            </MDBBtn>
          </MDBCol>
         
        </MDBRow>
        
      </div>
      )
  }
  stockTable = () =>{
    return(
    <MDBRow>
        <MDBCol md="12">
            <div className="infoTable">
                <div className="topInfoTable" style={{ background: '#605363' }}>
                    <h4>
                    <span class="material-icons" style={{color:'white',fontSize:23}}>format_size</span>
                    {allStrings.sizes}
                    </h4>
                </div>
                <div className="tableDate">
                    <div class="table-responsive">
                        <table class="table table-striped">
                        <thead>
                            <tr style={{textAlign:'center'}}>
                                <th>{allStrings.size}</th>
                                <th>{allStrings.price}</th>
                                <th>{allStrings.quantity}</th>
                                <th>{allStrings.more}</th>
                            </tr>
                        </thead>
                        <tbody>
                        {this.state.loading?
                            <tr style={{height: '200px'}}>
                                <th colspan="6" className="noData">
                                <Skeleton height={400}/> 
                                </th>
                            </tr>
                            :
                            this.state.stock.length > 0 ?
                            this.state.stock.map(val =>(
                            <tr style={{textAlign:'center'}}>
                                <td>{val.size?val.size.name:""}</td>
                                <td>{val.price}</td>
                                <td>{val.quantity}</td>
                                <td onClick={()=>{this.props.history.push("stockInfo",{data:val}) }} >
                                    <span style={{margin: "4px",cursor:'pointer'}}>
                                        <Tooltip title="details" placement="top">
                                        <Info style={{color:'#33d633'}}/>
                                        </Tooltip>
                                    </span>
                                </td>
                            </tr>
                                ))
            
                                :
                                <tr style={{height: '200px'}}>
                                    <th colspan="6" className="noData">
                                    <p style={{marginTop:'4rem',textAlign:'center'}}>{allStrings.noData}</p> 
                                    </th>
                                </tr>
                            }
                        </tbody>
                        </table>
                    </div>
                </div>
            </div>
            
        </MDBCol>
    </MDBRow>
    )
  }
  render() {
    if (this.props.currentUser.data.type === "SUB-ADMIN" && !this.props.currentUser.data.permission.pages.includes('PRODUCTS')) return <Redirect to='/Home' />
    return (
      <div className="ProductInfo">
        <MDBContainer>
          <MDBRow>
            <MDBCol xl ={this.state.actionType==="VIEW"?"8":"12"} lg = {this.state.actionType==="VIEW"?"7":"12"} md="12" sm="12">
              <div className="infoCard">
                  <Card className="editCard" style={{ overflow: "visible", outline: "none",marginBottom: "0px"}} >
                    <CardMedia style={{ height: "0px" }} />
                    <CardHeader></CardHeader>
                    <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                        <div className="topIcon" style={{background:'#605363'}}>
                            <span className="material-icons" style={{color:'white',fontSize:23}}>local_mall</span>
                        </div>
                        <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                        marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0'}}>
                        <p>{this.state.actionType==="VIEW"?allStrings.info:allStrings.update}</p>
                        </div>
                    </div>
                    <CardContent style={{ minHeight: "390px", outline: "none" }}>
                    <MDBContainer>
                      <MDBRow>
                        <MDBCol md="12">
                          <form
                            className="needs-validation"
                            onSubmit={this.submitHandler}
                            noValidate
                          >
                            <div className="grey-text">
                              {this.state.actionType==="VIEW"&&
                                <MDBRow>
                                  <MDBCol md="12" style={{ textAlign: this.props.isRTL?"right":"left" }}>
                                    <Rater style={{ float: this.props.isRTL?"left":"right" }} rating={this.state.rate} total={5} interactive={false} />
                                  </MDBCol>
                                </MDBRow>
                              }
                              {this.state.actionType==="VIEW"&&
                              <MDBRow>
                                <MDBCol md="6">
                                  <div class="md-form">
                                    <label for="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                    <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.id} type="text" id="form8" class="form-control" onChange={this.changeHandler} required validate/>
                                  </div>
                                </MDBCol>
                                <MDBCol md="6">
                                  <div class="md-form">
                                    <label for="form6" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.available}</label>
                                    <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.available?allStrings.yes:allStrings.no} type="text" id="form6" class="form-control" onChange={this.changeHandler} required validate/>
                                  </div>
                                </MDBCol>
                              </MDBRow>
                              }
                              <MDBRow>
                                <MDBCol md="6">
                                  <div class="md-form">
                                    <label for="form38" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicName}</label>
                                    <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.name_ar} type="text" id="form38"  name="name_ar"  class="form-control" onChange={this.changeHandler}  validate/>
                                  </div>
                                </MDBCol>
                                <MDBCol md="6">
                                <div class="md-form">
                                  <label for="form2" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishName}</label>
                                  <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z0-9\s]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.name_en} type="text" id="form2" name="name_en" class="form-control" onChange={this.changeHandler} required validate/>
                                </div>
                                </MDBCol>
                              </MDBRow>
                              
                             
                              <MDBRow>
                                <MDBCol md="6">
                                  <div class="md-form">
                                    <label  for="form303" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.quantity}</label>
                                    <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultdisabled={this.state.actionType==="VIEW"?true:false} Value={this.state.quantity} type="number" min={0} id="form303" name="quantity" class="form-control" onChange={this.changeHandler}  validate/>
                                  </div>
                                </MDBCol>
                                <MDBCol md="6">
                                  <div class="md-form">
                                    <label  for="form3" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.viewsCount}</label>
                                    <input disabled={this.state.actionType==="VIEW"?true:false}  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.viewsCount} type="text" id="form3" name="viewsCount" class="form-control" onChange={this.changeHandler}  validate/>
                                  </div>
                                </MDBCol>
                              </MDBRow>
                              <MDBRow  style={{flexFlow:this.props.isRTL?'row-reverse':'row'}}>
                                <MDBCol md="6">
                                  <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.category}</label>
                                  <TreeSelect
                                   value= {this.state.category}
                                   showSearch={true} 
                                   disabled={this.state.actionType==="VIEW"?true:false}
                                   style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                   treeCheckable = {false}         
                                   treeNodeFilterProp="title"                      
                                   dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                   placeholder={allStrings.chooseCategory}
                                   showCheckedStrategy= {SHOW_PARENT}
                                   onChange={(value) => {
                                     this.setState({category:value,subCategory:[]});
                                     console.log(value)
                                     this.getSubCategories(value)
                                      
                                   }} 
                                  
                                  >
                                    {this.state.categories.map(val=>(
                                        <TreeNode value={val.id} title={this.props.isRTL?val.name_ar:val.name_en} key={val.id} />
                                    ))}
                                  </TreeSelect>
                                </MDBCol>
                                <MDBCol md="6">
                                  <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.subCategory}</label>
                                  <TreeSelect
                                    value= {this.state.subCategory}
                                    showSearch={true} 
                                    disabled={this.state.actionType==="VIEW"?true:false}
                                    style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                    treeNodeFilterProp="title"                      
                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    placeholder={allStrings.chooseSubCategory}
                                    treeCheckable={false}
                                    showCheckedStrategy= {SHOW_PARENT}
                                    onChange={(value) => {
                                      console.log(value)
                                      this.setState({subCategory:value});
                                    }} 
                                  
                                  >
                                    {this.state.subcategories.map(val=>(
                                        <TreeNode value={val.id} title={this.props.isRTL?val.name_ar:val.name_en} key={val.id} />
                                    ))}
                                  </TreeSelect>
                                </MDBCol>
                              </MDBRow>
                              <MDBRow  style={{flexFlow:this.props.isRTL?'row-reverse':'row'}}>
                                <MDBCol md="6">
                                  <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.brand}</label>
                                  <TreeSelect
                                   value= {this.state.brand}
                                   disabled={this.state.actionType==="VIEW"?true:false}
                                   showSearch={true} 
                                   style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                   treeCheckable = {false}         
                                   treeNodeFilterProp="title"                      
                                   dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                   placeholder={allStrings.brand}
                                   showCheckedStrategy= {SHOW_PARENT}
                                   onChange={(value) => {
                                     this.setState({brand:value});
                                   }} 
                                  
                                  >
                                    {this.state.brands.map(val=>(
                                        <TreeNode value={val.id} title={this.props.isRTL?val.name_ar:val.name_en} key={val.id} />
                                    ))}
                                  </TreeSelect>
                                </MDBCol>
                                <MDBCol md="6">
                                </MDBCol>
                              </MDBRow>
                              <MDBRow>
                                <MDBCol md="12">
                                  <div class="md-form">
                                    <label for="form7" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicDescription}</label>
                                    <br></br>
                                    <textarea disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } placeholder={allStrings.arabicDescription} rows="3" style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'6px'}} defaultValue={this.state.description_ar} type="text" id="form7" class="form-control" onChange={this.changeHandler} validate name="description_ar"/>
                                  </div>
                                </MDBCol>
                              </MDBRow>
                              <MDBRow>
                                <MDBCol md="12">
                                  <div class="md-form">
                                    <label for="form70" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishDescription}</label>
                                    <br></br>
                                    <textarea disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z0-9\s]/g,'') } placeholder={allStrings.englishDescription} rows="3" style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'6px'}} defaultValue={this.state.description_en} type="text" id="form7" class="form-control" onChange={this.changeHandler} required validate name="description_en"/>
                                  </div>
                                </MDBCol>
                              </MDBRow>
                              
                              <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                  <MDBCol md="10" xs="10">
                                      <div class="md-form">
                                      <label for="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.vendor}</label>
                                      <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.owner?this.state.owner.firstname + " " + this.state.owner.lastname:''} type="text" id="form80" class="form-control"  />
                                      </div>
                                  </MDBCol>
                                  <MDBCol md="2" xs="2">
                                  <p style={{textAlign: 'center',marginTop: '2rem',color: '#2BBBAD'}}>
                                      <span style={{cursor:'pointer',position: 'absolute',padding: '3px'}} class="material-icons"
                                      onClick={()=>{this.props.history.push('userInfo',{data:this.state.vendor}) }}>
                                        remove_red_eye
                                      </span>
                                      <span style={{cursor:'pointer',fontSize: '16px',fontWeight: '500',marginLeft: '2rem'}}
                                        onClick={()=>{this.props.history.push('userInfo',{data:this.state.vendor}) }}>
                                        {allStrings.show}
                                      </span> 
                                    </p>
                                  </MDBCol>
                              </MDBRow>
                            </div>
                            <br></br>
                            <br></br>
                          </form>
                        </MDBCol>
                      </MDBRow>
                    </MDBContainer>
                  </CardContent>
                </Card>
              </div>
            </MDBCol>
            {this.state.actionType==="VIEW"&&this.imageSection()}
          </MDBRow>
          {this.state.actionType==="VIEW"&&this.buttonsSection()}
          {this.state.actionType==="VIEW"&&this.stockTable()}          
        </MDBContainer>
      </div>
    );
  }
}

const mapToStateProps = (state) => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
});

const mapDispatchaddressrops = {
  setUser,
};

export default withRouter(
  connect(mapToStateProps, mapDispatchaddressrops)(ProductInfo)
);