import React from 'react';
import './area info.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message } from "antd";
//onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') }   english only
//onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') } english char and numbers only
//oninput="this.value=this.value.replace(/[^0-9]/g,'');" only english numbers
//oninput="this.value=this.value.replace(/[^a-zA-Z0-9$@$!%*?&#^-_. +]/g,'');" oly specail char and english numbers and chars
//oninput="this.value=this.value.replace(/^[A-Za-z0-9]+$/g,'');" arabic only
class AreaInfo extends React.Component {
  state = {
    actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
      id:this.props.location.state.data.id,
      name_en:this.props.location.state.data.name_en,
      name_ar:this.props.location.state.data.name_ar,
  };

  constructor(props){
    super(props)
    window.scrollTo(0,0)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
  }

  updateArea = () => {
    const {name_en,name_ar} = this.state
    if(hasValue(name_en)&&hasValue(name_ar)){
        let l = message.loading('Wait..', 2.5)
        const data={
            'name_en':name_en,
            'name_ar':name_ar,
        }
        
        axios.put(`${BASE_END_POINT}cities/${this.props.location.state.data.id}/areas`,JSON.stringify(data),{
        headers: {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
        },
        })
        .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
        this.props.history.goBack() 
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
    }
  }

  deleteArea = () => {
      let l = message.loading('Wait..', 2.5)
      axios.delete(`${BASE_END_POINT}cities/${this.props.location.state.data.id}/areas`,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
      },
      })
      .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
        this.props.history.goBack() 
      })
      .catch(error=>{
          console.log(error.response)
          l.then(() => message.error(allStrings.error, 2.5))
      })
  }

  submitHandler = event => {
    event.preventDefault();
    console.log(event.target.className)
    event.target.className += " was-validated";
    this.updateArea()
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

     
  render(){
    if (this.props.currentUser.data.type === "SUB-ADMIN" && !this.props.currentUser.data.permission.pages.includes('COUNTRIES')) return <Redirect to='/Home' />
    return (
      <div className="App">
        
          <MDBContainer> 
              <MDBRow>
              
                  <MDBCol md="12">
                      <div className="infoCard">
                          <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                          <CardMedia
                          style={{height:"0px"}}
                          />
                          <CardHeader></CardHeader>
                          <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                              <div className="topIcon" style={{background:'#605363'}}>
                                  <span className="material-icons" style={{color:'white',fontSize:23}}>'location_city'</span>
                              </div>
                              <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                              marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0'}}>
                              <p>{this.state.actionType==="VIEW"?allStrings.areaInfo:allStrings.edit}</p>
                              </div>
                          </div>
                          <CardContent style={{minHeight:"390px",outline:'none'}}>
                          <MDBContainer>
                              <MDBRow>
                                  <MDBCol md="12">
                                  <form
                                  className="needs-validation"
                                  onSubmit={this.submitHandler}
                                  noValidate
                                  >
                                      <div className="grey-text">
                                      {this.state.actionType==="VIEW"&&
                                      <MDBRow>
                                          <MDBCol md="12">
                                              <div className="md-form">
                                              <label htmlFor="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                              <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.id} type="text" id="form8" className="form-control" onChange={this.changeHandler} required validate/>
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                          </MDBCol>
                                          
                                      </MDBRow>
                                      }
                                      <MDBRow>
                                          <MDBCol md="6">
                                              <div className="md-form">
                                              <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishName}</label>
                                              <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') }  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.name_en} type="text" id="form645" name="name_en" className="form-control" onChange={this.changeHandler} required  validate/>
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                              <div className="md-form">
                                              <label htmlFor="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicName}</label>
                                              <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.name_ar} type="text" id="form56" name="name_ar" className="form-control" onChange={this.changeHandler} required validate/>
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                          </MDBCol>
                                      </MDBRow>
                                      </div>
                                      <br></br>
                                      <br></br>
                                      <div className="text-center">
                                      {this.state.actionType==="EDIT"&&
                                          <MDBBtn
                                          type="submit" style={{background:'#605363'}}
                                          rounded
                                          className="btn-block z-depth-1a"
                                          >
                                              <MDBIcon icon="edit" className="mr-2" />
                                              {allStrings.updateArea}          
                                          </MDBBtn>
                                      }{this.state.actionType==="VIEW"&&
                                          <MDBBtn onClick={()=>{this.deleteArea()}} rounded color="danger" className="btn-block z-depth-1a">
                                              <MDBIcon icon="trash-alt" className="mr-2" />
                                              {allStrings.remove}
                                          </MDBBtn>
                                      }
                                          
                                      </div>
                                  </form>
                                  </MDBCol>
                              </MDBRow>
                          </MDBContainer>
                          </CardContent>
                      </Card>
                  </div>
              </MDBCol>
              </MDBRow>
            
        </MDBContainer>
        
      </div> 
    )
  }
}


const mapToStateProps = (state) => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
});

const mapDispatchToProps = {
};

export default withRouter(
  connect(mapToStateProps, mapDispatchToProps)(AreaInfo)
);