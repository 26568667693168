import React from 'react';
import './home.css'
import { MDBContainer,  MDBRow, MDBCol} from "mdbreact";

import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import {withRouter,Redirect,NavLink} from 'react-router-dom'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import {setUser} from '../../redux/actions/AuthActions'
import Skeleton from 'react-loading-skeleton';

class Home extends React.Component {
  
  state = {
    count:[],
    tops:[],
    topProductsRate:[],
    user:[],
    orders:[],
    loading:true,
   
  }
  
   
  constructor(props){
    super(props)
    this.getCount()
    //this.getTopProducts()
    this.getOrders()
    window.scrollTo(0,0)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }
  getTopProducts = () => {
    axios.get(`${BASE_END_POINT}products?sort=rate`,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log(response.data)
      this.setState({topProductsRate:response.data.data})
    })
    .catch(error=>{
      console.log("ALL topProductsRate ERROR")
      console.log(error.response)
    })
  }
  getCount = () => {
    axios.get(`${BASE_END_POINT}admin/count`,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.props.currentUser.token}`
      },
    })
    .then(response=>{
      console.log(response.data)
      this.setState({count:response.data,loading:false,topProductsRate:[]/*response.data.topProductsRate */})
    })
    .catch(error=>{
      console.log("ALL count ERROR")
      console.log(error.response)
    })
  }
  getOrders = () => {
    axios.get(`${BASE_END_POINT}orders?page=1&limit=7`,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log(response.data.data)
      this.setState({orders:response.data.data})
    })
    .catch(error=>{
      console.log("ALL orders ERROR")
      console.log(error.response)
    })
  }

  componentDidMount(){
  
  }

  render(){
    let {loading,topProductsRate} = this.state;
    if (this.props.currentUser.data.type !== "ADMIN") return <Redirect to='/orders' /> 
    console.log("topProductsRate",topProductsRate)
  return (
    <div className="Home">
      <MDBContainer> 
        <MDBRow>
          
         <div style={{textAlign:'right',width: '100%',padding: '5px 20px 25px'}}>
          <span  style={{
            display: 'inline-block',
            color: '#43425D',
            fontSize:'22px'}}>{allStrings.statistic}</span>
          <span  style={{fontFamily:'system-ui',fontWeight:'bold',fontSize:'22px',display: 'inline-block',
            color: '#43425D',margin:'6px'}}>|</span>
          <span style={{fontFamily:'system-ui',fontWeight:'bold',fontSize:'22px',display: 'inline-block',
            color: '#43425D'}}>{allStrings.overView}</span>
         </div>
         
        </MDBRow>
        <MDBRow>

          <MDBCol xl="4" lg="4" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard">{allStrings.usersCount}</p>
                <MDBRow>
                  <MDBCol  md="6" sm="6">
                    <img alt="" style={{width: '100%',marginTop: '20px'}} src={require('../../assets/images/Bar Chart1.png')}></img>
                  </MDBCol>
                  <MDBCol  md="6" sm="6">
                    <p className="count">{loading?<Skeleton height={20} />:this.state.count.usersCount}</p>
                  
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="4" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard">{allStrings.stockCount}</p>
                <MDBRow>
                  <MDBCol  md="6" sm="6">
                    <img alt="" style={{width: '100%',marginTop: '20px'}} src={require('../../assets/images/Bar Chart2.png')}></img>
                  </MDBCol>
                  <MDBCol  md="6" sm="6">
                    <p className="count">{loading?<Skeleton height={20} />:this.state.count.stockCount}</p>
                    
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="4" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard">{allStrings.messagesCount}</p>
                <MDBRow>
                  <MDBCol  md="6" sm="6">
                    <img alt="" style={{width: '100%',marginTop: '20px'}} src={require('../../assets/images/Bar Chart3.png')}></img>
                  </MDBCol>
                  <MDBCol  md="6" sm="6">
                    <p className="count">{loading?<Skeleton height={20} />:this.state.count.messagesCount}</p>
                    
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="4" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard">{allStrings.productsCount}</p>
                <MDBRow>
                  <MDBCol  md="6" sm="6">
                    <img alt="" style={{width: '100%',marginTop: '20px'}} src={require('../../assets/images/Bar Chart4.png')}></img>
                  </MDBCol>
                  <MDBCol  md="6" sm="6">
                    <p className="count">{loading?<Skeleton height={20} />:this.state.count.productsCount}</p>
                   
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="4" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard">{allStrings.vendorsCount}</p>
                <MDBRow>
                  <MDBCol  md="6" sm="6">
                    <img alt="" style={{width: '100%',marginTop: '20px'}} src={require('../../assets/images/Bar Chart5.png')}></img>
                  </MDBCol>
                  <MDBCol  md="6" sm="6">
                    <p className="count">{loading?<Skeleton height={20} />:this.state.count.vendorsCount}</p>
                    
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="4" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard">{allStrings.ordersCount}</p>
                <MDBRow>
                  <MDBCol  md="6" sm="6">
                    <img alt="" style={{width: '100%',marginTop: '20px'}} src={require('../../assets/images/Bar Chart6.png')}></img>
                  </MDBCol>
                  <MDBCol  md="6" sm="6">
                    <p className="count">{loading?<Skeleton height={20} />:this.state.count.ordersCount}</p>
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
        </MDBRow>
       
      
        <br></br>
        <MDBRow>
          <MDBCol xl="4" lg="6" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard">{allStrings.topUserRate}</p>
                <MDBRow>
                  <MDBCol xl="4" md="4" sm="4">
                    <p className="centerCount">{loading?<Skeleton height={20} />:this.state.count.topUserRate?this.state.count.topUserRate.firstname.substr(0,10)+' ...' :""}</p>
                  </MDBCol>
                  <MDBCol  md="4" sm="4" style={{paddingRight:0}}>
                   <p className="centerName">{allStrings.rate}
                   <p className="">{this.state.count.topUserRate?this.state.count.topUserRate.rate:'0'}</p>
                   </p>
                  </MDBCol>
                  <MDBCol  md="4" sm="4">
                  <img alt="" style={{width: '70px',height: '70px',borderRadius:'100%',marginBottom:'10px',float: 'right'}} src={require('../../assets/images/profileicon.jpg')}></img>
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="6" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard">{allStrings.topProductRate}</p>
                <MDBRow>
                  <MDBCol  md="4" sm="4">
                    <p className="centerCount">{loading?<Skeleton height={20} />:this.state.count.topProductRate?this.props.isRTL?this.state.count.topProductRate.name_ar.substr(0,10)+'...':this.state.count.topProductRate.name_en.substr(0,10)+' ...':''}</p>
                  </MDBCol>
                  <MDBCol  md="4" sm="4" style={{paddingRight:0}}>
                   <p className="centerName">{allStrings.rate}
                   <p className="">{this.state.count.topProductRate?this.state.count.topProductRate.rate:'0'}</p>
                   </p>
                   
                  </MDBCol>
                  <MDBCol  md="4" sm="4">
                  <img alt="" style={{width: '70px',height: '70px',borderRadius:'100%',marginBottom:'10px',float: 'right'}} src={require('../../assets/images/profileicon.jpg')}></img>
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="6" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard">{allStrings.topProductSale}</p>
                <MDBRow>
                  <MDBCol  md="4" sm="4">
                  <p className="centerCount">{loading?<Skeleton height={20} />:this.state.count.topProductSale?this.props.isRTL?this.state.count.topProductSale.name_ar.substr(0,10)+' ...':this.state.count.topProductSale.name_en.substr(0,10)+' ...':''}</p>
                  </MDBCol>
                  <MDBCol  md="4" sm="4" style={{paddingRight:0}}>
                   <p className="centerName">{allStrings.saleCount}
                   <p className="">{this.state.count.topProductSale?this.state.count.topProductSale.sallCount:'0'}</p>
                   </p>
                  </MDBCol>
                  <MDBCol  md="4" sm="4">
                  <img alt="" style={{width: '70px',height: '70px',borderRadius:'100%',marginBottom:'10px',float: 'right'}} src={require('../../assets/images/profileicon.jpg')}></img>
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol xl="8" lg="7" md="12" sm="12">
            <div className="theCard" style={{minHeight:'505px'}}>
              <p className="topCard" style={{color: '#43425D'}}>{allStrings.orders}</p>
              <br></br>
              <table class="table table-borderless">
                <thead class="thead-light">
                  <tr >
                    <th scope="col">{allStrings.client}</th>
                    <th scope="col">{allStrings.finalTotal}</th>
                    <th scope="col">{allStrings.city}</th>
                    <th scope="col">{allStrings.area}</th>
                  </tr>
                </thead>
                <tbody>
                {this.state.loading?
                
                <div>
                  <Skeleton height={40}/> 
                  <Skeleton height={40}/>
                  <Skeleton height={40}/>
                  <Skeleton height={40}/>
                  <Skeleton height={40}/>
                  <Skeleton height={40}/>
                </div>
                :
                this.state.orders.length > 0 ?
                  this.state.orders.map(val=>(
                      <tr onClick={()=>this.props.history.push("/orderInfo",{data:val})}>
                        
                        <td>{val.client?val.client.firstname + " " + val.client.lastname:""}</td>
                        <td>{val.finalTotal}</td>
                        <th>{val.city.name}</th>
                        <th>{val.area.name}</th>
                      </tr>
                  ))
                  :
                  <tr style={{height: '300px'}}>
                      <th colspan="8" className="noData" style={{background:"#fff"}}>
                      <p style={{marginTop:'10rem',textAlign:'center',}}>{allStrings.noData}</p> 
                      </th>
                  </tr>
                }
                </tbody>
              </table>
              <hr></hr>
              <NavLink to="pendingOrders">
                <span style={{display:'block',marginBottom:'8px'}}>{allStrings.showAll}</span>
              </NavLink>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="5" md="12" sm="12">
            <div className="theCard" style={{minHeight:'505px'}}>
              <NavLink to="products">
                <span style={{position: 'absolute'}}>{allStrings.showAll}</span>
              </NavLink>
              <p className="topCard"  style={{color: '#43425D'}}>{allStrings.topProductsRate}</p>
              <img alt="" width="100%" src={require('../../assets/images/Map.png')}></img>
              <div>
                <span className="rightSpan">{topProductsRate.length>0?this.props.isRTL?topProductsRate[0].name_ar.substr(0,10) + " ..":topProductsRate[0].name_en.substr(0,10) + " .." :""}</span>
                <p style={{marginBottom:0}}>
                  <span className="spanCicle" style={{color:'#54D8FF'}}></span>
                  <span className="spanNum">{topProductsRate.length>0?topProductsRate[0].rate?topProductsRate[0].rate:"-":"-"}</span>
                </p>
              </div>
              <div>
                <span className="rightSpan">{topProductsRate.length>1?this.props.isRTL?topProductsRate[1].name_ar.substr(0,10) + " ..":topProductsRate[1].name_en.substr(0,10) + " ..":"" }</span>
                <p style={{marginBottom:0}}>
                  <span className="spanCicle" style={{borderColor:'#5EE2A0'}}></span>
                  <span className="spanNum">{topProductsRate.length>1?topProductsRate[1].rate?topProductsRate[1].rate:"-":"-"}</span>
                </p>
              </div>
              <div>
                <span className="rightSpan">{topProductsRate.length>2?this.props.isRTL?topProductsRate[2].name_ar.substr(0,10) + " ..":topProductsRate[2].name_en.substr(0,10) + " ..":""  }</span>
                <p style={{marginBottom:0}}>
                  <span className="spanCicle" style={{borderColor:'#FFA177'}}></span>
                  <span className="spanNum">{topProductsRate.length>2?topProductsRate[2].rate?topProductsRate[2].rate:"-":"-"}</span>
                </p>
              </div>
              <div>
                <span className="rightSpan">{topProductsRate.length>3?this.props.isRTL?topProductsRate[3].name_ar.substr(0,10) + " ..":topProductsRate[3].name_en.substr(0,10) + " ..":""  }</span>
                <p style={{marginBottom:0}}>
                  <span className="spanCicle" style={{borderColor:'#A3A1FB'}}></span>
                  <span className="spanNum">{topProductsRate.length>3?topProductsRate[3].rate?topProductsRate[3].rate:"-":"-"}</span>
                </p>
              </div>
              <div>
                <span className="rightSpan">{topProductsRate.length>4?this.props.isRTL?topProductsRate[4].name_ar.substr(0,10) + " ..":topProductsRate[4].name_en.substr(0,10) + " ..":""  }</span>
                <p style={{marginBottom:0}}>
                  <span className="spanCicle" style={{borderColor:'#FBA69D'}}></span>
                  <span className="spanNum">{topProductsRate.length>4?topProductsRate[4].rate?topProductsRate[4].rate:"-":"-"}</span>
                </p>
              </div>
              <div>
                <span className="rightSpan">{topProductsRate.length>5?this.props.isRTL?topProductsRate[5].name_ar.substr(0,10) + " ..":topProductsRate[5].name_en.substr(0,10) + " ..":"" }</span>
                <p style={{marginBottom:0}}>
                  <span className="spanCicle" style={{borderColor:'#FF7CC3'}}></span>
                  <span className="spanNum">{topProductsRate.length>5?topProductsRate[5].rate?topProductsRate[5].rate:"-":"-"}</span>
                </p>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer> 
    </div> 
  )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = {
  setUser,
  ChangeLanguage
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(Home)
);
