import React from "react";
import "./size info.css";
import {MDBContainer,MDBRow,MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import "react-rater/lib/react-rater.css";
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { setUser } from "../../redux/actions/AuthActions";
import { hasValue } from "../../validations/validations";
import { message,TreeSelect} from "antd";
const { TreeNode } = TreeSelect;
const { SHOW_PARENT } = TreeSelect;

class sizeInfo extends React.Component {
  
  state = {
    actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
    name_en:this.props.location.state?this.props.location.state.data.name_en?this.props.location.state.data.name_en:'':'',
    name_ar:this.props.location.state?this.props.location.state.data.name_ar?this.props.location.state.data.name_ar:'':'',
    id:this.props.location.state.data.id,
    category:this.props.location.state?this.props.location.state.data.category?this.props.location.state.data.category:[]:[],
    loading:true,
    refresh:false,
    page:1,
    pages:1,
    categories:[]
  };

  constructor(props){
    super(props)

    window.scrollTo(0,0)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
  }
  
  componentDidMount(){
    this.getCategory()
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }
  getCategory = () => {
    axios.get(`${BASE_END_POINT}categories`)
    .then(response=>{
      console.log(response.data)
      this.setState({categories:response.data.data})
    })
    .catch(error=>{
      console.log("ALL categories ERROR")
      console.log(error.response)
    })
  }
  update = () => {
    const {name_en,name_ar,category} = this.state
    if(hasValue(name_en) && hasValue(name_ar)){
        let l = message.loading('Wait..', 2.5)
        var data = {
            'name_en':name_en,
            'name_ar':name_ar,
            'category':category
        }
        axios.put(`${BASE_END_POINT}sizes/${this.props.location.state.data.id}`,JSON.stringify(data),{
        headers: {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${this.props.currentUser.token}`
        },
        })
        .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.push('/sizes')
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
    }
  }
  delete = () => {
    let l = message.loading('Wait..', 2.5)
    axios.delete(`${BASE_END_POINT}sizes/${this.props.location.state.data.id}`,{
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.props.currentUser.token}`
    },
    })
    .then(response=>{
      l.then(() => message.success(allStrings.done, 2.5) )
      this.props.history.goBack()
    })
    .catch(error=>{
        console.log(error.response)
        l.then(() => message.error(allStrings.error, 2.5))
    })
  }

  submitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.update()
  };
  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    if (this.props.currentUser.data.type === "SUB-ADMIN" && !this.props.currentUser.data.permission.pages.includes('STORE')) return <Redirect to='/Home' />

    return (
      <div className="App">
        <MDBContainer>
          <MDBRow>
            <MDBCol md="12" sm="12">
              <div className="infoCard">
                <Card className="editCard" style={{ overflow: "visible", outline: "none",marginBottom: "0px"}} >
                  <CardMedia style={{ height: "0px" }} />
                  <CardHeader></CardHeader>
                  <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                      <div className="topIcon" style={{background:'#605363'}}>
                          <span className="material-icons" style={{color:'white',fontSize:23}}>format_sizes</span>
                      </div>
                      <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                      marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0'}}>
                      <p>{this.state.actionType==="VIEW"?allStrings.info:allStrings.edit}</p>
                      </div>
                  </div>
                  <CardContent style={{ minHeight: "390px", outline: "none" }}>
                    <MDBContainer>
                      <MDBRow>
                        <MDBCol md="12">
                          <form
                            className="needs-validation"
                            onSubmit={this.submitHandler}
                            noValidate
                          >
                            <div className="grey-text">
                              <MDBRow>
                                <MDBCol md="6">
                                  <div class="md-form">
                                    <label for="form38" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicName}</label>
                                    <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.name_ar} type="text" id="form38"  name="name_ar"  class="form-control" onChange={this.changeHandler} required validate/>
                                    <div class="valid-feedback">{allStrings.correct}</div>
                                    <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                  </div>
                                </MDBCol>
                                <MDBCol md="6">
                                  <div class="md-form">
                                    <label for="form2" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishName}</label>
                                    <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z0-9\s]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.name_en} type="text" id="form2" name="name_en" class="form-control" onChange={this.changeHandler} required validate/>
                                    <div class="valid-feedback">{allStrings.correct}</div>
                                    <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                  </div>
                                </MDBCol>
                              </MDBRow>
                              <MDBRow>
                                <MDBCol md="12">
                                  <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.categories}</label>
                                  <TreeSelect 
                                      virtual={false}
                                      showSearch={true} 
                                      value={this.state.category}
                                      disabled={this.state.actionType==="VIEW"?true:false}
                                      maxTagCount="6"
                                      style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                      treeCheckable = {true}        
                                      showCheckedStrategy= {SHOW_PARENT}   
                                      treeNodeFilterProp="title"                      
                                      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                      placeholder={allStrings.categories}
                                      onChange={(value) => {
                                      console.log(value)
                                        this.setState({category:value});
                                        
                                      }} 
                                  
                                  >
                                      
                                      {this.state.categories.map(val=>(
                                          <TreeNode value={val.id} title={val.name} key={val.id} />
                                      ))}
                                  </TreeSelect>
                                </MDBCol>
                              </MDBRow>
                            </div>
                            <br></br>
                            <br></br>
                            {this.state.actionType==="EDIT"&&
                            <div className="text-center">
                              <MDBBtn type="submit" style={{ background: '#605363' }} rounded className="btn-block z-depth-1a" >
                                <MDBIcon icon="edit" className="mr-2" />
                                {allStrings.update}
                              </MDBBtn>
                            </div>
                            }
                          </form>
                        </MDBCol>
                      </MDBRow>
                    </MDBContainer>
                  </CardContent>
                </Card>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
});

const mapDispatchaddressrops = {
    setUser,
};

export default withRouter(
    connect(mapToStateProps, mapDispatchaddressrops)(sizeInfo)
);