import React from 'react';
import './add coupon.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { message,DatePicker,TreeSelect } from "antd";
const { TreeNode } = TreeSelect;

class AddCoupon extends React.Component {
    state = {
        couponNumber:'',
        discountType:'RATIO',
        discount:0,
        expireDate:null,
        singleTime:true
    };

    constructor(props){
        super(props)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    add = () => {
        const {couponNumber,discount,discountType,expireDate,singleTime} = this.state
        let l = message.loading('Wait..', 2.5)
        if(couponNumber !== '' ){
            var data = {
                'couponNumber':couponNumber,
                'discountType':discountType,
                'discount':discount,
                'expireDate':expireDate,
                'singleTime':singleTime
            }
           
            axios.post(`${BASE_END_POINT}coupons`,JSON.stringify(data),{
            headers: {
            'Content-Type': 'application/json',
            'Authorization':`Bearer ${this.props.currentUser.token}`
            },
            })
            .then(response=>{
            console.log("AddCoupon UPDATED  ",response.data)
            l.then(() => message.success(allStrings.done, 2.5) )
            this.props.history.goBack() 
            })
            .catch(error=>{
                console.log(error.response)
                l.then(() => message.error(allStrings.error, 2.5))
            })
        }
    }
    
    submitHandler = event => {
        event.preventDefault();
        console.log(event.target.className)
        event.target.className += " was-validated";
        this.add()
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    onChangeExpireDate = (date, dateString) => {
        console.log(date, dateString);
        this.setState({expireDate:dateString})
    }
   
  render(){
    if (this.props.currentUser.data.type === "SUB-ADMIN" && !this.props.currentUser.data.permission.pages.includes('COUPONS')) return <Redirect to='/Home' />

    return (
        <div className="App">
            <MDBContainer> 
                <MDBRow>  
                    <MDBCol md="12">
                        <div className="infoCard">
                            <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                                <CardMedia
                                style={{height:"0px"}}
                                />
                                <CardHeader></CardHeader>
                                <div  style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                                    <div className="topIcon" style={{background:'#605363'}}>
                                        <span className="material-icons" style={{color:'white',fontSize:23}}>local_offer</span>
                                    </div>
                                    <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                                    marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0'}}>
                                    <p>{allStrings.AddCoupon}</p>
                                    </div>
                                </div>
                                <CardContent style={{minHeight:"390px",outline:'none'}}>
                                    <MDBContainer>
                                        
                                        <MDBRow>
                                            <MDBCol md="12">
                                            <form
                                            className="needs-validation"
                                            onSubmit={this.submitHandler}
                                            noValidate
                                            >
                                                <div className="grey-text">
                                                <MDBRow>
                                                    <MDBCol md="6">
                                                        <div class="md-form">
                                                        <label for="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.couponNumber}</label>
                                                        <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.couponNumber} type="text" id="form645" name="couponNumber" class="form-control" onChange={this.changeHandler} required  validate/>
                                                        <div class="valid-feedback">{allStrings.correct}</div>
                                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                        </div>
                                                    </MDBCol>
                                                    <MDBCol md="6">
                                                        <div class="md-form">
                                                        <label for="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.discount}</label>
                                                        <input style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.discount} type="number" min={0} id="form56" name="discount" class="form-control" onChange={this.changeHandler} required validate/>
                                                        <div class="valid-feedback">{allStrings.correct}</div>
                                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                        </div>
                                                    </MDBCol>
                                                </MDBRow>
                                                
                                                <MDBRow style={{flexFlow:this.props.isRTL?'row-reverse':'row'}}  >
                                                    <MDBCol md="6">
                                                        <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.discountType}</label>
                                                        <TreeSelect
                                                            showSearch={false} 
                                                            style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                                            treeCheckable = {false}         
                                                            treeNodeFilterProp="title"  
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder={allStrings.discountType}
                                                            onChange={(value) => {
                                                            console.log(value)
                                                            this.setState({discountType:value});
                                                            }} 
                                                        
                                                        >
                                                            <TreeNode value="RATIO" title={allStrings.ratio} key="RATIO" />
                                                            <TreeNode value="FIXED" title={allStrings.fixed} key="FIXED" />
                                                            
                                                        </TreeSelect>
                                                    </MDBCol>
                                                    <MDBCol md="6">
                                                        <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.expireDate}</label>
                                                        <DatePicker style={{width:'100%'}} placeholder={allStrings.expireDate} onChange={this.onChangeExpireDate}/>
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol md="6">
                                                        <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.singleTimeUsed}</label>
                                                        <TreeSelect
                                                            showSearch={false} 
                                                            style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                                            treeCheckable = {false}   
                                                            treeNodeFilterProp="title"  
                                                            value={this.state.singleTime}                    
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder={allStrings.singleTimeUsed}
                                                            onChange={(value) => {
                                                            console.log(value)
                                                            this.setState({singleTime:value});
                                                            }} 
                                                        
                                                        >
                                                            <TreeNode value={true} title={allStrings.yes} key={true} />
                                                            <TreeNode value={false} title={allStrings.no} key={false} />
                                                            
                                                        </TreeSelect>
                                                    </MDBCol>
                                                </MDBRow>
                                            
                                                </div>
                                                <br></br>
                                                <br></br>
                                                <br></br>
                                                <br></br>
                                                <br></br>
                                                <br></br>
                                                <br></br>
                                                <br></br>
                                                <div className="text-center">
                                                    <MDBBtn
                                                    style={{background:'#605363',color:'#fff'}}
                                                    rounded
                                                    type="submit"
                                                    className="btn-block z-depth-1a"
                                                    >
                                                    <MDBIcon icon="plus" className="mr-2" />
                                                        {allStrings.add}          
                                                    </MDBBtn>
                                                
                                                </div>
                                            </form>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBContainer>
                                </CardContent>
                            </Card>
                        </div>
                    </MDBCol>
                </MDBRow>
          </MDBContainer>
        </div> 
    )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
});

const mapDispatchToProps = {
};

export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(AddCoupon)
);