import React from 'react';
import './add size.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter ,Redirect} from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message,TreeSelect} from "antd";
const { TreeNode } = TreeSelect;
const { SHOW_PARENT } = TreeSelect;
class AddSize extends React.Component {
    state = {
        name_en:'',
        name_ar:'',
        category:[],
        categories:[]
    };

    constructor(props){
        super(props)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
        this.getCategory()

        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }
    getCategory = () => {
        axios.get(`${BASE_END_POINT}categories`)
        .then(response=>{
          console.log(response.data)
          this.setState({categories:response.data.data})
        })
        .catch(error=>{
          console.log("ALL categories ERROR")
          console.log(error.response)
        })
      }
    add = () => {
        const {name_en,name_ar,category} = this.state
        let l = message.loading('Wait..', 2.5)
        
        if(hasValue(name_en) && hasValue(name_ar)){
            var data = {
                'name_en':name_en,
                'name_ar':name_ar,
                'category':category
            }
            axios.post(`${BASE_END_POINT}sizes`,data,{
            headers: {
            'Content-Type': 'application/json',
            'Authorization':`Bearer ${this.props.currentUser.token}`
            },
            })
            .then(async(response)=>{
                console.log("AddSize UPDATED  ",response.data)
                l.then(() => message.success(allStrings.done, 2.5) )
                this.props.history.goBack() 
            })
            .catch(error=>{
                console.log(error.response)
                l.then(() => message.error(allStrings.error, 2.5))
            })
        }
    }
    
    submitHandler = event => {
        event.preventDefault();
        console.log(event.target.className)
        event.target.className += " was-validated";
        this.add()
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
   
  render(){
    if (this.props.currentUser.data.type === "SUB-ADMIN" && !this.props.currentUser.data.permission.pages.includes('STORE')) return <Redirect to='/Home' />

    return (
        <div className="App">
            <MDBContainer> 
                <MDBRow>  
                    <MDBCol md="12">
                        <div className="infoCard">
                            <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                                <CardMedia
                                style={{height:"0px"}}
                                />
                                <CardHeader></CardHeader>
                                <div  style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                                    <div className="topIcon" style={{background:'#605363'}}>
                                        <span className="material-icons" style={{color:'white',fontSize:23}}>format_sizes</span>
                                    </div>
                                    <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                                    marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0'}}>
                                    <p>{allStrings.AddSize}</p>
                                    </div>
                                </div>
                                <CardContent style={{minHeight:"390px",outline:'none'}}>
                                    <MDBContainer>
                                        <MDBRow>
                                            <MDBCol md="12">
                                            <form
                                            className="needs-validation"
                                            onSubmit={this.submitHandler}
                                            noValidate
                                            >
                                                <div className="grey-text">
                                                <MDBRow>
                                                    <MDBCol md="6">
                                                        <div className="md-form">
                                                            <label htmlFor="form3" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishName}</label>
                                                            <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z0-9-,.@%$*&!_\s]/g,'') }  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.name_en} type="text" id="form3" name="name_en" className="form-control" onChange={this.changeHandler} required validate/>
                                                            <div class="valid-feedback">{allStrings.correct}</div>
                                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                        </div>
                                                    </MDBCol>

                                                    <MDBCol md="6">
                                                        <div className="md-form">
                                                            <label htmlFor="form3" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicName}</label>
                                                            <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') }
                                                            style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.name_ar} type="text" id="form2" name="name_ar" className="form-control" onChange={this.changeHandler} required validate/>
                                                            <div class="valid-feedback">{allStrings.correct}</div>
                                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                        </div>
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol md="12">
                                                    <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.categories}</label>
                                                    <TreeSelect 
                                                        virtual={false}
                                                        showSearch={true} 
                                                        maxTagCount="6"
                                                        style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                        treeCheckable = {true}        
                                                        showCheckedStrategy= {SHOW_PARENT}   
                                                        treeNodeFilterProp="title"                      
                                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                        placeholder={allStrings.categories}
                                                        onChange={(value) => {
                                                        console.log(value)
                                                            this.setState({category:value});
                                                            
                                                        }} 
                                                    
                                                    >
                                                        
                                                        {this.state.categories.map(val=>(
                                                            <TreeNode value={val.id} title={val.name} key={val.id} />
                                                        ))}
                                                    </TreeSelect>
                                                    </MDBCol>
                                                </MDBRow>
                                                </div>
                                                <br></br>
                                                <br></br>
                                                <br></br>
                                                <br></br>
                                                <br></br>
                                                <br></br>
                                                <br></br>
                                                <br></br>
                                                <div className="text-center">
                                                    <MDBBtn
                                                    style={{background:'#605363',color:'#fff'}}
                                                    rounded
                                                    type="submit"
                                                    className="btn-block z-depth-1a"
                                                    >
                                                    <MDBIcon icon="plus" className="mr-2" />
                                                        {allStrings.add}          
                                                    </MDBBtn>
                                                
                                                </div>
                                            </form>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBContainer>
                                </CardContent>
                            </Card>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        
        </div> 
    )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
});

const mapDispatchToProps = {
};

export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(AddSize)
);