import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './index.css';

import { BrowserRouter , Switch ,Route} from 'react-router-dom';
import { Provider } from 'react-redux'
import {store,persistor} from './redux/store';
import { PersistGate } from 'redux-persist/integration/react'
import Login from './screens/login/login'
import ForgetPassword from './screens/forgetPassword/forgetPassword'
import Splash from './screens/splash/splash'
import Categories from './screens/categories/categories'
import CategoryInfo from './screens/category info/category info'
import SubCategoryInfo from './screens/subCategory info/subCategory info'
import AddCategory from './screens/add category/add category'
import AddSubCategory from './screens/add subCategory/add subCategory'
import UserInfo from './screens/user info/user info'
import Users from './screens/user/Users'
import Admins from './screens/admin/admin'
import Search from './screens/search/search'

import AddUser from './screens/add user/add user'
import cityInfo from './screens/city info/city info';
import areaInfo from './screens/area info/area info';
import setting from './screens/setting/setting';
import contactus from './screens/contactus/contactus';
import sendNotifs from './screens/send notifs/send notifs';
import vendors from './screens/vendor/vendor';
import Home from './screens/home/Home';
import About from './screens/about/about';
import Countries from './screens/countries/countries';
import CountryInfo from './screens/country info/country info'
import AddCountry from './screens/add country/add country'

import coupons from './screens/coupon/coupon';
import couponInfo from './screens/coupon info/coupon info';
import AddCoupon from './screens/add coupon/add coupon';

import brand from './screens/brand/brand';
import brandInfo from './screens/brand info/brand info';
import addbrand from './screens/add brand/add brand';
import size from './screens/size/size';
import sizeInfo from './screens/size info/size info';
import addSize from './screens/add size/add size';
import color from './screens/color/color';
import colorInfo from './screens/color info/color info';
import addColor from './screens/add color/add color';
import anoncement from './screens/anoncement/anoncement';
import anoncementInfo from './screens/anoncement info/anoncement info';
import addAnoncement from './screens/add anoncement/add anoncement';
import Products from './screens/products/products';
import ProductInfo from './screens/product info/product info';
import StockInfo from './screens/stock info/stock info';

import PendingOrders from './screens/pending orders/pending orders';
import AcceptedOrders from './screens/accepted orders/accepted orders';
import RefusedOrders from './screens/refused orders/refused orders';
import CancelledOrders from './screens/cancelled orders/cancelled orders' 
import DeliverdOrders from './screens/deliverd orders/deliverd orders';
import orderInfo from './screens/order info/order info';
import productOrderInfo from './screens/productOrderInfo/productOrderInfo';
import outForDelivery from './screens/out for deliverd orders/out for deliverd orders'
import Notifications from './screens/notifications/notifications'
import blog from './screens/blog/blog';
import blogInfo from './screens/blog info/blog info';
import addBlog from './screens/add blog/add blog';
import terms from './screens/terms/terms';
import orders from './screens/orders/orders';
import driver from './screens/driver/driver';

ReactDOM.render(
  
<Provider store={store}>
  <PersistGate loading={null} persistor={persistor}>
    <BrowserRouter >
      <Switch>

        <Route path={"/Login"} component={Login}/> 
        <Route exact path={"/"} component={Splash}/> 
        <Route path={"/forgetPassword"} component={ForgetPassword}/> 
        <App path={'/orders'} component={orders}/>
        <App path={'/drivers'} component={driver}/>
        <App path={'/terms'} component={terms}/>
        <App path={'/blog'} component={blog}/>
        <App path={'/blogInfo'} component={blogInfo}/>
        <App path={'/addBlog'} component={addBlog}/>
        <App path={'/Notifications'} component={Notifications}/>
        <App path={'/productOrderInfo'} component={productOrderInfo}/>
        <App path={'/Search'} component={Search}/>
        <App path={'/orderInfo'} component={orderInfo}/>
        <App path={'/PendingOrders'} component={PendingOrders}/>
        <App path={'/AcceptedOrders'} component={AcceptedOrders}/>
        <App path={'/RefusedOrders'} component={RefusedOrders}/>
        <App path={'/CancelledOrders'} component={CancelledOrders}/>
        <App path={'/OutForDelivery'} component={outForDelivery}/>
        <App path={'/DeliverdOrders'} component={DeliverdOrders}/>
        <App path={'/Products'} component={Products}/>
        <App path={'/ProductInfo'} component={ProductInfo}/>
        <App path={'/StockInfo'} component={StockInfo}/>
        <App path={'/Anoncements'} component={anoncement}/>
        <App path={'/anoncementInfo'} component={anoncementInfo}/>
        <App path={'/addAnoncement'} component={addAnoncement}/>
        <App path={'/Colors'} component={color}/>
        <App path={'/colorInfo'} component={colorInfo}/>
        <App path={'/AddColor'} component={addColor}/>
        <App path={'/Sizes'} component={size}/>
        <App path={'/sizeInfo'} component={sizeInfo}/>
        <App path={'/AddSize'} component={addSize}/>
        <App path={'/AddCountry'} component={AddCountry}/>
        <App path={'/brands'} component={brand}/>
        <App path={'/AddBrand'} component={addbrand}/>
        <App path={'/brandInfo'} component={brandInfo}/>
        <App path={'/coupons'} component={coupons}/>
        <App path={'/AddCoupon'} component={AddCoupon}/>
        <App path={'/couponInfo'} component={couponInfo}/>
        <App path={'/Countries'} component={Countries}/>
        <App path={'/CountryInfo'} component={CountryInfo}/>
        <App path={'/categories'} component={Categories}/>
        <App path={'/about'} component={About}/>
        <App path={'/Home'} component={Home}/>
        <App path={'/CategoryInfo'} component={CategoryInfo}/>
        <App path={'/subCategoryInfo'} component={SubCategoryInfo}/>
        <App path={'/addCategory'} component={AddCategory}/>
        <App path={'/addSubCategory'} component={AddSubCategory}/>
        <App path={'/users'} component={Users}/>
        <App path={'/Admins'} component={Admins}/>
        <App path={'/userInfo'} component={UserInfo}/>
        <App path={'/addUser'} component={AddUser}/>
        <App path={'/Setting'} component={setting}/>
        <App path={'/ContactUs'} component={contactus}/>
        <App path={'/SendNotifs'} component={sendNotifs}/>
        <App path={'/cityInfo'} component={cityInfo}/>
        <App path={'/areaInfo'} component={areaInfo}/>
        <App path={'/vendors'} component={vendors}/>
        
      </Switch>
    </BrowserRouter>
  </PersistGate>
</Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
